$swiper-arrowG : url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 483.1 483.1' style='enable-background:new 0 0 483.1 483.1' xml:space='preserve'%3E%3Cpath d='M150.9 287.2c7.9 8.4 15.7 16.8 23.6 25.1 50.4 52.7 101.6 104.6 155.8 153 15.4 13.8 30.8 22.6 36.1 15.1 4.9-6.8-1.2-21.5-15.5-37.2-25.7-28.1-51.4-56.6-78.6-83.4-41.9-41.4-84.9-81.7-127.4-122.4 79.4-67 150-145.5 225.4-217.8 3.6-3.4 6.6-11.4 6.6-14.9 0-6.9-7.4-5.3-16.3-.9-29.3 14.7-59.1 38.2-87.1 64.4-54.3 51-109.2 101.2-161.7 154.5l-.2.2c-7.3 7.4-7.2 19.3.2 26.6 12.9 12.8 25.9 25.4 39.1 37.7z' style='fill:%23009b4d'/%3E%3C/svg%3E");

$swiper-arrow : url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 483.1 483.1' style='enable-background:new 0 0 483.1 483.1' xml:space='preserve'%3E%3Cpath d='M150.9 287.2c7.9 8.4 15.7 16.8 23.6 25.1 50.4 52.7 101.6 104.6 155.8 153 15.4 13.8 30.8 22.6 36.1 15.1 4.9-6.8-1.2-21.5-15.5-37.2-25.7-28.1-51.4-56.6-78.6-83.4-41.9-41.4-84.9-81.7-127.4-122.4 79.4-67 150-145.5 225.4-217.8 3.6-3.4 6.6-11.4 6.6-14.9 0-6.9-7.4-5.3-16.3-.9-29.3 14.7-59.1 38.2-87.1 64.4-54.3 51-109.2 101.2-161.7 154.5l-.2.2c-7.3 7.4-7.2 19.3.2 26.6 12.9 12.8 25.9 25.4 39.1 37.7z' style='fill:%230054a3'/%3E%3C/svg%3E");


:root {
  --swiper-navigation-size: 44px;
  /*
  --swiper-navigation-color: var(--swiper-theme-color);
  */
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &:after { content: ''; display: block;width: 30px; height: 30px;
	  background: $swiper-arrow center center/contain no-repeat;
    // font-family: swiper-icons;
    // font-size: var(--swiper-navigation-size);
    // text-transform: none !important;
    // letter-spacing: 0;
    // text-transform: none;
    // font-variant: initial;
    // line-height: 1;
  }
  &:hover,&:focus {
	  &:after { background-image: $swiper-arrowG;}
  }
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  &:after {
    transform: rotate(0deg);
  }
  left: 10px;
  right: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  &:after {
    transform: rotate(180deg);
  }
  right: 10px;
  left: auto;
}

.swiper-button-lock {
  display: none;
}
