// /* Mobile first layout SmartMenus Core CSS (it's not recommended editing these rules)
//    You need this once per page no matter how many menu trees or different themes you use.
// -------------------------------------------------------------------------------------------*/

.sm{box-sizing:border-box;position:relative;z-index:9999;-webkit-tap-highlight-color:rgba(0,0,0,0);}
.sm,.sm ul,.sm li{display:block;list-style:none;margin:0;padding:0;line-height:normal;direction:ltr;text-align:left;}
.sm-rtl,.sm-rtl ul,.sm-rtl li{direction:rtl;text-align:right;}
.sm>li>h1,.sm>li>h2,.sm>li>h3,.sm>li>h4,.sm>li>h5,.sm>li>h6{margin:0;padding:0;}
.sm ul{display:none;}
.sm li,.sm a{position:relative;}
.sm a{display:block;}
.sm a.disabled{cursor:default;}
.sm::after{content:"";display:block;height:0;font:0px/0 serif;clear:both;overflow:hidden;}
.sm *,.sm *::before,.sm *::after{box-sizing:inherit;}

// Generate rules to indent sub menus text
@mixin sm-macabiTivi__sub-items-indentation($amount, $chainable: 'ul ', $level: 4, $chain: '') {
	@for $i from 1 through $level {
		$chain: $chain + $chainable;
		#{$chain} a,
		#{$chain} a:hover,
		#{$chain} a:focus,
		#{$chain} a:active {
			border-left: ($amount * ($i + 1)) solid transparent;
		}
	}
}

// Generate rules to round the corners of the last collapsible item

@mixin sm-macabiTivi__round-corners-last-item($amount, $chainable: 'ul > li:last-child > ', $level: 4, $chain_prefix: '> li:last-child > ', $chain: '', $selector: '') {
	$chain: $chain_prefix;
	$selector: $chain + 'a, ' + $chain + '*:not(ul) a, ' + $chain + 'ul';
	@for $i from 1 through $level {
		$chain: $chain + $chainable;
		$selector: $selector + ',' + $chain + ' a, ' + $chain + '*:not(ul) a, ' + $chain + ' ul';
	}
	#{$selector} {
		border-radius: 0 0 $amount $amount;
	}
	// highlighted items, don't need rounding since their sub is open
	$chain: $chain_prefix;
	$selector: $chain + 'a.highlighted, ' + $chain + '*:not(ul) a.highlighted';
	@for $i from 1 through $level {
		$chain: $chain + $chainable;
		$selector: $selector + ',' + $chain + ' a.highlighted, ' + $chain + '*:not(ul) a.highlighted';
	}
	#{$selector} {
		border-radius: 0;
	}
}

// This file is best viewed with Tab size 4 code indentation


// -----------------------------------------------------------------------------------------------------------------
// 1. Theme Quick Settings (Variables)
// (for further control, you will need to dig into the actual CSS in 2.)
// -----------------------------------------------------------------------------------------------------------------


// ----------------------------------------------------------
// :: 1.1. Colors
// ----------------------------------------------------------

$sm-macabiTivi__white:										#fff !default;
$sm-macabiTivi__gray:										darken($sm-macabiTivi__white, 6.5%) !default;
$sm-macabiTivi__gray-dark:									darken($sm-macabiTivi__white, 26.5%) !default;
$sm-macabiTivi__gray-darker:									darken($sm-macabiTivi__white, 66.5%) !default;
$sm-macabiTivi__red:											#D23600 !default;

$sm-macabiTivi__box-shadow:									rgba(0, 0, 0, 0.2) !default;


// ----------------------------------------------------------
// :: 1.2. Breakpoints
// ----------------------------------------------------------

$sm-macabiTivi__desktop-vp:									1200px !default;		// switch from collapsible to desktop
$sm-macabiTivi__desktop-vp-max:								1199.98px !default;		// switch from collapsible to desktop


// ----------------------------------------------------------
// :: 1.3. Typography
// ----------------------------------------------------------

// $sm-macabiTivi__font-family:									"Lucida Sans Unicode", "Lucida Sans", "Lucida Grande", Arial, sans-serif !default;
// $sm-macabiTivi__font-size-base:								18px !default;
// $sm-macabiTivi__font-size-small:								16px !default;
$sm-macabiTivi__line-height:									17px !default;


// ----------------------------------------------------------
// :: 1.4. Borders
// ----------------------------------------------------------

$sm-macabiTivi__border-width:								1px !default;
$sm-macabiTivi__border-radius:								5px !default;


// ----------------------------------------------------------
// :: 1.5. Collapsible main menu
// ----------------------------------------------------------

// Menu box
$sm-macabiTivi__collapsible-bg:								$sm-macabiTivi__gray !default;
$sm-macabiTivi__collapsible-border-radius:					$sm-macabiTivi__border-radius !default;

// Items
$sm-macabiTivi__collapsible-item-color:						$sm-macabiTivi__gray-darker !default;
$sm-macabiTivi__collapsible-item-current-color:				$sm-macabiTivi__red !default;
$sm-macabiTivi__collapsible-item-disabled-color:				darken($sm-macabiTivi__gray, 20%) !default;
$sm-macabiTivi__collapsible-item-padding-vertical:			13px !default;
$sm-macabiTivi__collapsible-item-padding-horizontal:			20px !default;

// Items separators
$sm-macabiTivi__collapsible-separators-color:				rgba(0, 0, 0, 0.05) !default;

// Toggle button (sub menu indicators)
$sm-macabiTivi__collapsible-toggle-bg:						rgba(255, 255, 255, 0.5) !default;


// ----------------------------------------------------------
// :: 1.6. Collapsible sub menus
// ----------------------------------------------------------

// Menu box
$sm-macabiTivi__collapsible-sub-bg:							rgba(darken($sm-macabiTivi__collapsible-bg, 30%), 0.1) !default;

// Items text indentation for deeper levels
$sm-macabiTivi__collapsible-sub-item-indentation:			8px !default;


// ----------------------------------------------------------
// :: 1.7. Desktop main menu
// ----------------------------------------------------------

// Menu box
$sm-macabiTivi__desktop-bg:									$sm-macabiTivi__gray !default;
$sm-macabiTivi__desktop-border-radius:						100px !default;
$sm-macabiTivi__desktop-padding-horizontal:					10px !default;

// Items
$sm-macabiTivi__desktop-item-color:							$link-color !default;
$sm-macabiTivi__desktop-item-hover-color:					$link-hover-color !default;
$sm-macabiTivi__desktop-item-current-color:					$link-hover-color !default;
$sm-macabiTivi__desktop-item-disabled-color:					darken($sm-macabiTivi__gray, 20%) !default;
$sm-macabiTivi__desktop-item-padding-vertical:				12px !default;
$sm-macabiTivi__desktop-item-padding-horizontal:				12px !default;

// Sub menu indicators
$sm-macabiTivi__desktop-arrow-size:							4px !default;		// border-width
$sm-macabiTivi__desktop-arrow-color:							$sm-macabiTivi__gray-darker !default;
$sm-macabiTivi__desktop-arrow-spacing:						4px !default;

// Vertical menu box
$sm-macabiTivi__desktop-vertical-border-radius:				$sm-macabiTivi__border-radius !default;
$sm-macabiTivi__desktop-vertical-padding-vertical:			10px !default;

// Vertical items
$sm-macabiTivi__desktop-vertical-item-hover-bg:				$sm-macabiTivi__white !default;
$sm-macabiTivi__desktop-vertical-item-padding-vertical:		10px !default;
$sm-macabiTivi__desktop-vertical-item-padding-horizontal:	20px !default;


// ----------------------------------------------------------
// :: 1.8. Desktop sub menus
// ----------------------------------------------------------

// Menu box
$sm-macabiTivi__desktop-sub-bg:								$sm-macabiTivi__white !default;
$sm-macabiTivi__desktop-sub-border-color:					$sm-macabiTivi__gray-dark !default;
$sm-macabiTivi__desktop-sub-border-radius:					$sm-macabiTivi__border-radius !default;
$sm-macabiTivi__desktop-sub-box-shadow:						0 5px 9px $sm-macabiTivi__box-shadow !default;
$sm-macabiTivi__desktop-sub-padding-vertical:				5px !default;
$sm-macabiTivi__desktop-sub-padding-horizontal:				0 !default;

// Items
$sm-macabiTivi__desktop-sub-item-color:						$sm-macabiTivi__gray-darker !default;
$sm-macabiTivi__desktop-sub-item-hover-color:				$sm-macabiTivi__red !default;
$sm-macabiTivi__desktop-sub-item-hover-bg:					$sm-macabiTivi__gray !default;
$sm-macabiTivi__desktop-sub-item-current-color:				$sm-macabiTivi__red !default;
$sm-macabiTivi__desktop-sub-item-disabled-color:				darken($sm-macabiTivi__white, 20%) !default;
$sm-macabiTivi__desktop-sub-item-padding-vertical:			10px !default;
$sm-macabiTivi__desktop-sub-item-padding-horizontal:			20px !default;

// Sub menu indicators
$sm-macabiTivi__desktop-sub-arrow-size:						5px !default;		// border-width

// Sub menu carets
$sm-macabiTivi__desktop-sub-caret-size:						8px !default;		// border-width
$sm-macabiTivi__desktop-sub-caret-left:						30px !default;


// -----------------------------------------------------------------------------------------------------------------
// 2. Theme CSS
// -----------------------------------------------------------------------------------------------------------------


// ----------------------------------------------------------
// :: 2.1. Collapsible mode (mobile first)
// ----------------------------------------------------------

// calc item height and sub menus toggle button size
$sm-macabiTivi__item-height: $sm-macabiTivi__line-height + $sm-macabiTivi__collapsible-item-padding-vertical * 2;
// set toggle button size to 80% of item height
$sm-macabiTivi__toggle-size: floor($sm-macabiTivi__item-height * 0.8);
$sm-macabiTivi__toggle-spacing: floor($sm-macabiTivi__item-height * 0.1);

// Main menu box
.sm-macabiTivi {
	// background: $sm-macabiTivi__collapsible-bg;
	// border-radius: $sm-macabiTivi__collapsible-border-radius;

	// Main menu items
	a:not(.btn) {
		&,
		&:hover,
		&:focus,
		&:active {
			padding: $sm-macabiTivi__collapsible-item-padding-vertical $sm-macabiTivi__collapsible-item-padding-horizontal;
			// /* make room for the toggle button (sub indicator) */
			padding-right: $sm-macabiTivi__collapsible-item-padding-horizontal + $sm-macabiTivi__toggle-size + $sm-macabiTivi__toggle-spacing;
			// color: $sm-macabiTivi__collapsible-item-color;
			// font-family: $sm-macabiTivi__font-family;
			//font-size: $sm-macabiTivi__font-size-base;
			font-weight: normal;
			line-height: $sm-macabiTivi__line-height;
			text-decoration: none;
		}

		&.current {
			color: $sm-macabiTivi__collapsible-item-current-color;
		}

		&.disabled {
			color: $sm-macabiTivi__collapsible-item-disabled-color;
		}

		// Toggle buttons (sub menu indicators)
		.sub-arrow {
			position: absolute;
			top: 50%;
			margin-top: -(ceil(calc($sm-macabiTivi__toggle-size / 2)));
			left: auto;
			right: $sm-macabiTivi__toggle-spacing;
			width: $sm-macabiTivi__toggle-size;
			height: $sm-macabiTivi__toggle-size;
			overflow: hidden;
			// font: bold #{$sm-macabiTivi__font-size-small}/#{$sm-macabiTivi__toggle-size} monospace !important;
			text-align: center;
			text-shadow: none;
			background: $sm-macabiTivi__collapsible-toggle-bg;
			border-radius: $sm-macabiTivi__border-radius;
		}
		.sub-arrow::before {
			content: '+';
		}
		&.highlighted .sub-arrow::before {
			content: '-';
		}
	}

	// round the corners of the first item
	> li:first-child > a, > li:first-child > :not(ul) a {
		border-radius:0;// $sm-macabiTivi__collapsible-border-radius $sm-macabiTivi__collapsible-border-radius 0 0;
	}
	// round the corners of the last item
	@include sm-macabiTivi__round-corners-last-item($sm-macabiTivi__collapsible-border-radius);

	// Main menu items separators
	li {
		border-top: 1px solid $sm-macabiTivi__collapsible-separators-color;
	}
	> li:first-child {
		border-top: 0;
	}

	// Sub menus box
	ul {
		//background: $sm-macabiTivi__collapsible-sub-bg;

		// Sub menus items
		a:not(.btn) {
			&,
			&:hover,
			&:focus,
			&:active {
				// font-size: $sm-macabiTivi__font-size-small;
				// add indentation for sub menus text
				border-left: $sm-macabiTivi__collapsible-sub-item-indentation solid transparent;
			}
		}

		// Add indentation for sub menus text for deeper levels
		@include sm-macabiTivi__sub-items-indentation($sm-macabiTivi__collapsible-sub-item-indentation);
	}
	@media (max-width: $sm-macabiTivi__desktop-vp-max) { max-height: calc(100vh - 72px); overflow: auto;
		>li {box-shadow: inset 0px -40px 40px -40px rgba(238, 235, 235, 0.75); font-size: 19px;
			>a { padding: 26.5px 30px !important; padding-left: 40px; color: $link-color; position: relative;
				&:hover,&:active,&:focus {padding: 26.5px 30px; padding-left: 40px; color: $link-color; }
				.sub-arrow {display: none;}
				&:after { transition: all .3s ease-out; transform: rotate(-180deg);margin-top: 3px;left: 27px;content:""; background: $right-arrow center center/contain no-repeat; width: 11px; height: 11px; position: absolute; }
				&.has-submenu.highlighted {
					&:after {transform: rotate(-90deg);}
				}
			}
		}
		&:not(.mega-menu) li > ul > li {
			a {font-size: clamp(0.882rem, 0.8314rem + 0.2388vw, 1rem) !important;color: #103245;}
		}
		.mega-menu { overflow: hidden;
			>li {position: unset; border:0;
				.arrow {display: none;}
				>button {border:0; font-size: 15px; color:$elephantBlue; background: 0; width: 90%; text-align: right;padding: .5rem 1.6rem;
					&:not(:first-child) {border-top: 1px solid #ececec;}
				}
				.btn-outline-primary { color:$link-color; padding: 6px 5px; border: 1px solid;font-size: 15px; max-width: 106px; margin: 1rem 1.6rem;
					&:hover {color: #fff;}
				}

			}
			.header { padding: 1rem .55rem 0;
				.btn { color: $link-color; font-size: 13px; border-radius: 4px;margin-bottom: 2.5rem;
					&:before {content:""; background: $right-arrow center center/contain no-repeat; width: 13px;height: 13px;display: inline-block;vertical-align: middle;margin: 0 0 0 4px; }					&:hover {}&:active {}&:focus {}
				}
				h4 { font-size: 19px; font-weight: 300; color: $link-color;padding: 0 1rem; margin-bottom: .1rem;}
			}
			.tipulim-list { display: flex !important; padding: 0 !important; flex-wrap: wrap; width: 90% !important;
				li {box-shadow: inset 0px -40px 40px -40px rgba(238, 235, 235, 0.85); list-style: none !important;flex: 0 0 100%; width: 100%;}
				a {padding: .95rem 1.7rem !important; font-size: 15px; color: $elephantBlue; position: relative; display: block !important; text-decoration: none !important;
					&:after { transition: all .3s ease-out; transform: rotate(-180deg);margin-top: 3px;left: 0px;content:""; background: $right-arrow center center/contain no-repeat; width: 11px; height: 11px; position: absolute; }
					&:hover,&:active,&:focus {}
				}
			}
			.matzavim-list { display: flex !important; text-align: center; padding:1rem 0 0 !important; flex-wrap: wrap;
				li { max-width: 50%; list-style: none !important; flex: 0 0 50%; width: 100%;    margin-bottom: 2rem;
				a { text-decoration: none !important; display: block !important; text-align: center; font-size: 15px; color: $elephantBlue;
					img { display: block; margin: auto auto 9px;}
					&:hover,&:active,&:focus {}
				}}
			}
			.family-list { display: flex !important; text-align: center; padding:1rem 0 0 !important; flex-wrap: wrap;
				li { max-width: 50%; list-style: none !important; flex: 0 0 50%; width: 100%;    margin-bottom: 2rem;
				a { text-decoration: none !important; display: block !important; text-align: center; font-size: 15px; color: $elephantBlue;
					img { display: block; margin: auto auto 9px;}
					&:hover,&:active,&:focus {}
				}}
			}
		}
		.accordion-collapse  {position: absolute;top: 0;right: 0;width: 100%;height: 100vh;z-index: 9999999;background: #fff; overflow: auto;
			&.collapsing {height: auto;width: 0;overflow: hidden;@include transition($transition-collapse-width);}//transition: width .35s ease;
			&.show {   }
		}
	}
}


// ----------------------------------------------------------
// :: 2.2. Desktop mode
// ----------------------------------------------------------

@media (min-width: $sm-macabiTivi__desktop-vp) {

	// /* Switch to desktop layout
	// -----------------------------------------------
	//    These transform the menu tree from
	//    collapsible to desktop (navbar + dropdowns)
	// -----------------------------------------------*/
	// /* start... (it's not recommended editing these rules) */
	.sm-macabiTivi ul{position:absolute;width:12em;}
	.sm-macabiTivi li{float:left;}
	.sm-macabiTivi.sm-rtl li{float:right;}
	.sm-macabiTivi ul li,.sm-macabiTivi.sm-rtl ul li,.sm-macabiTivi.sm-vertical li{float:none;}
	.sm-macabiTivi a{white-space:nowrap;}
	.sm-macabiTivi ul a,.sm-macabiTivi.sm-vertical a{white-space:normal;}
	.sm-macabiTivi .sm-nowrap > li > a,.sm-macabiTivi .sm-nowrap > li > :not(ul) a{white-space:nowrap;}
	// /* ...end */

	// Main menu box
	.sm-macabiTivi { height: 95px; max-width: 840px;
		padding: 0 $sm-macabiTivi__desktop-padding-horizontal;
		flex: 1 0 0%; justify-content: space-evenly; display: flex;
		// background: $sm-macabiTivi__desktop-bg;
		// border-radius: $sm-macabiTivi__desktop-border-radius;

		// Main menu items
		a:not(.btn) {
			&,
			&:hover,
			&:focus,
			&:active,
			&.highlighted {
				padding: 40px 0 34px;//$sm-macabiTivi__desktop-item-padding-vertical $sm-macabiTivi__desktop-item-padding-horizontal;
				color: $sm-macabiTivi__desktop-item-color;
				border-radius: 0 !important;
				font-size: 19px;
			}

			&:hover,
			&:focus,
			&:active,
			&.highlighted {
				color: $sm-macabiTivi__desktop-item-hover-color;
				.sub-arrow {background-color: $link-hover-color;transform: rotate(270deg);}
			}

			&.current {
				color: $sm-macabiTivi__desktop-item-current-color;
			}

			&.disabled {
				color: $sm-macabiTivi__desktop-item-disabled-color;
			}

			// Make room for the sub arrows
			&.has-submenu {
				// padding-right: $sm-macabiTivi__desktop-item-padding-horizontal + $sm-macabiTivi__desktop-arrow-size * 2 + $sm-macabiTivi__desktop-arrow-spacing;
			}

			// Sub menu indicators
			.sub-arrow { transition: all .3s ease-in-out;
				top: 67%;
				// margin-top: -(ceil($sm-macabiTivi__desktop-arrow-size / 2));
				// right: $sm-macabiTivi__desktop-item-padding-horizontal;
				transform: rotate(90deg);  content:""; 
								mask-size: 11px auto;
								mask-image: $right-arrow;
								mask-position: center;
								mask-repeat: no-repeat;
								background-color: $link-color;
								 width: 11px; height: 11px;
				// width: 0;
				// height: 0;
				// border-width: $sm-macabiTivi__desktop-arrow-size;
				// border-style: solid dashed dashed dashed;
				// border-color: $sm-macabiTivi__desktop-arrow-color transparent transparent transparent;
				// background: transparent;
				border-radius: 0;
			}
			// reset mobile first style
			.sub-arrow::before {
				display: none;
			}
		}

		// No main menu items separators
		box-shadow: -40px 0px 71px -25px rgba(238, 235, 235, 0.75);
		> li {box-shadow: inset -40px 0px 40px -40px rgba(238, 235, 235, 0.75); height: 100%;flex: 1 0 0%;
			
			> a { text-align: center;
				&.active {color: $link-hover-color;}
			}
		}
		li {
			border-top: 0;
		}

		// First sub level carets
		> li > ul::before,
 		> li > ul::after {
			content: '';
			position: absolute;
			top: -($sm-macabiTivi__desktop-sub-caret-size * 2 + $sm-macabiTivi__border-width * 2);
			left: $sm-macabiTivi__desktop-sub-caret-left;
			width: 0;
			height: 0;
			overflow: hidden;
			border-width: ($sm-macabiTivi__desktop-sub-caret-size + $sm-macabiTivi__border-width);
			border-style: dashed dashed solid dashed;
			border-color: transparent transparent $sm-macabiTivi__desktop-sub-border-color transparent;
		}
		> li > ul { border: 0; box-shadow:inset 0px 80px 80px -40px rgb(255 255 255 / 35%), 0px 10px 20px -5px rgb(116 116 116 / 45%); border-radius: 0 0 18px 18px !important;margin-top: 3px !important;
				&:before, &:after {display: none !important;}
				a {}
			
			// &::after {
			// 	top: -($sm-macabiTivi__desktop-sub-caret-size * 2);
			// 	left: ($sm-macabiTivi__desktop-sub-caret-left + $sm-macabiTivi__border-width);
			// 	border-width: $sm-macabiTivi__desktop-sub-caret-size;
			// 	border-color: transparent transparent $sm-macabiTivi__desktop-sub-bg transparent;
			// }
		}

		// Sub menus box
		ul {
			border: $sm-macabiTivi__border-width solid $sm-macabiTivi__desktop-sub-border-color;
			padding: $sm-macabiTivi__desktop-sub-padding-vertical $sm-macabiTivi__desktop-sub-padding-horizontal;
			background: $sm-macabiTivi__desktop-sub-bg;
			border-radius: $sm-macabiTivi__desktop-sub-border-radius !important;
			box-shadow: $sm-macabiTivi__desktop-sub-box-shadow;

			// Sub menus items
			a:not(.btn) {
				&,
				&:hover,
				&:focus,
				&:active,
				&.highlighted {
					border: 0 !important;
					padding: $sm-macabiTivi__desktop-sub-item-padding-vertical $sm-macabiTivi__desktop-sub-item-padding-horizontal;
					// color: $sm-macabiTivi__desktop-sub-item-color;
				}

				&:hover,
				&:focus,
				&:active,
				&.highlighted {
					// background: $sm-macabiTivi__desktop-sub-item-hover-bg;
					// color: $sm-macabiTivi__desktop-sub-item-hover-color;
				}

				&.current {
					color: $sm-macabiTivi__desktop-sub-item-current-color;
				}

				&.disabled {
					background: $sm-macabiTivi__desktop-sub-bg;
					color: $sm-macabiTivi__desktop-sub-item-disabled-color;
				}

				// No need for additional room for the sub arrows
				&.has-submenu {
					padding-right: $sm-macabiTivi__desktop-sub-item-padding-horizontal;
				}

				// Sub menu indicators
				.sub-arrow {
					right: 8px;
					top: 50%;
					margin-top: -$sm-macabiTivi__desktop-sub-arrow-size;
					border-width: $sm-macabiTivi__desktop-sub-arrow-size;
					border-style: dashed dashed dashed solid;
					border-color: transparent transparent transparent $sm-macabiTivi__desktop-arrow-color;
				}
			}
			
		}

		// Scrolling arrows containers for tall sub menus - test sub menu: "Sub test" -> "more..." in the default download package
		.scroll-up,
 		.scroll-down {
			position: absolute;
			display: none;
			visibility: hidden;
			overflow: hidden;
			background: $sm-macabiTivi__desktop-sub-bg;
			height: 20px;
			// width and position will be set automatically by the script

			&:hover {
				background: $sm-macabiTivi__desktop-sub-item-hover-bg;
			}
		}
		.scroll-up:hover .scroll-up-arrow {
			border-color: transparent transparent $sm-macabiTivi__desktop-sub-item-hover-color transparent;
		}
		.scroll-down:hover .scroll-down-arrow {
			border-color: $sm-macabiTivi__desktop-sub-item-hover-color transparent transparent transparent;
		}
		.scroll-up-arrow,
		.scroll-down-arrow {
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -6px;
			// we will use one-side border to create a triangle so that we don't use a real background image, of course, you can use a real image if you like too
			width: 0;
			height: 0;
			overflow: hidden;
			border-width: 6px; // tweak size of the arrow
			border-style: dashed dashed solid dashed;
			border-color: transparent transparent $sm-macabiTivi__desktop-sub-item-color transparent;
		}
		.scroll-down-arrow {
			top: 8px;
			border-style: solid dashed dashed dashed;
			border-color: $sm-macabiTivi__desktop-sub-item-color transparent transparent transparent;
		}


		// Rigth-to-left

		// Main menu box
		&.sm-rtl {

			// Main menu items
			a {

				// Make room for the sub arrows
				&.has-submenu {
					// padding-right: $sm-macabiTivi__desktop-item-padding-horizontal;
					// padding-left: $sm-macabiTivi__desktop-item-padding-horizontal + $sm-macabiTivi__desktop-arrow-size * 2 + $sm-macabiTivi__desktop-arrow-spacing;
				}

				// Sub menu indicators
				.sub-arrow {
					right: auto;
					left: $sm-macabiTivi__desktop-item-padding-horizontal;
					.pHeader & {left:-22px;}
				}
			}

			// Vertical main menu items
			&.sm-vertical {
				a {

					// No need for additional room for the sub arrows
					&.has-submenu {
						padding: $sm-macabiTivi__desktop-vertical-item-padding-vertical $sm-macabiTivi__desktop-vertical-item-padding-horizontal;
					}

					// Sub menu indicators
					.sub-arrow {
						right: auto;
						left: 8px;
						border-style: dashed solid dashed dashed;
						border-color: transparent $sm-macabiTivi__desktop-arrow-color transparent transparent;
					}
				}
			}

			// First sub level carets
			> li > ul::before {
				left: auto;
				right: $sm-macabiTivi__desktop-sub-caret-left;
			}
			> li > ul::after {
				left: auto;
				right: ($sm-macabiTivi__desktop-sub-caret-left + $sm-macabiTivi__border-width);
			}

			// Sub menus box
			ul {
				a {

					// No need for additional room for the sub arrows
					&.has-submenu {
						padding: $sm-macabiTivi__desktop-sub-item-padding-vertical $sm-macabiTivi__desktop-sub-item-padding-horizontal !important;
					}

					// Sub menu indicators
					.sub-arrow {
						right: auto;
						left: 8px;
						border-style: dashed solid dashed dashed;
						border-color: transparent $sm-macabiTivi__desktop-arrow-color transparent transparent;
					}
				}
			}
		}


		// Vertical main menu

		// Main menu box
		&.sm-vertical {
			padding: $sm-macabiTivi__desktop-vertical-padding-vertical 0;
			border-radius: $sm-macabiTivi__desktop-vertical-border-radius;

			// Main menu items
			a {
				padding: $sm-macabiTivi__desktop-vertical-item-padding-vertical $sm-macabiTivi__desktop-vertical-item-padding-horizontal;

				&:hover,
				&:focus,
				&:active,
				&.highlighted {
					background: $sm-macabiTivi__desktop-vertical-item-hover-bg;
				}

				&.disabled {
					background: $sm-macabiTivi__desktop-bg;
				}

				// Sub menu indicators
				.sub-arrow {
					right: 8px;
					top: 50%;
					margin-top: -$sm-macabiTivi__desktop-sub-arrow-size;
					border-width: $sm-macabiTivi__desktop-sub-arrow-size;
					border-style: dashed dashed dashed solid;
					border-color: transparent transparent transparent $sm-macabiTivi__desktop-arrow-color;
				}
			}

			// No sub level carets
			> li > ul::before,
	 		> li > ul::after {
				display: none;
			}

			// Sub menus box
			ul {

				// Sub menus items
				a {
					padding: $sm-macabiTivi__desktop-sub-item-padding-vertical $sm-macabiTivi__desktop-sub-item-padding-horizontal;

					&:hover,
					&:focus,
					&:active,
					&.highlighted {
						background: $sm-macabiTivi__desktop-sub-item-hover-bg;
					}

					&.disabled {
						background: $sm-macabiTivi__desktop-sub-bg;
					}
				}
			}
		}

		//
		.pHeader & { justify-content: flex-start; box-shadow: none;
			>li {flex: 0 0 0;padding-inline: 1.3rem;
				>a {}
			}
		}
	}
}


// /*********************************/

// /* Mega menus */

// /* make mega menus take the full width of .main-nav */

#main-menu {position: static !important;
	> .has-mega-menu {position: static !important;
		
	}
	.mega-menu {margin-left: -1px !important;margin-right: 10px !important;right: 0 !important; border: 0;
		    margin-left: -1px !important;
    margin-right: -1px !important;
    right: 0 !important;
    width: auto !important;
    max-width: none !important;
		&:before,&:after { display: none;}
		> li {overflow: auto;}
		ul {position: static;display: block;margin: 0.83em 0;border: 0;padding: 0 0 0 20px;width: auto;background: transparent;box-shadow: none;
			li {display: list-item;list-style-type: disc;border: 0;}
		}
		a:not(.btn) {display: inline;border: 0;padding: 0;background: transparent;color: inherit;text-decoration: underline;}
		.column-1-3 {padding: 0 20px;}
	}
	> .has-mega-menu {
		.scroll-up, .scroll-down {margin-left: 0 !important;}
	}
}
// /* clear any floats inside the mega menus */
// /* reset mega menu list/link styles (which are inherited from the SmartMenus core/theme styles) */
// /* simple columns inside the mega menus */
@media (min-width: $sm-macabiTivi__desktop-vp) {
	#main-menu .mega-menu .column-1-3 {float: left;width: 33.33%;}
}



//$account

#header {box-shadow: 0 0 25px rgba(238, 235, 235, 0.75); background: #fff;
	// .navbar-toggler {padding: .25rem .5rem; border-radius: 5px;
	// 	&:focus{box-shadow: 0 0 0 .1rem;}
	// }
	.navbar-toggler {order: 3;color: $link-color;
		font-size: clamp(0.882rem, 0.8278rem + 0.2561vw, 1.117rem);
		text-decoration: none;
		&:after {
			transform: rotate(90deg);
			border: 0;
			vertical-align: middle;
			content: "";
			width: 12px;
			height: 12px;
			display: inline-block;
			//background: $right-arrow center center/contain no-repeat;
			background-color: $link-color;
			mask-position: center;
			mask-size: contain;
			mask-repeat: no-repeat;
			mask-image: $right-arrow;
		}
		&:hover, &:active, &:focus {box-shadow: 0 0 0 0.15rem #0054a31a;
			&:before, &:after {
				background-color: $link-hover-color;
			}
		}
		&.show {
			color: $link-hover-color;
			&:after {
				transform: rotate(-90deg);
			}
			&:before, &:after {
				background-color: $link-hover-color;
			}
		}
		span {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: inline-block;
			vertical-align: middle;
			max-width: 50px;
		}
	}
	.navbar-toggler-icon { width: 0.9em; height: 0.9em; background-image: escape-svg($menu); }
	.navbar-brand { margin: 0;}
	// /*!
	// *<div class="navMore">
	// */
	.navMore {font-size: 19px;display: flex;
		.call {line-height: 1; text-decoration: none; font-size: 19px; color: $link-color;margin:auto 2.2%; display: inline-block;font-weight: 400;
			strong {font-weight: 400; display: inline-block; line-height: 1;
			&::first-letter {-webkit-initial-letter: 4;
				initial-letter: 4; }}///*font-weight: 400; font-size: 2.4rem;    transform: translateY(10px); display: inline-block;line-height: 1.2;*/
			&:before { content: ""; display: inline-block; width: 18px; height: 18px; 
				// background: $phone-callBlue center center/contain no-repeat; 
			mask-image: $phone-callBlue;
			mask-position: center;
			mask-size: contain;
			mask-repeat: no-repeat;
				background-color: $link-color;
				vertical-align: middle;margin-left: .4rem;transform: translateY(-2px);}
			&:hover,&:active,&:focus { color: $link-hover-color;
				&:before {background-color: $link-hover-color;}
			}
		}
		.goToLogin { text-decoration: none; margin: auto 5.8%;
			&:before { content: "";width: 17px; height: 17px;background-color: $link-color; display: inline-block; margin-left: 7px; margin-top: -1px;
					mask-image: $account;
					mask-position: center;
					mask-size: contain;
					mask-repeat: no-repeat;transform: translateY(3px);}
			&:hover,&:active,&:focus {
				&:before {background-color: $link-hover-color;}
			}
		}
		.btn-primary { font-weight: 700; margin: auto 3% auto 0; width: 125px;
			&:hover,&:active,&:focus {}
		}
	}
	.headerSearch { display: flex; flex-direction: row-reverse;}



	.search-open { border:0; width: 27px;height: 31px; background: 0; order:-1;
		
		overflow: hidden; text-indent: -9999px; border-radius: 5px; padding: 0;
		&:before { content:"";mask-image: $search-1;
			mask-position: center center;
			mask-size: 21px auto;
			mask-repeat: no-repeat;
			background-color: $link-color; display: block;width: 27px;height: 31px;}
		&:focus {box-shadow: 0 0 0 0.25rem rgb(0 84 163 / 25%);}
		&.close { /*display: none;*/
			&:before {mask-image: $right-arrow;}
		}
	}
	.search-form {display: none;}

	// @media (max-width: 991.98px) {
	// 	.search-open {left: 80px;}
	// }
	
	/* search-form */
	.search-form {width: 180px;padding: 0;border-bottom: 1px solid #006837;border-radius: 0px;
		//> * {direction: rtl;}
		form, .autocomplete-wrap { display: flex;}
		.btn-search {border: 0;width: 27px;height: 27px;
			// mask-image: $search-1;
			// mask-position: center;
			// mask-size: 21px auto;
			// mask-repeat: no-repeat;
			// background-color: $link-color;
			background: $search-1 center center/contain no-repeat;
				text-indent: -9999px;overflow: hidden;}
		.form-control {position: relative;font-size: 19px;background: none;border: 0;box-shadow: none;padding: 0 5px;background: none;border-bottom: 1px solid #fff;border-radius: 0;
			&::-moz-placeholder {color: #b7b7b7;opacity: 1;}
			&:-ms-input-placeholder, &::-webkit-input-placeholder {color: #b7b7b7;}
		}
		.autocomplete-wrap {position: relative;z-index: 100;}
		.autocomplete {list-style: none;margin: 1px 0 0;padding: 6px 0;position: absolute;left: 0;right: 2px;top: 100%;background: #fff;border: solid #aaa;border-width: 0 1px 1px;border-radius: 0 0 5px 5px;
			li {margin: 0 0 1px;}
			a {display: block;padding: 1px 15px 0;color: #333;
				&:hover {text-decoration: none;background: #dce9c0;}
			}
			.active a {text-decoration: none;background: #dce9c0;}
			strong {font-family: 'fbspoiler-bold', sans-serif;}
		}
	}
	
	@media (max-width: $sm-macabiTivi__desktop-vp-max) {
		.navbar { //padding-top: 0.8rem;padding-bottom: 0.8rem; padding-right: calc(var(--bs-gutter-x)*.5); padding-left: calc(var(--bs-gutter-x)*.5);
		 padding: 0.8rem calc(var(--bs-gutter-x) * .5);
		// padding-block: .8rem;
		// padding-inline: calc(var(--bs-gutter-x) * .5);
		}
		.navbar-brand { margin: 0; width: 120px;
			img {display: block;width: 100%;}
		}
		.search-open {display: none;}
		.headerSearch { }
		.search-form {display: block;width: 100%;border-bottom:9px solid #f5f5f5;padding: 26px 25px 10px 10px;
			.form-control { font-size: 15px;}
			.btn-search {transform: translateY(-3px);}
		}
		.navMore { align-items: center; margin-top: 6px; margin-left: 4px;
			a { min-width: 30px;}
			* span  { display: none;}
			.btn-primary { font-size: 13px; display: flex; justify-content: center; align-items: center; flex-direction: column; font-weight: normal; line-height: 1; position: absolute; top: 50vh; left:10px; width: 66px; height: 66px; border-radius: 100%;box-shadow: -18.1px 15.7px 35px 0 rgba(0, 1, 0, 0.14);
				&:before { content: ""; background: $calendar center center/contain no-repeat;width: 18px;  height: 18px; margin-bottom: 5px;}
				span {display: block;}
			}
		}
		.offcanvas { max-width: 307px;
			.offcanvas-header {padding: 0;
				.btn-close { position: absolute; right: 325px; top: 28px; filter: invert(1); opacity: 1; background-size: .75rem;}
			}
		}
		.offcanvas-backdrop { background: $link-color; opacity: .88;}
		.mega-menu {margin:-15px 0 0 0 !important; width: auto !important; max-width: none !important;
			.firstTimeLi { display: none;}
		}
		&:is(.pHeader) {}
		&.pHeader { display: flex;}
	}
	@media (min-width: $sm-macabiTivi__desktop-vp) {
		nav.navbar.container { padding: 0 15px;max-width: 1630px;}
		.navbar-brand { margin-left: 1.8%;}
		.offcanvas { flex-direction: row;}
		.navMore {order:4;transform: translateY(2px); flex-wrap: wrap; flex:0 0 25%; justify-content: space-between;
			>a {white-space: nowrap; display: inline-block !important; margin: auto 0 !important;
				&.call {transform: translateY(-1px);}
			}
			
		}
		.sm-macabiTivi { order:-1;}
		.headerSearch { margin:32px auto auto 3.3%;}
		.search-form { margin-right: 25px;}
		#main-menu .mega-menu {/*display: flex !important;*/ margin-right:10px !important; width: 1380px !important; max-width: calc(100vw - 240px) !important;  justify-content: space-between;  box-shadow:inset 0px 80px 80px -40px rgb(255 255 255 / 35%), 0px 10px 20px -5px rgb(116 116 116 / 45%); border-radius: 0 0 18px 18px !important;margin-top: 3px !important; padding: 0;
			&[aria-expanded="true"] { display: flex !important;}
			>li {flex:0 0 66.5%; width: 100%; max-width: 66.5%;
				&:not(.firstTimeLi) { position: relative; padding-right: 9%;
					&:after {content: ""; position: absolute;top: 0;box-shadow: 0px 0px 18px 0px rgb(116 116 116 / 18%); right:0; width: 12.6%; height: 100%;}

					.arrow {transition: bottom .3s ease-in-out;content: "";width: 20px;height: 20px;position: absolute;right: calc(12.6% - 10px);background: #f8f8f8;transform: rotate(45deg);box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);z-index: 1;}
					.nav-link { z-index: 2; position: absolute; right: 0; border:0; padding: 0; background: #fff; height: 50%; width: 12.6%;
						
						&:nth-of-type(1) {
							&[aria-expanded="true"] {
								~.arrow {bottom:calc(75% - 10px);}
							}
						}
						&:nth-of-type(2) {top: 50%; border-radius: 0 0 18px 0;
							&[aria-expanded="true"] {
								~.arrow {bottom:calc(25% - 10px);}
							}
						}
						&[aria-expanded="true"] {pointer-events: none; font-weight: bold;background: #f8f8f8;
							&:before {}
						}
					}
				}
			}
			.firstTimeLi {flex:0 0 33.5%; width: 100%; max-width: 33.5%; padding: 2% 1% 2% 2%; overflow: hidden;
				.firstTime { background: #f8f8f8; border-radius: 18px;padding: 4.5% 7%;position: relative;
					&:after { content: ""; position: absolute; width:25%; height: 25%; background: url(../images/dots3.webp) center center/contain no-repeat;     bottom: -6%; left: -12%;}
					.title { font-weight: bold; font-size: 23px; color: $link-color;}
					.text { margin-bottom: .5rem;}
					.btn.btn-success { max-width: 166px;
						&:hover {}&:active {}&:focus {}
					}
				}
			}
			.tipulim-list { display: flex; padding: 0 !important; flex-wrap: wrap; margin: 1.3rem 1.1rem 1rem; max-width: 770px;
				li { list-style: none !important;flex: 0 0 29%; width: 100%; max-width: 210px; text-overflow: ellipsis; overflow: hidden;
					&:last-child {
						a {color: $link-hover-color;
							&:after {  transform: rotate(-180deg); margin-right: 4px; content:""; 
								mask-size: 11px auto;
								mask-image: $right-arrow;
								mask-position: center;
								mask-repeat: no-repeat;
								background-color: $link-hover-color;
								 width: 11px; height: 11px; }
						}
					}
				}
				a {white-space: pre-line; display: flex!important; align-items: center; min-height:41px ; line-height: 1; font-size: 17px; padding: 0rem 1.7rem !important; color: $elephantBlue; position: relative;  text-decoration: none !important;
					//&:after { transition: all .3s ease-out; transform: rotate(-180deg);margin-top: 3px;left: 0px;content:""; background: $right-arrow center center/contain no-repeat; width: 11px; height: 11px; position: absolute; }
					&:hover,&:active,&:focus { color: $link-hover-color;}
				}
			}
			.matzavim-list { display: flex !important; text-align: center; flex-wrap: wrap;background: #f8f8f8; border-radius: 18px !important;padding: 2.8% 3%;margin: 3.5% 4.4% 0% 0%;
				li { list-style: none !important; flex: 1 0 118px; max-width: 118px;
				a {white-space: pre-line; line-height: 1; font-size: 17px;  text-decoration: none !important; display: block !important; text-align: center; color: $elephantBlue;
					img { display: block; margin: auto auto 0rem; width: 84px; height: 84px;}
					&:hover,&:active,&:focus { color: $link-hover-color;}
				}}
			}
			.family-list { gap:1rem; display: flex !important; text-align: center; flex-wrap: wrap;background: #f8f8f8; border-radius: 18px !important;padding: 2.1% 3%;margin: 2.5% 2.2%;
				li { list-style: none !important; flex: 1 0 0%;
				a {white-space: pre-line; line-height: 1; font-size: 17px;  text-decoration: none !important; display: block !important; text-align: center; color: $elephantBlue;
					img { display: block; margin: auto auto 0rem; width: 84px; height: 84px;}
					&:hover,&:active,&:focus { color: $link-hover-color;}
					&.active { text-shadow: 0 0 1px, 0 0 1px;}
				}}
			}
		}
		&:is(.pHeader) {
			.navMore {flex: 1 0 auto; min-width: 465px; width: min-content; gap: .4rem;max-width: 504px;}
			#main-menu {
				.mega-menu {
					>li { padding: 0; flex:0 0 100%; max-width: none; overflow: hidden;
						&:after {display: none;}
					}
				 }
			}
		}
	}
	@media (max-width: 991.98px) {
		// .search-form {left: 80px;}
		&:is(.pHeader) {}
	}
}












.tt-suggestion {
  padding: 3px 20px;
//   font-size: 18px;
//   line-height: 24px;
}

// .tt-suggestion:hover {
//   cursor: pointer;
//   color: #fff;
//   background-color: #0097cf;
// }

// .autocomplete-wrap { height: 39px; }
.twitter-typeahead { display: block !important; }
.message-empty { text-align: center; }
.tt-menu { list-style: none; margin: 1px 0 0; padding: 6px 0; position: absolute; left: 0; right: 2px; top: 100%; background: #fff; border: solid #aaa; border-width: 0 1px 1px; border-radius: 0 0 5px 5px; }
.tt-dataset { margin: 0 0 1px; }
.tt-dataset.tt-dataset-0 .tt-suggestion { display: block; padding: 1px 15px 0; }
.tt-suggestion > a { color: #333; }
.tt-suggestion > a:hover { text-decoration: none; }
.tt-suggestion:hover { text-decoration: none; background: #dce9c0; }