.recruitContact {
	input[type="file"], input[type="file" i] {
		&::-webkit-file-upload-button { display: none; 
			// + [aria-hidden], + label {display: none !important;}
		}
		&::file-selector-button { display: none; 
			// + [aria-hidden], + label {display: none !important;}
		}
		// > label, span { display: none !important;}
	}
	legend {color: #fff;font-size: clamp(1.705rem, 1.2259rem + 2.2626vw, 2.823rem); text-align: center; font-weight: bold; line-height: 1;
		+ p {}
	}
	label { border-radius: 3px;font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem);}
	.form-control {font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem);}
	p {color: #fff;font-size: clamp(1rem, 0.7986rem + 0.9512vw, 1.47rem); text-align: center; font-weight: 500; line-height: 1;}
	.form-floating > .form-control:focus ~ label, 
	.form-floating > .form-control:not(:placeholder-shown) ~ label, 
	.form-floating > .form-control-plaintext ~ label, 
	.form-floating > .form-select ~ label {color: var(--bs-body-color);}

	textarea.form-control { height: clamp(6.47rem, 7.2286rem + -3.5821vw, 4.7rem);}
	.umbraco-forms-form {border-radius: 20px; background-color: $endeavourBlue; padding: clamp(3rem, 2.4000rem + 2.8333vw, 4.4rem) clamp(1.176rem, -2.3786rem + 16.7855vw, 9.47rem);margin: 0 15px .9rem;}
	.field-validation-error {padding-top: .2em;
		color: #ffb5bc;
		font-size: 80%;
		line-height: 1;
		display: inline-block;
	}
	.btn{background-color: $hazeGreen; width: 100%; margin: auto; max-width: 320px; display: block;font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem); font-weight: 500;
		color: #fff;
		&:hover, &:active, &:focus {
			color: $hazeGreenA;
			background: var(--bs-white);
			border-color: $hazeGreen;
		}
	}
	@media (min-width:992px) {
		.umbraco-forms-form { margin:0 0 1rem;}

		// .form-floating > .form-control:focus, 
		// .form-floating > .form-control:not(:placeholder-shown), 
		// .form-floating > .form-control-plaintext:focus, 
		// .form-floating > .form-control-plaintext:not(:placeholder-shown),
		.form-control { height: 61px;}

		.btn { max-width: 205px;}
		.umbraco-forms-container { display: flex; flex-wrap: wrap; justify-content: space-between;
			.form-group { width: 100%;
				&:nth-child(1), &:nth-child(2), &:nth-child(3) { width: 32%;}
			}
		}
		
	}
}