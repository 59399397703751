// /*!
// *<footer id="footer">
// */
#footer {margin:2.2rem 0;
	.container {--bs-gutter-x:22px;
		&:before {transform: translateY(-2.2rem); content: ""; height: 7px; border-radius: 7px; width: 100%; display: block;box-shadow:0 0 70px #c3b3b3;background: var(--bs-white);}
	}
	nav {
		ul { list-style: none; padding: 0; column-count: 3; gap: 1rem; vertical-align: top; margin-bottom: 1.5rem; font-size: 17px;}
		li {margin:0 0 1rem 0;break-inside: avoid;vertical-align: top;
			a { text-decoration: none; line-height: 1; display: inline-block;
				&:hover ,&:active,&:focus { text-decoration: underline;}
			}
		}
	}
	.contactText { background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 12px;margin-bottom: 2.1rem; padding:2rem 0 2.8rem 0; text-align: center;
		h2 { margin: 0 0 .1rem;  font-size: 23px; font-weight: bold;
			span {display: block; font-family: 'GveretLevin'; font-size: 17px;}
		}
		.call { text-decoration: none; font-size: 19px; color: $link-color;margin-left: 2rem; display: inline-block;
			strong { font-weight: 400;   transform: translateY(3px); display: inline-block;line-height: 1.2;} ///*direction: ltr; font-size: 2.4rem; */
			&:before { content: ""; display: inline-block; width: 18px; height: 18px; 
				// background: $phone-callBlue center center/contain no-repeat; 
			mask-image: $phone-callBlue;
			mask-position: center;
			mask-size: contain;
			mask-repeat: no-repeat;
				background-color: $link-color;
				vertical-align: middle;margin-left: .5rem;}
			&:hover,&:active,&:focus { color: $link-hover-color;
				&:before {background-color: $link-hover-color;}
			}
		}
		.instagram { margin-right: .4rem; width: 23px;height: 23px;
			display: inline-block; vertical-align: middle;
			&:before {content:""; display: block; width: 100%; height: 100%;
			background-color: $link-color;
			mask-image: $instagramBlue;
			mask-position: center;
			mask-size: contain;
			mask-repeat: no-repeat;}
			&:hover,&:active,&:focus {&:before { background-color: $link-hover-color;}}
		}
		.facebook { margin-right: .4rem; width: 23px;height: 23px;
			display: inline-block; vertical-align: middle;
			&:before {content:""; display: block; width: 100%; height: 100%;
			mask-image: $facebookBlue;
			mask-position: center;
			mask-size: contain;
			mask-repeat: no-repeat; 
			background-color: $link-color;}
			&:hover,&:active,&:focus { &:before {background-color: $link-hover-color;}}
		}
		.btn { display: block;max-width: 180px; margin: auto;
			
		}
	}
	.credits { font-size: 13px; text-align: center;
		img { display: block; margin: auto;}
		.ewave {margin-top: 1rem; display: inline-block;}
	}
	@media (min-width:768px) {margin:8.5rem 0;
		.container { display: flex; flex-wrap: wrap ; background: url(../images/dots5.webp) right center no-repeat;
			&:before {display: none;}
			nav{ flex: 0 0 auto; width: 46%; text-align: center;padding-top: 2.2rem; position: relative; margin: auto;
				&:before,&:after { position: absolute; top: 0; content: ""; height: 100%; border-radius: 7px; width: 7px; display: block;box-shadow:0 0 70px #c3b3b3;background: var(--bs-white);}
				&:before {left: 66%;}&:after {left:33%;}
			
			}
			.contactText {flex: 0 0 auto; width: 49%; margin-left: 2%; order: -1; padding-bottom: 1.8rem;}
			.credits { display: flex; flex-wrap: wrap ;flex: 0 0 auto; align-items: flex-start; width: 100%;justify-content: center; margin-top: 1rem;
				img {margin: 0 5px;}
				p { margin: 0;}
				.ewave {  margin: 0;}
			}
			&:has(.contactText) { background: 0;
				nav { margin: initial;}
				.credits {justify-content:flex-start; margin-top: auto;
					.ewave {  margin: 0 auto 0 0;}
				}
			}
		}
	}
	
}
