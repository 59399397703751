
/*
*<div class="ask-treatment">
*/
.ask-treatment { text-align: center; color: $endeavourBlue; padding: 1rem 0 0;
	.btn-clinic { display: block; max-width: 315px; margin: auto; font-weight: bold; text-decoration: none; position: relative;
		&:after {  transform: rotate(90deg) translate(-1px, 5px); margin: 0; border:0; content:"";  vertical-align: middle;
					mask-size: 11px auto;
					mask-image: $right-arrow;
					mask-position: center;
					mask-repeat: no-repeat;
					background-color: $link-color;
					width: 11px; height: 11px;
		}
		.multiselect-native-select {display: block; max-width: 315px; margin: auto; font-weight: bold; text-decoration: none; position: relative;}
		
		&:hover,&:active,&:focus {
			&:after {background-color: $link-hover-color;}
		}
		&.show {
			&:before {z-index: 9999; content: ""; position: absolute;top: 20px; left: calc(50% - 9px); width: 0;height: 0;border-style: solid;border-width: 0 9px 10px 9px;border-color: transparent transparent #fff transparent;}
			&:after {transform: rotate(90deg) translate(-1px, 5px) scale(-1);}
		}
	}
	.clinics-list.dropdown-menu { max-height: 220px; overflow: auto;@include scrollbar; width: 100%; max-width: 315px; border:0; background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 12px; padding: .35rem 2rem;
		// &:before { content: ""; position: absolute;top: -9px; left: calc(50% - 9px); width: 0;height: 0;border-style: solid;border-width: 0 9px 10px 9px;border-color: transparent transparent #fff transparent;}
		li {padding: .15rem 0;
			&:not(:last-child) {border-bottom:1px solid #f7f7f7;}
			a {display: block;width: 100%;padding: .35rem 0;clear: both;font-weight: 400;color: #212529;text-align: inherit;text-decoration: none;white-space: nowrap;background-color: transparent;border: 0;
				&:hover {}&:active {}&:focus {}
			}
		}
	}
	
	.dropdown {
		@media (max-width:991.98px) {max-width: 315px; margin: auto;display: block;}
	}
	.btn-clinicBig { max-width: 315px; width: 100%; margin: auto;padding: .192rem .75rem; position: relative; z-index: 1001;
		@media (max-width:991.98px) {display: block;/*font-size: 15px;*/ }
		@media (min-width:992px) { max-width: 180px;}
	}
	.dropdown-menu.clinics-listBig { position: relative; overflow: auto;@include scrollbar; width: calc(100% - 2rem); max-width: 860px; border:0; background: var(--bs-white); box-shadow: 0 0 70px #eeebeb;
		&:before {z-index: 9999; content: ""; position: absolute;top: -10px; left: calc(50% - 9px); width: 0;height: 0;border-style: solid;border-width: 0 9px 10px 9px;border-color: transparent transparent #fff transparent;}
		ul { display: flex; flex-wrap: wrap; list-style: none; padding: 0; gap:.3rem;
			li { max-width: calc(50% - .15rem); width: 100%;
				input {position: absolute; clip: rect(0, 0, 0, 0); pointer-events: none;}
				label {font-size: clamp(0.882rem, 0.8584rem + 0.1254vw, 1rem); text-align: center;background: var(--bs-white); box-shadow: 0 0 14px #eeebeb; border-radius: 18px; display: block; color:$endeavourBlue; width: 100%; padding: .2rem; text-decoration: none;}
				input{
					&:focus+label {color:#fff; background-color: lighten($endeavourBlue, 30%);}
					&:checked+label {color:#fff; background-color: $endeavourBlue;}
					
				}
				&.w-100 {max-width: 100%;}
			}
		}
		.btn.btn-success { width: 100%; margin: .3rem 0;
			&:hover {}&:active {}&:focus {}
		}
		.btn.btn-link {width: 100%; color: $link-hover-color; margin: .3rem 0;
			&:hover {}&:active {}&:focus {}
		}
		@media (max-width:991.98px) {max-width: 315px;/*inset: 0px auto auto 0px !important; margin-left: 1rem!important; margin-right: 1rem!important;*/ width: 100%; border-radius: 12px; padding: .35rem 1rem;}
		@media (min-width:992px) { overflow: visible; width: 1000px; max-width: 860px; border-radius: 18px; padding: 1.75rem;transform: translate(345px, 45px) !important;
			ul {
				li { width: auto !important; max-width: none !important; min-width: 1px;
					label { min-width: 156px;padding: .2rem .8rem;}
				}
			}
			.ftr {display: flex; flex-wrap: wrap; justify-content: space-between;
				hr { flex:0 0 100%;}
				.btn {width: auto !important; max-width: none !important; padding: .2rem .8rem; margin: 0;
					&.btn-success {min-width: 156px; order:3;}
					&.btn-link { order:2;}
				}
			}
		}
	}	
	.tools {display: none;}
	@media (min-width:992px) { text-align: start; 
		.btn-clinic, .multiselect-native-select { display: inline-block; max-width:min-content;}
		
	}
}

/*
*<ul class="clinics-nav">
*/
.clinics-nav { margin: 0; display: block;  overflow: auto; padding:1rem 0; max-width: 100vw; position: relative;
	ul {white-space: nowrap; list-style: none; padding:0;display: flex; flex-wrap: wrap; gap: 1rem;}
	li {  min-width: 137px; display: inline-block;
		a, button { text-align: center;background: var(--bs-white); box-shadow: 0 0 14px #eeebeb; border-radius: 18px; display: block; color:$endeavourBlue; min-width: 137px; padding: .2rem; text-decoration: none; border:0;
			&.active {}
			.sr-only {}
			&:hover,&:focus {background-color: lighten($endeavourBlue, 65%); box-shadow: 0 0 14px darken(#eeebeb, 20%) ;}
			&:active, &.active {color:#fff; background-color: $endeavourBlue;}
			
		}
		
	}
	@media (max-width:991.98px) {
		li {min-width: calc((100% / 2) - 1rem + (1rem /2));
			article & {
				a, button {min-width: 100%;}
			}
		}
	}
	@media (min-width:992px) {
		li {min-width: 155px;
			a, button {min-width: 155px; padding: 0.35rem;}
		}
	}
}
%rowItems {
	.location, .modal-title {font-weight: 500; font-size: 17px; width: 100%; max-width: calc(100% - 70px); margin-bottom: .5rem; line-height: inherit;
		strong { display: block;}
	}
	.distance { text-align: left; max-width: 70px; white-space: nowrap;
		&:before {content: ""; display: inline-block; width: 13px; height: 13px; vertical-align: -.01rem; margin-left: 3px;
			mask-image: $pin;
			mask-size: contain;
			mask-position: center;
			mask-repeat: no-repeat;
			background-color: $endeavourBlue;
		}
	}
	.mail { max-width: 20px; margin-left: 1rem;
		a {color:$elephantBlue; overflow: hidden; white-space: nowrap; font-size: .000001px; display: block; text-decoration: none;
			&:before { content:""; display: inline-block; width: 20px; height: 20px;
				mask-image: $mail2;
				mask-size: contain;
				mask-position: center;
				mask-repeat: no-repeat;
				background-color: $elephantBlue;}
			&:hover {}&:active {}&:focus {}
		}
	}
	.phone { max-width: 20px;
		a { color:$elephantBlue; overflow: hidden; white-space: nowrap; font-size: .000001px; display: block; text-decoration: none;
			&:before { content:""; display: inline-block; width: 20px; height: 20px;
				mask-image: $phone-call;
				mask-size: contain;
				mask-position: center;
				mask-repeat: no-repeat;
				background-color: $elephantBlue;}
			&:hover {}&:active {}&:focus {}
		}
	}
	.fax { max-width: 20px; margin-left: 1rem;
		a { color:$elephantBlue; overflow: hidden; white-space: nowrap; font-size: .000001px; display: block; text-decoration: none;
			&:before { content:""; display: inline-block; width: 20px; height: 20px;
				mask-image: $fax;
				mask-size: contain;
				mask-position: center;
				mask-repeat: no-repeat;
				background-color: $elephantBlue;}
			&:hover {}&:active {}&:focus {}
		}
	}
	.infoPop { max-width: 20px; margin:auto auto auto 0;
		a, button { border:0; background: 0; color:$elephantBlue; overflow: hidden; white-space: nowrap; font-size: .000001px; display: block; text-decoration: none;
			&:before { content:""; display: inline-block; width: 20px; height: 20px;transform: rotate(90deg) /*translate(-1px, 5px)*/;
				mask-image: $right-arrow;
				mask-size: contain;
				mask-position: center;
				mask-repeat: no-repeat;
				background-color: $link-hover-color;
				
			}
			&:hover {}&:active {}&:focus {}
		}
	}
	@media (min-width:992px) {
		.location, .modal-title { margin-left: 1rem; max-width: max-content; //calc(67% - 3rem - 70px);
			strong { display: inline;}
		}
		.distance { display: none;}
		.mail {max-width: 21%;
			a { font-size: 15px; text-overflow: ellipsis;
				&:before {width: 14px; height: 14px; margin-left: 5px;}
			}
		}
		.phone {max-width: 12%; margin-left: 1rem;min-width: max-content;
			a { font-size: 15px; text-overflow: ellipsis;
				&:before {width: 14px; height: 14px; margin-left: 5px;}
			}
		}
		.fax {max-width: 21%; margin-inline-start: auto;
			a { font-size: 15px; text-overflow: ellipsis;
				&:before {width: 14px; height: 14px; margin-left: 5px;}
			}
		}
		.infoPop {max-width: 70px; margin-left: 0; margin-right: auto;
			a, button { font-size: 15px; color:$link-hover-color; text-decoration: none;
				&:before { display: none;}
				&:after { content:""; display: inline-block; width: 11px; height: 11px;transform: rotate(90deg) translate(1px, 5px);
				mask-image: $right-arrow;
				mask-size: contain;
				mask-position: center;
				mask-repeat: no-repeat;
				background-color: $link-hover-color;}
				&:hover {color:darken($link-hover-color, 15%);
					&:after {background-color:darken($link-hover-color, 15%);}
				}
			}
		}
	}
}
.clinicsRow { display: flex; flex-wrap: wrap;
	>* { flex-shrink: 0; width: 100%; max-width: 100%;}
	.results {background: var(--bs-white); box-shadow: 0 0 70px #e7e7e7; border-radius: 12px; position: relative; @include scrollbar;
		>ul { padding: 0; list-style: none;
		li { display: flex; flex-wrap: wrap; line-height: 1.2;color: $endeavourBlue; padding: .7rem 1rem;
			&:not(.show):nth-child(even) { background: #f8f8f8;}
			>* {flex-shrink: 0;width: 100%;max-width: 100%;}
			@extend %rowItems;
			a {
				&:hover,&:focus { text-decoration: underline;}
			}
		}}
	}
	.map {background: var(--bs-white); box-shadow: 0 0 70px #e7e7e7; border-radius: 12px; overflow: hidden;
		iframe {height: 410px;}
	}
	
	@media (max-width:991.98px) {
		.map {display: none;}
		.results { margin-bottom: 2rem;}
	}
	@media (min-width:992px) { justify-content: space-between;
		.results { max-width: 60%; height: 540px; overflow: auto;
			>ul {li {padding: .9rem 3.2%;
				&.show { display: flex !important; background: 0;
					&:not(.even) { background: #f8f8f8;}
				}

			}}
		}
		.map {max-width: 37%;
			iframe {height: 100%;}
		}
	}
}

/*
*<div class="clinicsTop">
*/
@mixin clinicsTools {

	.tools {
		.btn { border-radius: 6px; padding: 4px;
			&:before {content: ""; display: block; width: 18px; height: 18px;
				mask-size: contain;
				
				mask-position: center;
				mask-repeat: no-repeat;
				background-color: $link-color;
			}
			&:hover {}&:active {}&:focus {}
			&:hover,&.active,&:active { background-color: $endeavourBlue;}
		}
		.showMap {&:before { mask-image: $map3;}
			&:hover {}&:active {}&:focus {}
			&:hover,&.active,&:active {
				&:before { background-color: #fff;}
			}
		}
		.showLocation {&:before { mask-image: $list;}
			&:hover {}&:active {}&:focus {}
			&:hover,&.active,&:active {
				&:before { background-color: #fff;}
			}
		}
	}
}
.clinicsTop {color: $endeavourBlue; display: flex; position: relative; z-index: 1; justify-content: space-between; margin-bottom: .5rem;
	strong {}
	@include clinicsTools;
	@media (min-width:992px) {
		.tools {display: none;}
	}
}

.stickyMobile {
	@media (max-width:991.98px) { transition: background .3s ease-in-out;
		&.stuck {background: $endeavourBlue; z-index: 3;position: fixed;top: 64px;left: 0;width: 100%;padding-left: var(--bs-gutter-x, 0.75rem);padding-right: var(--bs-gutter-x, 0.75rem);
			.ask-treatment { display: flex; justify-content: space-between;
				@include clinicsTools;
				.tools {display: block;
					.btn {border:1px solid #fff;
						&:before {background: #fff;}
						&:active,&.active {background-color: #fff !important; 
							&:before {background: $endeavourBlue;}
						}
					}
				}
				>span:not(.multiselect-native-select):not(.dropdown) {display: none;}
				.btn-clinicBig {padding: 0.2rem .75rem;margin: 0;}
				
				.multiselect-native-select { max-width: calc(100% - 70px);}
				.dropdown { margin: 0;max-width: calc(100% - 70px); position: static;}
				
				.btn-clinic {color: #fff; text-align: start; margin: 0;// width: calc(100% - 65px);
					&:after {background-color: #fff;}
				}
			}
			.clinics-nav li {
				a {border:1px solid #fff;box-shadow: none; background: 0; color: #fff;
					&:active,&.active {background-color: #fff !important; color: $endeavourBlue; }
				}
			}
		}
	}
}

.clinicItem {position: relative;@include scrollbar; z-index: 2;
	.modal-dialog { max-width: 100%; margin: 0;}
	.modal-content { border:0;}
	.modal-header {@extend %rowItems; border-bottom: 6px solid #f8f8f8; padding: 1rem 0;margin: 0 3%; flex-wrap: wrap;
		.infoPop {a, button {&:before {transform: rotate(-90deg) /*translate(-1px, 5px)*/;}}}
	}
	.modal-body { padding-top: 1rem;
		.row { margin: 0;}
		.content { line-height: 1.2;
			.head { display: flex; justify-content: space-between; align-items: flex-start;}
			h3 { font-size: 17px; font-weight: normal; color: $elephantBlue; display: flex;
				&:before {transform: translateY(4px);content: ""; display: inline-block; flex: 0 0 13px; width: 13px; height: 13px; vertical-align: -.01rem; margin-left: 3px;
					mask-image: $pin;
					mask-size: contain;
					mask-position: center;
					mask-repeat: no-repeat;
					background-color: $elephantBlue;
				}
			}
			.toMap {border-radius: 8px; border-width: 1px;padding: .3rem .4rem; white-space: nowrap;
				&:before {content: ""; display: inline-block; width: 18px; height: 18px; vertical-align: -.2rem; margin-left: 4px;
				mask-size: contain;
				
				mask-position: center;
				mask-repeat: no-repeat;
				background-color: $link-hover-color; 
				mask-image: $map3;
			transition: background-color .15s ease-in-out;}
				&:hover,&:active,&:focus {
					&:before { background-color: #000;}
				}

			}
			h4,.h4 { margin-top: 1.5rem; font-size: 17px; font-weight: bold;color: #0054a3;}
			.treatments {    padding: 0 1rem 0 0;
				li { margin-bottom: .3rem;
					&::marker {color:#9ed3f1; font-size: 120%;}
					a { text-decoration: none;color: $elephantBlue;
						&:hover,&:focus,&:active {text-decoration: underline;}
					}
				}
			}
		}
		aside {line-height: 1.2;
			.grey {border-radius: 20px;  background-color: #f8f8f8; padding:1rem 1.5rem; 
				h4 {  font-weight: bold; font-size: 17px;}
				p {
					&:last-child { margin-bottom: 0;}
				}
			}
		}
	}
	.modal-footer { flex-direction: column; align-items: flex-start; padding: calc(var(--bs-gutter-x)*.5);
		
		.whatsappLink {color: $link-hover-color; text-decoration: none; font-weight: 300;
			&:hover {text-decoration: underline;}
			&:before {transform: translateY(4px);content: ""; display: inline-block; flex: 0 0 13px; width: 13px; height: 13px; vertical-align: -.01rem; margin-left: 3px;
					mask-image: $whatsapp2;
					mask-size: contain;
					mask-position: center;
					mask-repeat: no-repeat;
					background-color: $link-hover-color;
				}
		}
		.wazeLink {color: $link-hover-color; text-decoration: none; font-weight: 300;
			&:hover {text-decoration: underline;}
			&:before {transform: translateY(4px);content: ""; display: inline-block; flex: 0 0 13px; width: 13px; height: 13px; vertical-align: -.01rem; margin-left: 3px;
					mask-image: $waze;
					mask-size: contain;
					mask-position: center;
					mask-repeat: no-repeat;
					background-color: $link-hover-color;
				}
		}
		.btn { width: 100%; max-width: 320px; margin-block: .7rem;
			&:last-child {margin-top: 0;}
		}
	}

	@media (min-width:992px) {
		.modal-header { padding: 1rem 0 .4rem;}
		.modal-body {
			.row { justify-content: space-between; align-items: flex-start;}
			.content { max-width: calc(100% - 230px);
				.head {}
				.toMap { display: none;}
				h4 {font-size: 17px;}
				.treatments { column-count: 2; gap: 1rem;}
			}
			aside { max-width: 220px; text-align: center; line-height: 1.1;}
		}
		.modal-footer { flex-direction: row;  gap:1rem; border-top: 6px solid #f8f8f8; padding: 1rem 0;margin: 0 3%;
			.wazeLink { display: none;}
			.whatsappLink {
				&:before {vertical-align: 2px;}
			}
			.btn { max-width: 175px; margin: 0 auto 0 0;
				&:last-child {margin: 0;}
			}
		}
	}
}



.mapPin { position: absolute; width: 31px; height: 40px; background: url(../images/mapPin.svg) center center/contain no-repeat;}
.mapBubble { font-size: 15px;position: absolute;width: 210px; padding: 20px;background: #fff;border-radius: 20px; line-height: 1;
	&:after {content: '';position: absolute;border-style: solid;border-width: 0 15px 15px;border-color: #fff transparent;display: block;width: 0;z-index: 1;top: -15px;left: 90px;}
	.location {border-bottom: 4px solid #f8f8f8;color: $endeavourBlue; width: 100%; margin-bottom: .4rem; line-height: inherit;
		strong { display: block;}
	}
	.address { color: $elephantBlue; margin-bottom: .4rem;
		&:before {transform: translateY(4px);content: ""; display: inline-block; flex: 0 0 13px; width: 13px; height: 13px; vertical-align: -.01rem; margin-left: 3px;
			mask-image: $pin;
			mask-size: contain;
			mask-position: center;
			mask-repeat: no-repeat;
			background-color: $elephantBlue;
		}
	}

	.mail { margin-bottom: .4rem;
		a {color:$elephantBlue; overflow: hidden; white-space: nowrap; display: block; text-decoration: none; text-overflow: ellipsis;
			&:before { content:""; display: inline-block;  flex: 0 0 13px; width: 13px; height: 13px; margin-left: 3px;
				mask-image: $mail2;
				mask-size: contain;
				mask-position: center;
				mask-repeat: no-repeat;
				background-color: $elephantBlue;}
			&:hover {}&:active {}&:focus {}
		}
	}
	
	.phone { margin-bottom: .4rem;
		a { color:$elephantBlue; overflow: hidden; white-space: nowrap; display: block; text-decoration: none; text-overflow: ellipsis;
			&:before { content:""; display: inline-block;  flex: 0 0 13px; width: 13px; height: 13px; margin-left: 3px;
				mask-image: $phone-call;
				mask-size: contain;
				mask-position: center;
				mask-repeat: no-repeat;
				background-color: $elephantBlue;}
			&:hover {}&:active {}&:focus {}
		}
	}
	.infoPop {
		a, button { border:0; background: 0; color:$link-hover-color; overflow: hidden; white-space: nowrap; display: block; text-decoration: none;
			&:after { content:""; display: inline-block; width: 8px; height: 8px;transform: rotate(180deg) /*translate(-1px, 5px)*/; margin-right: 3px;
				mask-image: $right-arrow;
				mask-size: contain;
				mask-position: center;
				mask-repeat: no-repeat;
				background-color: $link-hover-color;
				
			}
			&:hover {}&:active {}&:focus {}
		}
	}
}