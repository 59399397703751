.socialBlock { margin-bottom: 1rem;
	.container {}
	.inner {border-radius: 20px; background-color: #009b4d; color: #fff; padding: 1.9rem;}
	h3 {}
	dl { margin: 0; display: flex; flex-wrap: wrap; justify-content: space-between; font-size: clamp(1rem, 0.7986rem + 0.9512vw, 1.47rem);
		dt { width: 100%; text-align: center; font-weight: 600;margin-bottom: .6rem;}
		dd {margin: 0;}
		a {color: #fff; display: block; text-align: center; text-decoration: none; transition: all .3s ease;  font-weight: 300;
			&:hover,&:focus {text-decoration: underline;
				i {opacity: 1; transition: all .3s ease;}
			}
			i { display: block; width: 25px; height: 25px; margin: auto; opacity: .5; margin-bottom: .3rem;
				&.facebook {@include mask($facebookW, #fff);}
				&.instagram {@include mask($instagramW, #fff); }
				&.linkedin {@include mask($linkedinW, #fff); }
				&.whatsapp {@include mask($whatsapp,#fff); }
				&.email {@include mask($email, #fff); }
				&.phone {@include mask($phone-call, #fff); }
				// &.whatsapp { background: $whatsapp center center/contain no-repeat;}
				// &.facebook { background: $facebookW center center/contain no-repeat;}
				// &.instagram { background: $instagramW center center/contain no-repeat;}
				// &.linkedin { background: $linkedinW center center/contain no-repeat;}
			}
		}
	}
	@media (max-width: 991.98px) {
		.inner { margin: 0 15px 5rem; padding-bottom: .1rem;}
		dl { justify-content: center; padding-bottom: 1.3rem;
			&:first-child {margin-bottom: 1.9rem; justify-content: space-between; border-bottom: 1px solid rgba(255, 255, 255, 0.21);}

		}
	}
	@media (min-width: 992px) {
		.inner { display: flex; justify-content: center; padding:2.2rem 2.1%;gap: 2.2%;}
		dl { gap: 0 1em;
			&:first-child { /*margin-inline-end: 1.9rem;*/}
			dt { width: auto; margin: 0; /*margin-inline-end: 1.9rem;*/}
			a { /*margin-inline-end: 2.2rem;*/
				i {width: 33px; height: 33px;display: inline-block; vertical-align: middle; margin: 0; margin-inline-end: .7rem;}
			}
		}
	}
}
.socialSplitBlock {
	.container {}
	.inner { display: flex; margin: 0 15px 5rem; padding-bottom: .1rem;flex-direction: column;}
	h3 {}
	dl {width: 100%;padding-bottom: 1.9rem;border-radius: 20px; background-color: #fff;box-shadow: 0 0 35px #eeebeb; margin: 0 0 1.1rem; display: flex; flex-wrap: wrap; justify-content: space-evenly; font-size: clamp(1rem, 0.7986rem + 0.9512vw, 1.47rem);
		
		dt { width: 100%; text-align: center; font-weight: 600;margin-bottom: .6rem; color: $blue;padding-block: 2.5em .2em;}
		dd {margin: 0; min-width: 3.3529rem;}
		a {color: #fff; display: block; text-align: center; text-decoration: none; transition: all .3s ease;  font-weight: 300;
			&:hover,&:focus {text-decoration: underline;
				i {opacity: 1; transition: all .3s ease;}
			}
			i { display: block; width: 30px; height: 30px; margin: auto; margin-bottom: .3rem;
				&.facebook {@include mask($facebookW, $blue);}
				&.instagram {@include mask($instagramW, $blue); }
				&.linkedin {@include mask($linkedinW, $blue); }
				&.whatsapp {@include mask($whatsapp,#103245); }
				&.email {@include mask($email, #103245); }
				&.phone {@include mask($phone-call, #103245); }
			}
			&:hover {color:#103245;
				i {background-color: #103245;}
			}
		}
		&:nth-child(2) { background-color: #a4d4a4;
			dt { color:#103245;}
			a { color:#103245;
				i { width: 24px; height: 24px; }
				&:hover {color:$blue;
					i {background-color: $blue;}
				}
			}
		}
	}
	// @media (max-width: 991.98px) { 
	// 	.inner {}
	// 	dl { 
	// 		//&:first-child {margin-bottom: 1.9rem; justify-content: space-between; border-bottom: 1px solid rgba(255, 255, 255, 0.21);}

	// 	}
	// }
	@media (min-width: 992px) {
		.inner { justify-content: center; gap: 2.2%; margin: 0; flex-direction: row;}
		dl { gap: 0 1em; padding:2.2rem 0; margin: 0; justify-content: center;
			max-width: calc((100% / 2) - 2.2% + (2.2% / 2));
			dt { width: auto; margin: 0; padding: 0; font-weight: 700;}
			a { 
				i {width: 33px !important; height: 33px !important;display: inline-block; vertical-align: middle; margin: 0;}
			}
			&:nth-child(2) {
				a {
					i {}
				}
			}
		}
	}
}
.career {}


.recruitInfo { overflow: hidden;
	.container {}
	h2 {font-size: clamp(1.705rem, 1.2259rem + 2.2626vw, 2.823rem); text-align: center; line-height: 1;
		+ p {font-size: clamp(1rem, 0.7986rem + 0.9512vw, 1.47rem);text-align: center; font-weight: 700; line-height: 1; color: $blue;}
	}
	p {}
	nav {padding:.9rem 0 1.5rem;
		ul { list-style: none; margin: 0; padding: 0; display: flex; flex-wrap: wrap; gap:.4rem; justify-content: center;}
		li {}
		.btn {font-size: clamp(0.882rem, 0.8314rem + 0.2388vw, 1rem);--bs-btn-padding-y: 0.23rem;border-color: #fff; background: #fff; box-shadow: 0 0 8px rgba(0, 0, 0, 0.22); min-width: 9.2em;
			&:hover,&:active,&:focus,&.active {color: var(--bs-btn-active-color);
				background-color: var(--bs-btn-active-bg);
				border-color: var(--bs-btn-active-border-color); }
			&:hover {}&:active {}&:focus {}
		}
		@media (min-width: 992px) { padding-bottom: 0;}
	}
	.recruitInfoItems {list-style: none; margin: 0; padding: 0; display: flex; justify-content: space-evenly; text-align: center;
		li { }
		.step { margin: auto auto .9rem; width: 38px; height: 38px;font-family: 'GveretLevin';background-color:#c9c0de; color: #00509c; display: flex;align-items: center; justify-content: center;
			mask-image: $Object;
			mask-position: center;
			mask-size: contain;
			mask-repeat: no-repeat;
		}
		.content {font-size: clamp(0.882rem, 0.8314rem + 0.2388vw, 1rem); line-height: 1.4;
			h3 { font-weight: 600; margin-bottom: .1rem;font-size: clamp(1rem, 0.7986rem + 0.9512vw, 1.47rem);}
			.text {}
		}
		@media (max-width: 991.98px) { flex-direction: column;margin: 0 30px 1rem;
			li { padding-bottom: 4rem; background: url(../images/recruitInfoItemsM.webp) center bottom no-repeat;
				&:last-child {padding-bottom: 2rem;background: 0;}
			}
		}
		@media (min-width: 992px) { text-align: start; justify-content: center;/*background: url(../images/recruitInfoItemsDB.webp) right top/100% 100% no-repeat;*/padding: 2rem 0 8rem;
			
			li {padding: 4.6% 3% 0 7.15%; position: relative; z-index: 4;
				&:before {content: ""; position: absolute;background: url(../images/recruitInfoItemsD2.webp) right top/100% 100% no-repeat ;inset: -11% -8% -48% -8%;
					z-index: -1;}
				&:first-child {
					&:before { background-image: url(../images/recruitInfoItemsD.webp);inset: -11% 0 -48% -8%;}
				}
				&:nth-child(2) {z-index: 3;}
				&:nth-child(3) {z-index: 2;}
				&:nth-child(4) {z-index: 1;}
			
			}//padding: 4.5rem 3rem 0rem 7rem;
			.content {max-width:250px;}
			.step { width: 45px; height: 45px; margin: 0 0 1.2rem 6%; font-size: 1.352rem;}
		}
		@media (min-width: 1660px) {margin: 0 -2rem;}
	}
} 

.recruitStudents { margin-bottom: 2.7rem;
	.container {}
	.inner {background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px; text-align: center; padding: 2.5rem 1.352rem 1.5rem;}
	h2 {}
	h3 {font-size: clamp(1rem, 0.6475rem + 1.6646vw, 1.47rem); font-weight: 600;}
	.btn.btn-success { width: 100%; max-width: 340px; display: block; margin:0 auto 1rem;font-size: clamp(1.235rem, 1.1473rem + 0.4144vw, 1.352rem);
		&:hover {}&:active {}&:focus {}
	}
	.articlesShortsItem {@extend %articlesShortsItem; max-width: 1006px; margin: auto; background-color: #f8f8f8; box-shadow: none;
		figure {margin: calc(clamp(10px, 3vw, 30px) * -1); margin-bottom: calc(clamp(10px, 3vw, 30px) * -.7 );
			@media (max-width: 767.98px) { margin-bottom: 1rem;}
		}
		@media (min-width: 768px) {
			figure {width: 29.72%;}
			.content {width: 68.28%;}
		}
	}
	@media (min-width: 992px) { margin-bottom: 3.7rem;
		.inner {padding: 4.2rem 1.352rem 3.5rem; 
			.intro {max-width: 1000px; margin: auto;
				p { font-size: 1.352rem;}
			}
		}
		.btn.btn-success {max-width: 220px; margin: 1.5rem auto 2.5rem;}
	}
}

.recruitPositions {
	.container {}
	.inner {background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px; text-align: center; padding: 2.5rem 1.352rem 1.5rem;}
	h2 {}
	h3 {font-size: clamp(1rem, 0.6475rem + 1.6646vw, 1.47rem); font-weight: 600;}
	ul { list-style: none; display: flex;flex-wrap: wrap; justify-content: space-evenly; gap:1.5rem; text-align: center; padding: 1.5rem 0 0;}
	li { /*width: 100%; max-width: 50%; margin-bottom: 1.5rem;*/
		&:nth-child(1) figure:before { background-color: #fab794;transform: rotate(45deg);}
		&:nth-child(2) figure:before { background-color: #fed787;transform: rotate(130deg);}
		&:nth-child(3) figure:before { background-color: #d8d0c9;transform: rotate(0deg);}
		&:nth-child(4) figure:before { background-color: #a4d4a5;transform: rotate(30deg);}
		&:nth-child(5) figure:before { background-color: #9ed2f1;transform: rotate(90deg);}
		&:nth-child(6) figure:before { background-color: #c9c0de;transform: rotate(40deg);}
	}
	// a { color: $elephantBlue; text-decoration: none;
	// 	&:hover {}&:active {}&:focus {}
	// }
	// figure {width: clamp(5.9375rem, 4.4663rem + 6.5385vw, 12.3125rem); margin: 0 auto;
	// 	img { display: block;width: 100%; object-fit: contain;}
	// }
	a { line-height: 1; text-decoration: none;font-size: clamp(1rem, 0.7360rem + 1.2467vw, 1.352rem); display: block; color: $body-color;
		figure { overflow: hidden; position: relative; display: block;align-items: center; justify-content: center; margin: 0 auto 1rem; width: clamp(5.9375rem, 4.4663rem + 6.5385vw, 12.3125rem); aspect-ratio: 1/1; //padding: 16%;
			&:before {content:""; position: absolute;left:0; top: 0; width: 97%; padding-top: 100%;
				background-color: #dec0ce;
				mask-image: $Object;
				mask-position: center;
				mask-size: contain;
				mask-repeat: no-repeat;
				// border-radius: 37% 63% 58% 42% / 38% 39% 61% 62%;
				//z-index: -1;
			}
			img {width: 56.86%;@include centerer; }
		}
		&:hover,&:active,&:focus { color: $link-hover-color;
			figure {
				&:before {animation: spin 10s infinite linear !important;}
			}
		}
	}
	@media (min-width:768px) {
		ul {margin-bottom:1.5rem ;}
	}
	@media (min-width: 992px) { margin-bottom: 3rem;
		.inner {padding: 4.2rem 1.352rem 3.5rem; }
		ul { padding-top: 1rem;}
	}	
}



.videoStrip { margin-bottom: 2.5rem;
	figure { overflow: hidden; margin: 0; position: relative; z-index: -1;
		img { display: block;width: 100%; height: 100%; object-fit: cover;}
	}
	.btn-video { width: 100%; position: relative; border:0; background:$play center center/52px 52px no-repeat transparent;border-radius: 20px; padding: 0; overflow: hidden;
		&:hover {}&:active {}&:focus {}
	}
	@media (max-width:991.98px) {
		.btn-video {margin-inline: calc(var(--bs-gutter-x) * .5);
			width: calc(100% - var(--bs-gutter-x)); }
	}
	@media (min-width:576px) {
		figure { aspect-ratio: 400/67;}
		.btn-video { background-size: 66px 66px;aspect-ratio: 400/67;}
	}
	.workWithUs & { margin: 0;
		@media (min-width:576px) {
			figure,.btn-video {aspect-ratio: 39/20;}
		}
	}
}

.pageJobSearch { background: $mossGreen;
	.container {padding-block: 1.15rem;}
	fieldset { display: flex; flex-wrap: wrap; justify-content: center; align-items: baseline; gap:.5rem 1rem;}
	.umbraco-forms-container {display: flex; flex-wrap: wrap; gap:.5rem 1rem; justify-content: center;}
	legend {font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem); flex:1 0 100%;white-space: nowrap; text-align: center; margin: 0;}
	.form-control {font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem);/*width: clamp(7.647rem, 5.2268rem + 11.4287vw, 13.2941rem);*/}
	.form-select {font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem); 
		// color: #fff;background-color: transparent; border-color: #fff; background-image: $sw3;
		//width: clamp(7.647rem, 5.2268rem + 11.4287vw, 13.2941rem);
			// height: 34px;
			// option { color: $body-color;}
	}
	.btn { white-space: nowrap;font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem); font-weight: bold;// background: #fff;
		// &:hover,&:active,&:focus { background: transparent; color: #fff; border-color:#fff;}
	}
	@media (max-width:991.98px) {
		fieldset { flex-direction: column;}
		.umbraco-forms-container { max-width: 420px; margin: auto; margin-bottom: .5rem;}
		.form-group, .form-select { flex:0 0 calc(50% - .5rem);}
		.btn { width: 100%; max-width: 420px; margin: auto;}
	}
	@media (min-width:992px) {
		.container { padding-block: 1.75rem;}
		legend {flex:0 0 0%; font-weight: bold; text-align: start;}
		.form-select{min-width: max-content;}
		.form-control,.form-select {width: clamp(11.1rem, 8.7015rem + 4.1144vw, 13.3rem);//width: 226px; height: 53px;
			}
	}
		@media (max-width:1500px) {legend {width: 100%;flex: 0 1 100%;
			text-align: center;}}
}

.careerCarouselWrapper { 
	+ .seeAllJobs {margin-top: -17%;}
	.carousel-item { 
		img { object-fit: contain; width: 100%;}
	}
	.carousel-caption { bottom:auto; top:1rem;font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem); text-shadow: 0 0 3px #000;
		&.quote:before {content: ""; background: $quote center center/contain no-repeat; display: block;width: 30px; height: 21px; margin: auto auto 1em;}
		.title {color: #fff;font-size: clamp(1.47rem, 0.4725rem + 4.7104vw, 2.8rem);}
	}
	@media (max-width:991.98px) {
		.carousel-item { height: 400px;
			img { position: absolute; bottom: 0;width: auto; object-fit: cover; min-width: 100%; min-height: 100%; height: auto;}
		}
	}
	@media (min-width:992px) {
		+ .seeAllJobs {margin-top: -7%;}
		.carousel-caption { text-align: start;max-width: 645px; padding-top: 6%;
			&.quote:before {width: 59px; height: 42px; position: absolute;inset-inline: -5rem auto; margin:0;}
		}
	}
}


.seeAllJobs {margin-bottom: 2.5rem;position: relative;font-size: clamp(1rem, 0.6475rem + 1.6646vw, 1.3rem); font-weight: 300;
	.inner {background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px; text-align: center; padding: 2.5rem 1.352rem 1.5rem;}
	:not(.workWithUs) h2 {font-size: clamp(1.47rem, 0.5876rem + 4.1670vw, 3.529rem);} 
	strong {color: $endeavourBlue; font-weight: 800;}
	p {font-weight: 300;}
	.btn.btn-success {font-size: clamp(1rem, 0.6475rem + 1.6646vw, 1.3rem);
		&:hover {}&:active {}&:focus {}
	}
	.workWithUs & {border-radius: 20px; background-color: #f8f8f8; text-align: center; height: 100%;padding: 2.5rem 1.352rem 1.5rem;  margin: 0;
		.btn.btn-success { min-width: 270px;}
	}
	@media (min-width: 992px) { 
		p { max-width: 925px; margin-inline: auto;}
		
		.workWithUs &,.inner {padding: 4.2rem 1.352rem 3.5rem;}
		.workWithUs & {padding-bottom: 0;}
	}
	@media (max-width: 767.98px) {  .workWithUs &,.inner {margin-inline: 15px;}}
}
.workWithUs { margin-bottom: 3.5rem;}

@mixin text { padding: .5rem 0;
	.collapseJob {
		&:not(.show) {display: block; height: 3em;
			-webkit-line-clamp: 2;
			/* autoprefixer: off */
			-webkit-box-orient: vertical;
			/* autoprefixer: on */
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;}
		&.collapsing {height: 3em;}
	}
}
@mixin collapseJobBtn { margin: auto auto .5rem; display:block; max-width: max-content;color:$link-hover-color; text-decoration: none;
	&:before {content: "סגירת פרטי המשרה";}
	//&:hover {}&:active {}&:focus {}
	&.collapsed {
		&:before {content: "לפרטים נוספים";}
	}
	&:after { content:""; display: inline-block; width: 11px; height: 11px;transform: rotate(90deg) translate(1px, 5px);
		mask-image: $right-arrow;
		mask-size: contain;
		mask-position: center;
		mask-repeat: no-repeat;
		background-color: $link-hover-color;}
	&[aria-expanded="true"]:after {transform: rotate(-90deg) translate(0px, -5px);}
	&:hover {color:darken($link-hover-color, 15%);
		&:after {background-color:darken($link-hover-color, 15%);}
	}
}
.jobItem { position: relative; display: flex; flex-direction: column; background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px;padding:9% 5.5%;
	figure {margin-bottom: .5rem; overflow: hidden; position: relative; display: block;align-items: center; justify-content: center; width: clamp(2.235rem, -0.0591rem + 10.8335vw, 7.588rem); aspect-ratio: 1/1; //padding: 16%;
		&:before {content:""; position: absolute;left:0; top: 0; width: 97%; padding-top: 100%;
			background-color: var(--jobItem-img-bg,#dec0ce);
			mask-image: $Object;
			mask-position: center;
			mask-size: contain;
			mask-repeat: no-repeat;
		}
		img {width: 80%;@include centerer; }
	}
	.content { }
	.title {font-size: clamp(1.47rem, 1.3693rem + 0.4756vw, 1.705rem); font-weight: bold;}
	.jobMeta { 
		div {}
		i {display: inline-block; width: 14px; aspect-ratio: 1/1; //background: center center/contain no-repeat;
				mask-position: center;
				mask-size: contain;
				mask-repeat: no-repeat;background-color: $elephantBlue;}
		.jobId {mask-image: $thumbtack;}
		.jobLocation {mask-image:$pin;}
		.jobType {mask-image: $clock;}
	}
	.text {
		@include text;
	}
	.collapseJobBtn {
		@include collapseJobBtn;
	}
	a.share {width:34px; height: 34px; position: absolute; inset-inline-end: .5em;
		background-color: $link-color;
			mask-image: $share;
			mask-position: center;
			mask-size: 18px;
			mask-repeat: no-repeat;
		&:hover ,&:active ,&:focus {background-color: $link-hover-color;}
		&.show {background-color: $link-hover-color;}
	}
	.btn-group.dropstart {position: absolute; inset-inline-end: .5em;}
	.btn.btn-success { font-weight: bold; font-size: 1.294rem; width: max-content; white-space: nowrap;}
	@media (max-width: 991.98px) { margin: 0 15px 1.5rem; }
	@media (min-width: 992px) {padding: 3% 3.5% 2%; margin-bottom: 1.5rem; flex-direction: row;align-items: flex-start;
		figure {width: 24%;max-width: 130px; margin-inline-end: 4%;}
		.jobMeta {display: flex; gap: 9%;
			div {}
		}
		.content { max-width: 830px; width: calc(100% - (385px + 8%));}
		.collapseJobBtn {margin: .5rem 0 0; padding-bottom: 0;}
		.btn.btn-success {min-width: 255px; margin-inline-start: auto; margin-inline-end: 0;}
		a.share,.btn-group.dropstart {inset-inline-end: 340px;}
	}
}
.recruitPathContainer {}
.recruitPath {list-style: none; padding:1.6rem 0; margin: 0 0 2.5rem;display: flex; flex-wrap: wrap; gap:.8rem;
	li {padding: 6.7% 12%; width: 100%; border-radius: 20px; background-color: #fff;box-shadow: 0 0 30px #eeebeb;font-size: clamp(0.882rem, 0.7813rem + 0.4756vw, 1.117rem); line-height: 1.25;}
	.num {margin: 0 auto .75rem;font-size: clamp(1rem, 0.7360rem + 1.2467vw, 1.352rem);
		width: 45px;
		height: 45px;
		font-family: 'GveretLevin';
		background-color: $salomieYellow;
		color: #00509c;
		display: flex;
		align-items: center;
		justify-content: center;
		mask-image: $Object;
		mask-position: center;
		mask-size: contain;
		mask-repeat: no-repeat;}
	.title {font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem); line-height: 1.1; color: $endeavourBlue; font-weight: bold;display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden; margin-bottom: .3rem;

	}
	a {font-weight: bold;}
	@media (max-width: 767.98px) { text-align: center; margin-inline: 15px;}
	@media (min-width: 768px) {  padding:1.6rem 0; justify-content: center;
		li {width: 310px;padding: 2.7% 1.9%;}
		.num {margin: 0 0 .75rem;}
		.title {height: calc(clamp(0.882rem, 0.6806rem + 0.9512vw, 1.352rem) * 1.1 * 2);}
	}
}


.hotJobsContainer {margin-bottom: 2.5rem;
	.inner {position: relative; line-height: 1.3;
			background: var(--bs-white);
			box-shadow: 0 0 70px #eeebeb;
			border-radius: 18px;
			padding: 2rem 1rem 1.5rem;
		&:after {
			animation: rotation 120s infinite linear;
			content: "";
			background: url(../images/dots4.webp) center center/contain no-repeat;
			position: absolute;
			width: 78px;
			height: 78px;
			top: -2%;
			right: -3vw;
			display: block; 
			@media (min-width: 768px) {top: -4%;right: -1vw;}
		}
			
		}
	h2 {text-align: center; margin-bottom: 1rem;}
	.hotJobsList { list-style: none; margin: 0; padding: 0;
		li { padding: 0 0 1.28rem;
			&:not(:first-child) {border-top: 1px solid #ccc; padding-top: 1.28rem;}
		}
		.content {font-size: clamp(1rem, 0.9123rem + 0.4144vw, 1.117rem); padding-bottom: 1rem;
			.text{ @include text; padding: 0;
				.collapseJob:not(.show) { height: 0;}
			}
			.collapseJobBtn {@include collapseJobBtn; margin-inline: 0 auto;margin-block: 0 -1rem;}
		}
		.title {font-size: clamp(1.235rem, 0.8825rem + 1.6646vw, 1.705rem); font-weight: 700;line-height: 1;}
		.btn.btn-primary {font-size: clamp(1.235rem, 1.1473rem + 0.4144vw, 1.352rem); font-weight: 700; width: 100%; max-width: 270px;display: block;margin: auto;
			&:hover {}&:active {}&:focus {}
		}
	}
	> .text-center {
		.btn.btn-success {font-size: clamp(1.235rem, 1.1473rem + 0.4144vw, 1.352rem); max-width: 250px; width: 100%; display: block;margin: auto;
			&:hover {}&:active {}&:focus {}
		}
	}
	@media (max-width: 539.98px) {> .text-center {
		.btn.btn-success { max-width: 100%;}}}
	@media (max-width: 991.98px) {
		>.text-center {margin: 0 15px 2rem;}
		.inner {margin: 0 15px 1rem;}
	}
	@media (min-width: 992px) { margin-bottom: 4.5rem;
		.inner {padding: 3.7rem 5.1% 2.5rem; margin-bottom: 2.2rem;}
		h2 {margin-bottom: 1.8rem;}
		.hotJobsList {
			li {padding: 0 0 .85rem; display: flex; justify-content: space-between; align-items: flex-start;
				&:not(:first-child) { padding-top: 2rem; }
			}
			.btn.btn-primary { margin: 0;max-width: 250px;}
		}
	}
}


.container.whyWorkAtMacaby { text-align: center; margin-bottom: 3rem;
	h2 {}
	ul {list-style: none; margin: 0; padding: 0; display: flex; flex-wrap: wrap; justify-content:center; gap:2rem;font-size: clamp(0.823rem, 0.4262rem + 1.8735vw, 1.352rem);}
	li { min-width: 120px;max-width: 200px; line-height: 1.2;
		figure { margin-bottom: .5rem; img { display: block; width: 100%; object-fit: contain;}}
	}
	@media (max-width: 539.98px) {
		li {max-width: 43%;}
	}
	@media (min-width: 992px) { margin-bottom: 4rem;
		ul {justify-content: space-evenly;margin-top: 3rem;}
		li {
			figure { margin-bottom: 1.5rem;}
		}
	}
}
// @media (max-width: 991.98px) {.row {	margin: 0 15px;}}



.stikyJobSearch { font-weight: bold; font-size: $fz-15-23;word-spacing: 300px; line-height: 1;
	z-index: 3;
	// right: calc(100% - 6.4rem);top: 35dvh;

		right: auto;
		left: 0;
	position: fixed;width: clamp(5.294rem, 2.6134rem + 12.6585vw, 10rem); aspect-ratio: 1; overflow: hidden; border:0; background: transparent; color: #fff;
	&:before { content: ""; display: block; background: $suitcase center center/contain no-repeat;width: clamp(1.647rem, 1.4129rem + 1.1055vw, 2.058rem); aspect-ratio: 1; margin: auto;}
	&:after {content:""; position: absolute;left:0; top: 0; width: 98%; height: 98%; transition: all 4s ease-in-out;
		background-color: $red1;
		mask-image: $Object;
		mask-position: center;
		mask-size: contain;
		mask-repeat: no-repeat; z-index: -1;
	}
	@media (min-width: 992px) {top: 50dvh;}
}

.jobSearchModal {
	.modal-dialog {}
	.modal-content { overflow: hidden;}
	.modal-header { padding: 0; border:0;
		.btn-close { position: relative; z-index: 1; background-image: $closeRed; background-size: 60%;transform: translate(20px, 29px); width: clamp(1.176rem, 0.9749rem + 0.9495vw, 1.529rem); height: clamp(1.176rem, 0.9749rem + 0.9495vw, 1.529rem);}
	}
	.modal-body { border-bottom: .2941rem solid $red1; padding-bottom: 1.5rem;}
	// fieldset {
	// 	#jobSearchModalLabel {}
	// 	.umbraco-forms-container {
	// 		.form-group {
	// 			.form-select {}
	// 		}
	// 	}
	// 	.btn {}
	// } 
	fieldset { display: flex; flex-wrap: wrap; justify-content: center; align-items: baseline; gap:.5rem 1rem; font-size: $fz-17-23; color:$endeavourBlue;
		
	}
	.umbraco-forms-container {display: flex; flex-wrap: wrap; gap:1rem; justify-content: center; margin-bottom: 1.5rem;}
	legend {font-size: $fz-17-23; color: $endeavourBlue; flex:1 0 100%;white-space: nowrap; text-align: center; margin: 0; font-weight: bold;
		&:before { content: ""; display: block; aspect-ratio: 1; width: clamp(1.647rem, 1.4129rem + 1.1055vw, 2.058rem); margin:0 auto 1rem; background: $suitcaseRed center center/contain no-repeat;}
	}
	.form-control {font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem);/*width: clamp(7.647rem, 5.2268rem + 11.4287vw, 13.2941rem);*/}
	.form-select {font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem); //min-width: max-content;
		// color: #fff;background-color: transparent; border-color: #fff; background-image: $sw3;
		//width: clamp(7.647rem, 5.2268rem + 11.4287vw, 13.2941rem);
			// height: 34px;
			// option { color: $body-color;}
	}
	.btn { white-space: nowrap;font-size: clamp(1rem, 0.8491rem + 0.7124vw, 1.352rem); font-weight: bold; min-width: 13.2353rem;// background: #fff;
		// &:hover,&:active,&:focus { background: transparent; color: #fff; border-color:#fff;}
	}
	@media (max-width:991.98px) {
		fieldset { flex-direction: column;}
		.umbraco-forms-container { max-width: 420px; margin: auto; margin-bottom: .5rem;}
		.form-group, .form-select { flex:0 0 calc(50% - .5rem);}
		.btn { width: 100%; max-width: 420px; margin: auto;}
	}
	@media (min-width:768px) {
		.modal-dialog { max-width: 550px;}
	}
	@media (min-width:992px) {
		.container { padding-block: 1.75rem;}
		legend {flex:0 0 0%; font-weight: bold; text-align: start;}
		.form-control,.form-select {width: clamp(7.647rem, 5.2268rem + 11.4287vw, 13.2941rem);//width: 226px; height: 53px;
			}
	}
		@media (max-width:1500px) {legend {width: 100%;flex: 0 1 100%;
			text-align: center;}}
}