$chevronLeft : url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M390.3 473.9 180.9 264.5c-4.7-4.7-4.7-12.3 0-17L390.3 38.1c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L246.4 256l180.7 181.1c4.7 4.7 4.7 12.3 0 17l-19.8 19.8c-4.7 4.7-12.3 4.7-17 0zm-143 0 19.8-19.8c4.7-4.7 4.7-12.3 0-17L86.4 256 267.1 74.9c4.7-4.7 4.7-12.3 0-17l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L20.9 247.5c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0z'/%3E%3C/svg%3E");

$chevronLeftDouble : url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath d='m231.293 473.899 19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z'/%3E%3C/svg%3E");
$angleDown : url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5'%3E%3Cpath fill='%230054a3' d='m3.788 4.908-3.7-3.85a.326.326 0 0 1 0-.446L.583.093a.29.29 0 0 1 .425 0L4 3.201 6.992.093a.29.29 0 0 1 .425 0l.495.519a.326.326 0 0 1 0 .446l-3.7 3.85a.29.29 0 0 1-.425 0Z'/%3E%3C/svg%3E");

@function unicode($str){ 
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"")
};
/*.ami-podcast:before { content:unicode("e939");}*/

.float-right, .pull-right {
    float: right!important;
}
.float-left, .pull-left { 
    float: left!important;
}
:dir(rtl) {
  .float-right, .pull-right {
      float: inline-end !important; transform: rotate(180deg);
    }
    .float-left, .pull-left {
      float: inline-start !important; transform: rotate(180deg);
    }
}
.datepickerWrapper {
	.input-group {
		.form-control.datepicker,
		.form-control.date {
			&[readonly] { background: #fff;border-radius: 6px;}
		}
		.datepicker-button.bootstrap4.input-group-append { position: absolute; width: 100%; z-index: 4;height: 100%;direction: ltr;
		
			.input-group-text { height: 100%; border:0; background: rgba(0, 0, 0, 0);}
			&:hover, &:focus {  background: 0;.input-group-text {color: red;}}
		}
	}
}
/* 
 * sizes and positions
 */

a.datepicker-button {
  cursor: pointer;
}

div.datepicker-calendar {
  margin: 0 10px 10px 0;
  font-size: clamp(0.882rem, 0.8548rem + 0.1286vw, 1rem);
  padding: 2px;
  position: absolute;
  width: 261px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 50; overflow: hidden;

  div.datepicker-month-wrap {
    margin: 0;
    padding-top: 1px;
    text-align: center;
    height: 30px;
  }

  &.bootstrap4 div.datepicker-month-wrap {
    > div {
      padding-top: 0;
    }

    div {
      &.datepicker-month-prev, &.datepicker-month-next {
        padding-top: 2px;
      }
    }

    i { width: 14px; height: 14px;background-color: $blue;mask-position: center center;mask-size:contain;mask-repeat: no-repeat;
      &:before {display: none;} 
      &.fa-forward, &.fa-backward {
        font-size: .8em;
      }
      &.fa-caret-left, &.fa-caret-right {
        font-size: 1.1em;
      }
      //r
      &.fa-forward { mask-image: $right-arrow;}
      &.fa-caret-right { mask-image: $dblarr;}


      //l
      &.fa-backward { mask-image: $right-arrow; transform: scale(-1);}
      &.fa-caret-left { mask-image: $dblarr; transform: scale(-1);}
    }
  }

  div {
    &.datepicker-month-fast-prev.disabled, &.datepicker-month-fast-next.disabled, &.datepicker-month-prev.disabled, &.datepicker-month-next.disabled {
      border: none;
      cursor: default;
    }

    &.datepicker-month-fast-prev, &.datepicker-month-fast-next, &.datepicker-month-prev, &.datepicker-month-next {
      background:0; //#D8D8D8;
      cursor: pointer;
      margin: 3px;
      width: 24px;
      height: 24px;
      padding: 0 !important;  border-radius: 100%; display: flex;justify-content: center; align-items: center;
    }

    &.datepicker-month-fast-prev {
      padding-right: 2px;
    }

    &.datepicker-month-fast-next {
      padding-left: 2px;
    }

    &.datepicker-month-fast-prev.enabled {
      &:hover, &:focus {
        margin-top: 2px;
        border: 1px solid #800;
        // border-radius: 4px;
      }
    }

    &.datepicker-month-fast-next.enabled {
      &:hover, &:focus {
        margin-top: 2px;
        border: 1px solid #800;
        // border-radius: 4px;
      }
    }

    &.datepicker-month-prev.enabled {
      &:hover, &:focus {
        margin-top: 2px;
        border: 1px solid #800;
        // border-radius: 4px;
      }
    }

    &.datepicker-month-next.enabled {
      &:hover, &:focus {
        margin-top: 2px;
        border: 1px solid #800;
        // border-radius: 4px;
      }
    }

    &.datepicker-month {
      margin: 3px auto 2px;
      height: 24px;
      text-align: center;
      font-weight: 500;
      //font-size: 1.2em;
      cursor: pointer;
      padding-top: 1px;max-width: calc(100% - 125px);
      border:1px solid #ccc;
      border-radius: 4px;
      font-size: clamp(1rem, 0.9730rem + 0.1275vw, 1.117rem);
    }
  }

  &:not(.bootstrap4) div.datepicker-month:after {
    font-family: 'Glyphicons Halflings';
    font-size: 0.6em;
    display: inline-block;
    content: "\e252";
  }

  &.bootstrap4 div.datepicker-month:after {
    // font-family: 'Font Awesome 5 Free';
    // font-size: 0.7em;
    // -moz-osx-font-smoothing: grayscale;
    // -webkit-font-smoothing: antialiased;
    display: inline-block;
    // font-style: normal;
    // font-variant: normal;
    // text-rendering: auto;
    // line-height: 1;
    padding: 0 .4em;
    // content: "\f078";
    // content:unicode("f078");
    vertical-align: middle;
    width: 8px;
    height: 5px;
    background: $angleDown center center/contain no-repeat;
    content: "";
    color: #000;
  }

  div.datepicker-month {
    &:hover, &:focus {
      padding-top: 0;
      border: 1px solid #800;
      border-radius: 4px;
    }
  }

  table {
    &.datepicker-grid {
      width: 100%;
    //   font-size: 1.2em;
      text-align: center;

      &.rtl {
        direction: rtl;
      }

      &:focus {
        outline: none;
      }
    }

    &.datepicker-weekdays {}

    &.datepicker-grid {
      th, td {
        text-align: center;
        padding: 0;
      }

      th abbr {
        border: none; text-decoration: none; font-weight: 500;
      }

      td {
        border: 1px solid #999;
        cursor: pointer;

        &.unselectable {
          cursor: default;
        }
      }

      th {
        height: 30px;
      }

      td {
        &.day {
          height: 30px;
        }

        &.month {
          height: 60px;
        }

        &.year {
          height: 45px;
        }

        &.curDay, &.curMonth, &.curYear {
          border: 1px solid #999;
        }

        &.empty {
          border: 1px solid #999;
          cursor: default;
        }
      }
    }
  }

  .offscreen {
    position: absolute;
    left: -200em;
    top: -100em;
  }
}

.datepicker-overlay {
  background: #777;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  height: 100%;
  min-height: 100%;
  z-index: 40; font-weight: bold;
}

div.datepicker-calendar button.datepicker-close {
  height: 30px;
  width: 100%;
  font-weight: bold;
  margin-top: 1px;
  border: 1px solid #eee;
  border-radius: 2px;
}

/**************************** 
 * colors
 ***************************/

/**************************** 
 * default theme colors
 ***************************/

a.datepicker-button.default {
  &:hover, &:focus {
    background-color: #DDD;
  }
}

div.datepicker-calendar.default {
  background-color: #FFF;

  div {
    &.datepicker-month-wrap {}

    &.datepicker-month-fast-prev.disabled, &.datepicker-month-fast-next.disabled, &.datepicker-month-prev.disabled, &.datepicker-month-next.disabled {
      color: #999;
    }

    &.datepicker-month-fast-prev.enabled {
      &:hover, &:focus {
        background-color: #999;
        border-color: #999;
      }
    }

    &.datepicker-month-fast-next.enabled {
      &:hover, &:focus {
        background-color: #999;
        border-color: #999;
      }
    }

    &.datepicker-month-prev.enabled {
      &:hover, &:focus {
        background-color: #999;
        border-color: #999;
      }
    }

    &.datepicker-month-next.enabled {
      &:hover, &:focus {
        background-color: #999;
        border-color: #999;
      }
    }

    &.datepicker-month {
      &:hover, &:focus {
        background-color: #EEE;
        border-color: #999;
      }
    }
  }

  button.datepicker-close {
    &:hover, &:focus {
      background-color: #EEE;
      border-color: #999;
    }
  }

  table.datepicker-grid:focus {
    outline: 1px dotted #999;
  }

  tr.datepicker-weekdays {
    th { padding-bottom: .3rem;}
    // border-top: 1px solid #999;
    // border-bottom: 1px solid #999;
  }

  table.datepicker-grid {
    th {
      // background-color: #EEE;
      border: none;
    }

    td {
      border: none;
      color: #000;

      &.unselectable {
        color: #999;
      }

      &.curDay, &.curMonth, &.curYear {
        background-color: #FFF0C4;
      }

      &.empty {
        border: none;
        color: #CCC;
      }

      &.selectable {
        &:hover, &.focus { border-radius: 5px;
          background-color: #DDD;
        }
        &.curDay {background-color: red;}
      }

      &.empty:hover {
        background-color: #F9F9F9;
      }
    }
  }

  button.datepicker-close {
    background-color: #DDD;
    border-color: #999;
    color: #000;
  }
}

/**************************** 
 * bootstrap theme colors
 ***************************/

a.datepicker-button.bootstrap {
  &:hover, &:focus {
    background-color: #DDD;
  }
}

div.datepicker-calendar.bootstrap {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  min-width: 200px;
  font-size: .8em;
  background-clip: padding-box;
  background-color: #FFF;
  border-radius: 4px;
  line-height: 20px;

  &.below {
    margin-top: 2px;
  }

  &.above {
    margin-top: -2px;
  }

  &:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 7px;
  }

  &:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-top: 0;
    position: absolute;
    left: 6px;
  }

  &.below {
    &:before {
      top: -7px;
    }

    &:after {
      top: -6px;
    }
  }

  &.above {
    &:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid #999;
    }

    &:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #ffffff;
    }
  }

  div {
    &.datepicker-month-wrap {}

    &.datepicker-month-fast-prev.disabled, &.datepicker-month-fast-next.disabled, &.datepicker-month-prev.disabled, &.datepicker-month-next.disabled {
      color: #999;
    }

    &.datepicker-month-fast-prev.enabled {
      &:hover, &:focus {
        background-color: #EEE;
        border-color: #999;
      }
    }

    &.datepicker-month-fast-next.enabled {
      &:hover, &:focus {
        background-color: #EEE;
        border-color: #999;
      }
    }

    &.datepicker-month-prev.enabled {
      &:hover, &:focus {
        background-color: #EEE;
        border-color: #999;
      }
    }

    &.datepicker-month-next.enabled {
      &:hover, &:focus {
        background-color: #EEE;
        border-color: #999;
      }
    }

    &.datepicker-month {
      &:hover, &:focus {
        background-color: #EEE;
        border-color: #999;
      }
    }
  }

  button.datepicker-close {
    &:hover, &:focus {
      background-color: #EEE;
      border-color: #999;
    }
  }

  table.datepicker-grid:focus {
    outline: none;
  }

  tr.datepicker-weekdays {
    border: none;
  }

  table.datepicker-grid {
    th abbr {
      text-decoration: none;
      cursor: default;
    }

    td {
      border: none;
      color: #000;

      &.unselectable {
        color: #999;
      }

      &.curDay, &.curMonth, &.curYear {
        background-color: #FFF0C4;
      }

      &.empty {
        border: none;
        color: #CCC;
      }

      &.selectable {
        &:hover, &.focus {
          background-color: #DDD;
        }
      }

      &.empty:hover {
        background-color: #F9F9F9;
      }
    }
  }

  button.datepicker-close {
    background-color: #DDD;
    border-color: #999;
    color: #000;
  }
}

/**************************** 
 * maroon theme colors
 ***************************/

a.datepicker-button.maroon {
  color: #FFF;
  background-color: #A63950;

  &:hover, &:focus {
    color: #FFF;
    background-color: #802C3E;
  }
}

div.datepicker-calendar.maroon {
  background-color: #FFF;
  border-color: #CCC;

  div {
    &.datepicker-month-wrap {
      background-color: #522A3F;
      color: #FFF;
    }

    &.datepicker-month-fast-prev.disabled, &.datepicker-month-fast-next.disabled, &.datepicker-month-prev.disabled, &.datepicker-month-next.disabled {
      color: #999;
    }

    &.datepicker-month-fast-prev.enabled {
      &:hover, &:focus {
        background-color: #A63950;
        border-color: #800;
      }
    }

    &.datepicker-month-fast-next.enabled {
      &:hover, &:focus {
        background-color: #A63950;
        border-color: #800;
      }
    }

    &.datepicker-month-prev.enabled {
      &:hover, &:focus {
        background-color: #A63950;
        border-color: #800;
      }
    }

    &.datepicker-month-next.enabled {
      &:hover, &:focus {
        background-color: #A63950;
        border-color: #800;
      }
    }

    &.datepicker-month {
      &:hover, &:focus {
        background-color: #A63950;
        border-color: #800;
      }
    }
  }

  button.datepicker-close {
    &:hover, &:focus {
      background-color: #A63950;
      border-color: #800;
    }
  }

  table.datepicker-grid:focus {
    outline: 1px dotted #A63950;
  }

  tr.datepicker-weekdays {}

  table.datepicker-grid {
    th {
      background-color: #A63950;
      color: #FFF;
    }

    td {
      background-color: #EEE;
      border-color: #999;

      &.unselectable {
        color: #999;
      }

      &.curDay, &.curMonth, &.curYear {
        background-color: #FFF0C4;
      }

      &.empty {
        background-color: #F9F9F9;
        color: #AAA;
      }

      &.selectable {
        &:hover, &.focus {
          background-color: #B55A6E;
          color: #FFF;
          border-color: #800;
        }
      }

      &.empty:hover {
        background-color: #F9F9F9;
        border-color: #999;
      }
    }
  }

  button.datepicker-close {
    background-color: #522A3F;
    color: #FFF;
    border-color: #EEE;
  }
}

/**************************** 
 * blue theme colors
 ***************************/

a.datepicker-button.blue {
  color: #FFF;
  background-color: #063A5B;

  &:hover, &:focus {
    background-color: #262A33;
  }
}

div.datepicker-calendar.blue {
  background-color: #FFF;
  border-color: #CCC;

  div {
    &.datepicker-month-wrap {
      background-color: #063A5B;
      color: #FFF;
    }

    &.datepicker-month-fast-prev.disabled, &.datepicker-month-fast-next.disabled, &.datepicker-month-prev.disabled, &.datepicker-month-next.disabled {
      color: #999;
    }

    &.datepicker-month-fast-prev.enabled {
      &:hover, &:focus {
        background-color: #0B6BA8;
        border-color: #3484B7;
      }
    }

    &.datepicker-month-fast-next.enabled {
      &:hover, &:focus {
        background-color: #0B6BA8;
        border-color: #3484B7;
      }
    }

    &.datepicker-month-prev.enabled {
      &:hover, &:focus {
        background-color: #0B6BA8;
        border-color: #3484B7;
      }
    }

    &.datepicker-month-next.enabled {
      &:hover, &:focus {
        background-color: #0B6BA8;
        border-color: #3484B7;
      }
    }

    &.datepicker-month {
      &:hover, &:focus {
        background-color: #0B6BA8;
        border-color: #3484B7;
      }
    }
  }

  button.datepicker-close {
    &:hover, &:focus {
      background-color: #0B6BA8;
      border-color: #3484B7;
    }
  }

  table.datepicker-grid:focus {
    outline: 1px dotted #0B6BA8;
  }

  tr.datepicker-weekdays {}

  table.datepicker-grid {
    th {
      background-color: #0B6BA8;
      color: #FFF;
    }

    td {
      background-color: #EEE;
      border-color: #999;

      &.unselectable {
        color: #999;
      }

      &.curDay, &.curMonth, &.curYear {
        background-color: #FFF0C4;
      }

      &.empty {
        background-color: #F9F9F9;
        color: #AAA;
      }

      &.selectable {
        &:hover, &.focus {
          background-color: #0B6BA8;
          color: #FFF;
          border-color: #3484B7;
        }
      }

      &.empty:hover {
        background-color: #F9F9F9;
        border-color: #999;
      }
    }
  }

  button.datepicker-close {
    background-color: #063A5B;
    color: #FFF;
    border-color: #EEE;
  }
}

/**************************** 
 * green theme colors
 ***************************/

a.datepicker-button.green {
  color: #FFF;
  background-color: #0C5E2F;

  &:hover, &:focus {
    background-color: #262A33;
  }
}

div.datepicker-calendar.green {
  background-color: #FFF;
  border-color: #CCC;

  div {
    &.datepicker-month-wrap {
      background-color: #0C5E2F;
      color: #FFF;
    }

    &.datepicker-month-fast-prev.disabled, &.datepicker-month-fast-next.disabled, &.datepicker-month-prev.disabled, &.datepicker-month-next.disabled {
      color: #999;
    }

    &.datepicker-month-fast-prev.enabled {
      &:hover, &:focus {
        background-color: #0D803B;
        border-color: #36955C;
      }
    }

    &.datepicker-month-fast-next.enabled {
      &:hover, &:focus {
        background-color: #0D803B;
        border-color: #36955C;
      }
    }

    &.datepicker-month-prev.enabled {
      &:hover, &:focus {
        background-color: #0D803B;
        border-color: #36955C;
      }
    }

    &.datepicker-month-next.enabled {
      &:hover, &:focus {
        background-color: #0D803B;
        border-color: #36955C;
      }
    }

    &.datepicker-month {
      &:hover, &:focus {
        background-color: #0D803B;
        border-color: #36955C;
      }
    }
  }

  button.datepicker-close {
    &:hover, &:focus {
      background-color: #0D803B;
      border-color: #36955C;
    }
  }

  table.datepicker-grid:focus {
    outline: 1px dotted #0D803B;
  }

  tr.datepicker-weekdays {}

  table.datepicker-grid {
    th {
      background-color: #0D803B;
      color: #FFF;
    }

    td {
      background-color: #EEE;
      border-color: #999;

      &.unselectable {
        color: #999;
      }

      &.curDay, &.curMonth, &.curYear {
        background-color: #FFF0C4;
      }

      &.empty {
        background-color: #F9F9F9;
        color: #AAA;
      }

      &.selectable {
        &:hover, &.focus {
          background-color: #0D803B;
          color: #FFF;
          border-color: #36955C;
        }
      }

      &.empty:hover {
        background-color: #F9F9F9;
        border-color: #999;
      }
    }
  }

  button.datepicker-close {
    background-color: #0C5E2F;
    color: #FFF;
    border-color: #EEE;
  }
}