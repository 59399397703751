/*
*<div class="modal fade centerNoteModal" id="centerNoteModal" tabindex="-1" aria-labelledby="centerNoteModalLabel" aria-hidden="true">
*/

.modalBg {position: absolute;inset: 0 0 0 0;overflow: hidden;z-index: -1;
	&:before,&:after {animation: morph 105s linear infinite alternate, spin 70s linear infinite;content: "";width: 30vmax;height: 30vmax;position: absolute;background: rgba(239, 233, 229, 0.274);right: 0vmin;left: auto;top: 0vmin;z-index: -1;will-change: border-radius, transform;transform-origin: 45% 45%;pointer-events: none;}
	&:after {animation: spin 65s linear infinite reverse;width: 25vmin;height: 16vmin;right: auto;left: 3vmin;top: auto;background: url(../images/bul-5.svg) left bottom/ 100px auto no-repeat, url(../images/bul-2.svg) left 115px bottom 50px/ 37px auto no-repeat;bottom: -9vmin;transform-origin: 30% 30%}
}
@media (prefers-reduced-motion: reduce) {
	.modalBg {
		&:before,&:after {animation:none;}
		&:before {border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%}}
}
// @media (prefers-reduced-motion: no-preference) {
// 	.modalBg {
// 		&:before,
// 		&:after {	}
// 		&:after {}
// 	}
// }
.umbraco-forms-container .umbraco-forms-field-wrapper {text-align: start !important;}
.umbraco-forms-container .field-validation-error {
	color: var(--bs-danger);
	font-size: 80%;
	line-height: 1;
	display: inline-block;
}
.centerNoteModal {
	.modal-dialog {}
	.topPic { transform: translateY(-4.7059rem);}
	.modal-content {border-radius: 20px; box-shadow: 0 0 68px 0 rgba(0, 1, 0, 0.28); border:0; overflow: hidden; z-index: 1;}
	.modal-header { border:0;align-items: flex-start;
		.modal-title { order:-1; flex:1 0 auto; text-align: center; line-height: 1.4;}
	}
	.btn-close {margin: .2rem auto -0.5rem 0rem; width: 23px; height: 23px; background: transparent escape-svg($btn-close-bg2) center / 23px auto no-repeat;
		&:hover {}&:active {}&:focus {}
		+ .modal-title { padding-inline-start: 40px;}
	}
	.modal-body { text-align: center;padding: clamp(1rem, 0.0769rem + 4.3590vw, 5rem) 1rem clamp(3rem, 1.6154rem + 6.5385vw, 9rem);
		// background: url(../images/bul-5.svg) left -50px bottom -50px/ 100px auto no-repeat,
		// 	url(../images/bul-2.svg) left 50px bottom 50px/ 37px auto no-repeat,
		// 	url(../images/bul-7.svg) right -200px bottom -200px/ 400px auto no-repeat
		// ;
	}
	.modal-title { color: $endeavourBlue; margin-top: 0;}
	h3 {font-size: clamp(1.176rem, 1.0403rem + 0.6408vw, 1.764rem); margin-bottom: 1em;
		strong {}
	}
	.btn:not(.btn-lg) { min-width: 180px;font-weight: 600; line-height: 1.8; margin-bottom: .7rem;}
	.btn-lg { font-weight: bold; border-radius: 32px; min-width: 255px; line-height: 2;
		&:hover {}&:active {}&:focus {}
		&:disabled { background: #ebebeb; border-color: #ebebeb; color: #9c9c9c;}
	}
	.canDo {
		strong { color: $link-hover-color; font-size: 23px;}
		ul { list-style: none; padding: 0; display: flex; text-align: center; gap:1rem; flex-wrap: wrap; margin: auto auto 1rem; justify-content: center;
			li {color: $endeavourBlue; font-size: 1rem; max-width: 160px;
				&:before {content:""; display: block; margin: auto auto .6rem; width: 25px; height: 25px;
					background-color: $link-hover-color;
					mask-image: $check-round;
					mask-position: center;
					mask-size: contain;
					mask-repeat: no-repeat; z-index: -1;
				}
			}
		}
	}
}

.otherTreatmentModal {
	.modal-dialog {max-width: 705px;}
	.modal-content {border-radius: 20px; box-shadow: 0 0 68px 0 rgba(0, 1, 0, 0.28); border:0; overflow: hidden;}
	.modal-header { border:0; padding-bottom: 0;}
	.btn-close {margin: .2rem auto -0.5rem 0rem; width: 23px; height: 23px; background: transparent escape-svg($btn-close-bg2) center / 23px auto no-repeat;
		&:hover {}&:active {}&:focus {}
	}
	.modal-body { text-align: center; padding: 0 1rem 3rem;
		background: url(../images/bul-5.svg) left -50px bottom -50px/ 100px auto no-repeat,
			url(../images/bul-2.svg) left 50px bottom 50px/ 37px auto no-repeat,
			url(../images/bul-7.svg) right -200px bottom -200px/ 400px auto no-repeat
		;
	} 
	.modal-title { color: $endeavourBlue; margin-top: 0;}
	h2 {font-size: clamp(1.117rem, 1.0355rem + 0.3847vw, 1.47rem);}
	h3 {font-size: clamp(0.882rem, 0.8548rem + 0.1286vw, 1rem); margin-bottom: 2em; font-weight: 700;
		strong {}
	}
	.form { padding: 0 9%;}
	textarea { min-height: 120px; resize: none;}
	.btn { min-width: 180px;
		@media (max-width:767.98px) { width: 100%;}
	}
}

.largeButtons { display: flex; justify-content: center; gap:1rem; flex-wrap: wrap;
	.btn {min-width: 44%;}
	// .btn { font-size: clamp(1rem, 0.7995rem + 0.9468vw, 1.352rem); line-height: clamp(1em,3rem,1.8em); width: 44%;border-radius: clamp(1.294rem, 1.2610rem + 0.1560vw, 1.352rem);}
}

.serviceTerms {
	.modal-dialog {max-width: 1000px;}
	.modal-header .modal-title { padding-top: 1rem; margin-bottom: 0; line-height: 1.2;}
	.scrollWrapper {border-radius: 20px; background-color: #fff;box-shadow: 0 0 15px #eeebeb; padding:1.1rem 10px;margin: 0 6%;position: relative;
		&:after { content: ""; position: absolute; inset: auto .8rem 0; //opacity: 0.71;
			background-image: linear-gradient(to top, #ffffff 0%, #ffffff 14%, #0000); height: 5rem;}
	}
	.scroll {font-size: 0.882rem; text-align: start;height: 310px; overflow: auto;  @include scrollbar; padding-inline-start: .5rem;
		
	}
	.ftr {margin: 0 calc(6% + 20px); display: flex; align-items: flex-start; justify-content: space-between; flex-wrap: wrap; padding:1.5rem 0; font-weight: bold;
		.checkbox input { width: 22px;
			height: 22px;
			vertical-align: text-bottom;}
	}
	// .modal-footer { text-align: left;border:0;
	// 	.checkbox { display: inline-block; margin: 0 0 0 2em;}
	// 	// @media (max-width:767.98px) { 
	// 	// 		.checkbox { margin:0;}
	// 	// }
	// }
}

.jobFormModal {
	.modal-body { padding-block:0 2.5rem;}
	fieldset {} 
	.umbraco-forms-container { display: flex; flex-direction: column;
		.form-group {width: 100%;}
	}
	
	.form-floating > .form-control[type=file]:not(:placeholder-shown) {
		// [aria-hidden="true"] {display: none !important;}
		~ label {transform: translateY(.14rem) translateX(-.5rem);color: #103245; font-size: 1rem;}
		&::file-selector-button {min-width: 120px;
			background: 0;
			border: 0;
		}
		// &:not([val]) {
		// 	&::file-selector-button { color:red}
		// }
	}
	@media (min-width:768px) {
		fieldset { padding-inline: 5%;} 
		.umbraco-forms-container { flex-direction: row; flex-wrap: wrap; gap:1.5rem;
			.form-group { max-width: calc(50% - .75rem);color: #103245;
				&.longanswer {max-width: 100%;
					textarea.form-control { height: 5rem; resize: none;}
				}
			}
		}
	}
}

// .reservationDone {
// 	.text { text-align: start;
// 		$colors: (
// 			// $white : #ffffff;
// 				// $alabaster : #efe9e5;
// 				// $springWood : #fbf9f8;;#128851
// 				$grayPurple// : #c9c0de;
// 				$roseOrange// : #fab794;
// 				$hazeGreen// : #008743; //#008743;#009b4d;
// 				$hazeGreenA// : #128851; //009b4d
// 				$peranoBlue// : #9ed1f1;
// 				$salomieYellow// : #fed786;
// 				$pastelGray// : #d8d0c9;
// 				$mossGreen// : #a4d4a5;
// 				$endeavourBlue// : #0054a3;
// 				$elephantBlue// : #103245;
// 				//// $pampasGrey : #f6f4f2;
// 			// #FF5733, // Red
// 			// 		#33FF57, // Green
// 			// 		#3357FF, // Blue
// 			// 		#F1C40F, // Yellow
// 			// 		#8E44AD, // Purple
// 			// 		#E67E22, // Orange
// 			// 		#2ECC71, // Light Green
// 			// 		#3498DB, // Light Blue
// 			// 		#9B59B6, // Lavender
// 			// 		#E74C3C // Dark Red
// 				);
		
// 			ul {
// 				// list-style: none; // Remove default bullets
// 				// padding: 0; // Remove default padding
		
// 				li {
// 					position: relative; // Required for positioning the marker
// 					padding-left: 20px; // Space for the custom marker
		
// 					// Apply marker colors based on the index of the list item
// 					@for $i from 1 through 10 {
// 						&:nth-child(#{$i})::marker {
// 							color: nth($colors, $i);
// 							font-size: 2em; // Size of the marker
// 						}
// 					}
// 				}
// 			}
// 	}
// 	.buttons {}
// }

.videoModal {
	.modal-content { border-radius: 0; background: 0; box-shadow: none;}
	.modal-header { padding: 0 0 .5rem;}
	.modal-body {padding: 0;}
	.btn-close { background-color: #fff;}
}

.virtualModal {
	&.centerNoteModal .modal-body {padding: 1.5rem 1rem 3rem;}
	.fuzzyList { max-width:33.5294rem; margin-inline: auto; text-align: start; padding-block:1rem;
		li { margin-bottom: .5rem;
			&::marker {font-size: 160%;}
		}
	}
	.btn {font-size: clamp(17px, 3.3vw, 23px); font-weight: normal !important;min-width: 235px !important; line-height: 1.5 !important;}
}

.healthDeclarationSubmit {
	.modal-body { padding-top: 0;}
	.modal-title {margin-bottom: 2rem ;}
	.inner { font-size: 1.3529rem; color: #0054A3;max-width: 500px; margin-inline:auto ;}
	p { line-height: 1.3; margin-bottom: 2rem;}
	.progress { margin-inline: auto; max-width: 18.5rem; position: relative;}
	.progress-info {font-size: 1rem; color: #0054A3;}
}