
.megaSearchBox { margin-bottom: 2.5rem;
	form {background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px; padding: calc(var(--bs-gutter-x)*.5); padding-top: 1.5rem;}
	.d-flex { flex-wrap: wrap; justify-content: space-between;
		.form-control {max-width: calc(100% - 50px);}
		.btn.btn-secondary {
			&:hover {}&:active {}&:focus {}
		}
		.btn-primary {width: 40px;height: 40px; display: flex; align-items: center; justify-content: center; padding: 0;
			&:before {width: 40px;height: 40px;content:"";background-color: #fff;mask-image: $search-1;mask-position: center; mask-size: 21px auto; mask-repeat: no-repeat;}
			&:hover,&:focus {&:before { background-color: $elephantBlue;}}
		}
		.rescount { width: 100%;padding: 1.5rem 1rem 0; font-weight: 700; font-size: 1.25rem;}
	}
}

.searchResults { margin-bottom: 2.5rem;
	.results-list { list-style: none;  margin: 0;background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px; padding: calc(var(--bs-gutter-x)*.5); padding-top: 1.5rem;
		@media (min-width: 992px) { column-count: 2; column-gap: 2rem;}
	}
	.result-item { display: flex; align-items: flex-start; line-height: 1.2; margin-bottom: 1rem;break-inside: avoid;
		.ico {background-color: var(--icoBg-color, $endeavourBlue); margin-inline-end: 1rem;
			mask-image: $Object;
			mask-position: center;
			mask-size: contain;
			mask-repeat: no-repeat; padding: 5px;
			img {height: 41px; width: auto; display: block;}
		}
		.holder {}
		a {
			img {}
			h3 { margin: 0;}
			&:hover {}&:active {}&:focus {}
		}
	}
}