// @import "fonts"; 
@use 'sass:math';
@import "tools";
$white :#ffffff;
$alabaster :#efe9e5;
$peranoBlue :#9ed1f1;
$endeavourBlue :#0054a3;
$elephantBlue :#103245;
$springWood :#fbf9f8;
$pastelGray :#d8d0c9;
$mossGreen :#a4d4a5;
$hazeGreen :#008743;//#008743;#009b4d;
$hazeGreenA :#128851;//009b4d;#128851
$grayPurple :#c9c0de;
$roseOrange :#fab794;
$salomieYellow :#fed786;
$pampasGrey :#f6f4f2;
$red1:#F05A57;

$fz-15-23 :clamp(0.882rem, 0.6143rem + 1.2642vw, 1.352rem);
$fz-17-23 :clamp(1rem, 0.7995rem + 0.9468vw, 1.352rem);

// $body-bg:                   $white !default;
$body-color:		$elephantBlue !default;
$breadcrumb-active-color:		$elephantBlue !default;
$blue:    			$endeavourBlue !default;
$headings-color:	$endeavourBlue !default;
$link-color:		$endeavourBlue !default;
$green:				$hazeGreen !default;
$link-hover-color: 	$hazeGreen !default;
$body-text-align:	right !default;
$font-family-sans-serif:      "ploni", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$btn-border-width:            2px !default;
$btn-border-radius:           clamp(25px,3.4vw,26px) !default;
$btn-padding-y:               .427rem;
$enable-reduced-motion:       false !default;
$input-border-width: 	2px !default;
$input-border-color: 	#efefef !default;
$input-border-radius: 24px  !default;

$form-floating-height:            calc(2.5rem + calc($input-border-width * 2)) !default;
$form-floating-line-height:       1.25 !default;
// $form-floating-padding-x:         $input-padding-x !default; 
$form-floating-padding-y:         .8rem !default;
$form-floating-input-padding-t:   .7rem !default;
$form-floating-input-padding-b:   .625rem !default;
$form-floating-label-opacity:     .65 !default;
$form-floating-label-transform:   translateY(-1.3rem) translateX(-1rem) !default;
$btn-close-bg2: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$hazeGreen}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$form-select-bg-position:left .75rem center;
$form-select-feedback-icon-position:center left 2.25rem;
.foo {}

// @import "bootstrap/bootstrap"; 
@import "bootstrap5.2.3/bootstrap";

@import "bootstrap-multiselect";
@import "datepicker"; 
@import "searchRes";
dialog { padding: 0; border:0; background: 0;
&::backdrop {
		background: rgba(255, 0, 0, 0);
	}
}

.text-end { text-align: end !important; }
.text-start { text-align: start !important;}
.float-end { float: inline-end !important; }
.float-start { float: inline-start !important; }

.form-select {
  padding: $form-select-padding-y $form-select-padding-x $form-select-padding-y $form-select-indicator-padding;
  // stylelint-disable-next-line property-no-vendor-prefix
  -moz-padding-start: subtract($form-select-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636

}
.form-check:not(.form-switch) .form-check-input {
	float: inline-start;
	margin-inline-start: -1.5em;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
	color: #BFBFBF;
	pointer-events: none;
	background-color: #F2F2F2;
	border-color: #F2F2F2;
	opacity: 1;
}





.sr-only {@extend .visually-hidden;}
// .form-floating>label {left:auto; right: 0;}
.modal-header .btn-close {margin: -0.5rem auto -0.5rem -0.5rem;}
.breadcrumbNav {--bs-breadcrumb-divider: '›';margin-bottom: 1.82rem; margin-top: .75rem;
	@media (max-width: 991.98px) { display: none;}
}
.breadcrumb-item { font-size: 15px; color: $body-color;
	a {text-decoration: none;}
	+.breadcrumb-item { padding-left: 0; padding-right: .5rem;
		&:before { float: right; padding-right: 0; padding-left: .5rem;}
	}
	&.active { font-weight: 600;}
}
// /*! 
//  * END Bootstrap*/
@import "swiper"; 

// /*!--------------------------------
//  * Start Macabitivi
//  */
 :root { font-size: 17px; position: relative;
	scroll-behavior: smooth;
	 //--bs-green:$hazeGreen;
	color-scheme: dark light;
	



	// /* override, assuming the theme toggler sets a data-theme attribute */
	&[data-theme=light] {
		color-scheme: light;
	}
	&[data-theme=dark] {
		color-scheme: dark;
	}
	/* Light mode */
	@media (prefers-color-scheme: light) {
			--body-bg: #FFFFFF;
			--body-color: #{$body-color};
	}
	/* Dark mode */
	@media (prefers-color-scheme: dark) {
			--body-bg: #{$body-color};
			--body-color: #FFFFFF;
			// body {background-color: var(--body-bg) !important;}
	}
 }
body { background: url(../images/bg.webp) center top #fff; direction: rtl;}
a,button { transition: all .3s ease-in-out;} 
.btn-success {background-color: $hazeGreenA;color: #fff;
	&:hover,&:active,&:focus {color: $hazeGreen; background: var(--bs-white);border-color: $hazeGreen;}
}
.btn-primary { position: relative;
	// &:before, &:after {
	// 	content: "";
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	height: 100%;
	// 	width: 100%;
	// 	transition: all 0.25s ease;
	// 	border-radius: 30px; z-index: -1;
	// }
	// &:after {
	// 	box-shadow: inset 0 0 0 1px $link-color;
	// }
	// &:before {
	// 	//background: $red;
	// 	box-shadow: inset 0 0 0 1px $link-color;
	// }
	&:hover,&:active,&:focus {color: $link-color; background: var(--bs-white);border-color: $link-color;
		// &:before {
		// 	box-shadow: inset 0 0 0 20px #fff;
		// }
	}
}
.btn-light {
	--bs-btn-color:#{$endeavourBlue};
	--bs-btn-hover-color:#{$endeavourBlue};
	--bs-btn-active-color:#{$endeavourBlue};
	--bs-btn-disabled-color:#{$endeavourBlue};
}
// @media (min-width:1670px) {
	.container {max-width: 1630px;}
// }

.subTitle {color: $link-color; margin-top: -.5rem; line-height: 1;
	@media (max-width: 991.98px) {margin: -.5rem auto 0; text-align: center;}
}
h1 {font-size: clamp(1.706rem, 1.2851rem + 1.9877vw, 3.53rem); line-height: 1; font-weight: 700; color: $green; margin-top: 1.8rem;margin-bottom: 1rem;
	+.subTitle { font-size: clamp(1rem, 0.8915rem + 0.4821vw, 1.47rem); max-width: 85%;}
	@media (max-width: 991.98px) { text-align: center;}
}
h2 {font-size: clamp(1.706rem, 1.4481rem + 1.2178vw, 2.8235rem); line-height: 1; font-weight: 700;
	+.subTitle { font-size: clamp(1rem, 0.8915rem + 0.4821vw, 1.47rem); max-width: 85%;}
	@media (max-width: 991.98px) { text-align: center;}
}
h3 {font-size: clamp(1rem, 0.8915rem + 0.4821vw, 1.47rem); }

.form-floating, .floatingPlaceHolder { margin-bottom: 1.3rem;
	.error { position: absolute; color: var(--bs-danger); font-size: 80%; line-height: 1;}
}


.combineComps-1 { margin-bottom: 4rem;
	h2 {text-align: center; width: 100%; margin-bottom:0;}
	@media (min-width: 768px) {display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; margin-bottom: -4.5rem;}
}
.skipLinks {
	>span {display: block;}
	a { font-weight: bold; position: absolute;top: -999em;z-index: 2147483647;padding: 3px 10px;text-align: center;cursor: pointer;border-radius: 4px;
		&:link, &:visited {font: bold 15px Arial !important;position: absolute;top: -999em;z-index: 2147483647;padding: 3px 10px;text-align: center;cursor: pointer;border-radius: 4px;}
		&:focus {color: #fff !important;background: #000;top: 0;}
	}
}
#main { position: relative; 
	// >* {position: relative;}
}
.siteBg { position: absolute; inset:0 0 0 0; overflow: hidden; z-index: -1;
	&:before,
	&:after {
		content: "";
		width: 70vmax;
		height: 70vmax;
		position: absolute;
		background: rgba(239, 233, 229, 0.274);
		left: 0vmin;
		top: 0vmin;
		animation: morph 105s linear infinite alternate, spin 70s linear infinite;
		z-index: -1;
		will-change: border-radius, transform;
		transform-origin: 45% 45%;
		pointer-events: none;
	}
	
	&:after {
		width: 70vmin;
		height: 70vmin;
		left: auto;
		right: -10vmin;
		top: auto;
		bottom: 8vmin;
		animation: morph 105s linear infinite alternate, spin 65s linear infinite reverse;
		transform-origin: 30% 30%
	}
}

// /*!
// *<section class="infoCards">
// */
.infoCards { text-align: center;font-size: clamp(1rem, 0.8915rem + 0.4821vw, 1.47rem); color: $link-color;margin: 0; line-height: 1;
	// .container {--bs-gutter-x:30px;}
	.row {}
	.cardItem {--bs-gutter-x:3rem; margin-bottom: 3rem;}
	@media (min-width: 768px) { text-align: start; 
		.row { justify-content: space-between;}
		.cardItem {--bs-gutter-x:1.5rem; width: 45.2%;}
	}
}
// /***********/
.cardItem {
	&:not([class*="col"]) {flex: 1 0 0%; width: 100%;}
	figure {width: 196px; margin: auto auto .3rem;
		img {width: 100%; display: block; margin: auto;}
	}
	.content {}
	h2 {font-size: clamp(29px,3.4vw,48px); line-height: 1; font-weight: bold;}
	.btn { font-weight: 600; min-width: 180px;
		&:hover {}&:active {}&:focus {}
	}
	.combineComps-1 & { text-align: center;}
	@media (min-width: 768px) { display: flex; margin: 0;justify-content: space-between;
		figure { width: 37.4%; margin: 0; }
		.content { max-width: calc(100% - 40%); order: -1;padding-top: 2.2rem;}
		.combineComps-1 & { margin: auto;
			figure { width: 47.5%;}
			.content { order: 2; text-align: right;padding: 2.2rem 1.2rem 0;}
			h2 { font-size: 23px; text-align: right;}
		}
	}
}
// /*!
// *<section class="articlesShorts">
// */
.bigMore { text-align: center;padding-top: 1.2rem; width: 100%;
	.btn.btn-success { min-width: 180px;
		&:hover {}&:active {}&:focus {}
	}
	@media (min-width: 768px) {padding-top: 2.2rem;
		.btn.btn-success { font-size: 23px; min-width: 255px;}
	}
}
%articlesShortsItem {  background: var(--bs-white); box-shadow: 0 0 70px #eeebeb;border-radius: 18px; padding: clamp(10px,3vw,30px); padding-bottom: 22px; 
	&:not(:last-child) {margin-bottom: 1.4rem;}
	figure { margin: 0 0 1rem;overflow: hidden; border-radius:  20px 20px 67px 194px/20px 20px 30px 178px;//border-radius: 20px 20px 0 0;
		img {display: block;width: 100%; height: 100%; object-fit: cover;}
	}
	.content {display: flex; flex-direction: column; flex-wrap: wrap; padding: 0 5px;font-size: clamp(15px, 3.9vw, 17px); text-align: start;
		// >* { margin-top: 0;}
	}
	h4 {font-size: clamp(17px, 3.9vw, 23px); font-weight: 700; white-space: pre-line; width: 100%;margin-top: -.4rem;}
	.tags { margin-bottom: .7rem; width: 100%;
		a { display: inline-block;border-radius: 12px;padding:4.5px 16.2px;  border: solid 1px #f0f0f0;  background-color: $pampasGrey;margin: 0 0 .5rem 4px; font-size: 13px; font-weight: 700; text-decoration: none; line-height: 1;
			&:hover {}&:active {}&:focus {}
		}
	}
	.text { margin-bottom: .6rem; width: 100%; line-height: 1.42;}
	.ftr {display: flex; justify-content: space-between; margin-bottom: 0; margin-top: auto;}
	.time {//width: calc(100% - 100px);
		&:before {content: ""; display: inline-block; background: $time-left center center/contain no-repeat; width: 15px; height: 15px; margin-left: 5px; vertical-align: middle;}
	}
	.more { font-weight: 700;
		&:hover {}&:active {}&:focus {}
	}
	@media (min-width: 768px) {break-inside: avoid;display: flex; justify-content: space-between;/*width: calc(50% - .7rem);*/margin-bottom: 1.4rem !important;
		figure { width: 35.72%/*255px*/; margin: 0;border-radius: 20px 20px 67px 194px/20px 20px 30px 178px; }//border-radius:0 20px 20px 0;
		.content { width: calc(97% - 35.72%/*255px*/);}
	}
	@media (max-width: 991.98px)  {margin-bottom: 1.4rem;}
}
.articlesShorts {margin-bottom: 4rem;
	// .container {--bs-gutter-x: 22px;}
	h2 { text-align: center; position: relative; }
	h3 { text-align: center; margin-bottom: .9rem; white-space: pre-line; position: relative;}
	.row {}
	.multiItems {}
	.biggerleItem {}
	// .bigMore { text-align: center;padding-top: 1.2rem;
	// 	.btn.btn-success { min-width: 180px;
	// 		&:hover {}&:active {}&:focus {}
	// 	}
	// }
	// .articlesShortsItem {  background: var(--bs-white); box-shadow: 0 0 70px #eeebeb;border-radius: 18px; padding: clamp(10px,3vw,30px); padding-bottom: 22px; 
	// 	&:not(:last-child) {margin-bottom: 1.4rem;}
	// 	figure { margin: 0 0 1rem; border-radius: 20px 20px 0 0;overflow: hidden;
	// 		img {display: block;width: 100%;}
	// 	}
	// 	.content {display: flex; flex-direction: column; flex-wrap: wrap; padding: 0 5px;font-size: clamp(15px, 3.9vw, 17px);
	// 		// >* { margin-top: 0;}
	// 	}
	// 	h4 {font-size: clamp(17px, 3.9vw, 23px); font-weight: 700; white-space: pre-line; width: 100%;margin-top: -.4rem;}
	// 	.tags { margin-bottom: .7rem; width: 100%;
	// 		a { display: inline-block;border-radius: 12px;padding:4.5px 16.2px;  border: solid 1px #f0f0f0;  background-color: $pampasGrey;margin: 0 0 .5rem 4px; font-size: 13px; font-weight: 700; text-decoration: none; line-height: 1;
	// 			&:hover {}&:active {}&:focus {}
	// 		}
	// 	}
	// 	.text { margin-bottom: .6rem; width: 100%; line-height: 1.42;}
	// 	.ftr {display: flex; justify-content: space-between; margin-bottom: 0; margin-top: auto;}
	// 	.time {//width: calc(100% - 100px);
	// 		&:before {content: ""; display: inline-block; background: $time-left center center/contain no-repeat; width: 15px; height: 15px; margin-left: 5px; vertical-align: middle;}
	// 	}
	// 	.more { font-weight: 700;
	// 		&:hover {}&:active {}&:focus {}
	// 	}
	// }
	.articlesShortsItem {@extend %articlesShortsItem;}
	@media (min-width: 768px) {margin-bottom: 5.5rem;
		h3 { margin-bottom: 1.9rem; white-space: normal;}
		.row {column-count: 2; column-gap: 1.4rem; display: block;}//justify-content: space-between; gap: 1.4rem;
		// .multiItems { /*width: calc(50% - .7rem);*/break-inside: avoid; display: flex; flex-direction: column; justify-content: space-evenly; gap: 1.4rem;
		// 	>div {height: 100%; margin: 0 !important;}
		// }
		.bigMore {padding-top: 2.2rem;
			.btn.btn-success { font-size: 23px; min-width: 255px;}
		}
		// .articlesShortsItem {break-inside: avoid;display: flex; justify-content: space-between;/*width: calc(50% - .7rem);*/margin-bottom: 1.4rem !important;
		// 	figure { width: 35.72%/*255px*/; margin: 0; border-radius:0 20px 20px 0;}
		// 	.content { width: calc(97% - 35.72%/*255px*/);}
		// }
		.biggerleItem { /*width: calc(50% - .7rem);*/
			>div {height: 100%;}
		}
	}
	@media (max-width: 1640px)  {
		.row {margin: 0;}
	}
	// @media (max-width: 991.98px)  {
	// 	.articlesShortsItem {margin-bottom: 1.4rem;}
	// }
}
// /*!
// *<section class="needHelp">
// */
.needHelp {margin-bottom: 4rem;
	// .container {--bs-gutter-x: 22px;}
	h2 { text-align: center; margin-bottom: .1rem; }
	h3 { text-align: center; margin-bottom: 1.6rem; white-space: pre-line; position: relative;}
	// .container {}
	// h2 {}
	// h3 {}
	ul {gap:2rem 5.2801%; display: flex; list-style: none; padding: 0; flex-wrap: wrap; justify-content: space-between; text-align: center;
		li {flex: 1 0 195px; min-width: 122px;
			&:nth-child(1) figure:before { background-color: #fab794;transform: rotate(45deg);}
			&:nth-child(2) figure:before { background-color: #fed787;transform: rotate(130deg);}
			&:nth-child(3) figure:before { background-color: #d8d0c9;transform: rotate(0deg);}
			&:nth-child(4) figure:before { background-color: #a4d4a5;transform: rotate(30deg);}
			&:nth-child(5) figure:before { background-color: #9ed2f1;transform: rotate(90deg);}
			&:nth-child(6) figure:before { background-color: #c9c0de;transform: rotate(40deg);}




			@media (max-width: 1199.98px) { flex:0 0 calc(100% / 3 - 4%);}
			@media (max-width: 767.98px) { flex:0 0 calc(100% / 2 - 9%);}
		}
		a { text-decoration: none;font-size: clamp(14px, 3.9vw, 23px); display: block;
			figure { overflow: hidden; position: relative; padding-top: 100%;display: flex;align-items: center; justify-content: center; margin: 0 auto; max-width: 200px; //padding: 16%;
				&:before {content:""; position: absolute;left:0; top: 0; width: 98%; height: 98%; transition: all 4s ease-in-out;
					background-color: $link-color; 
					mask-image: $Object;
					mask-position: center;
					mask-size: contain;
					mask-repeat: no-repeat; z-index: -1;
				}
				img {width: 56.86%;@include centerer; }
			}
			&:hover,&:active,&:focus {
				figure {
					&:before {animation: spin 10s infinite linear !important;}
				}
			}
		}
	}
	@media (min-width: 768px) {margin-bottom: 6.5rem;
		ul {
			a figure { margin-bottom: 1rem;}
		}
	}
}


// /*!
// *<section class="howToTreatment">
// */
.howToTreatment {margin-bottom: 4rem; text-align: center; overflow: hidden;
	// .container {--bs-gutter-x: 22px;}
	h2 { text-align: center; }
	h3 { text-align: center; margin-bottom: 0; white-space: pre-line; position: relative;}
	.row { background: url(../images/group-8M.webp) center center/90% auto no-repeat; }
	.httItem {flex: 1 0 100%; width: 100%; max-width: 100%;padding: 1rem 1rem 2.6rem;}
	.step { margin: auto auto 1rem; width: 38px; height: 38px;font-family: 'GveretLevin';background-color:#c9c0de; color: #00509c; display: flex;align-items: center; justify-content: center;
		mask-image: $Object;
		mask-position: center;
		mask-size: contain;
		mask-repeat: no-repeat; 
		span {display: none;}
	}
	.content {font-size: clamp(15px, 3.1vw, 17px); line-height: 1.3;}
	h4, h3 {font-size: clamp(17px, 3.1vw, 23px);}
	.text { margin-bottom: 1rem;}
	.btn.btn-success { font-weight: 600; min-width: 140px; font-size: 15px;
		&:hover {}&:active {}&:focus {}
	}
	// @media (min-width: 768px) {margin-bottom: 5.5rem; text-align: right; position: relative;
	// 	&:after {animation: rotation 120s infinite linear; content: ""; background: url(../images/dots.webp) center center/contain no-repeat; position: absolute; width: 115px; height: 109px; bottom: 20px;right: 1660px;}
	// 	.row { background: url(../images/group-8D.webp) center top/auto 100% no-repeat; min-height: 350px;}
	// 	.httItem {display: flex;padding: 6% 4.5% 0 0;flex: 1 0 0%; max-width: 50%;}
	// 	.step { width: 66px; height: 66px;margin:0 0 0 6%;
	// 		span{display: inline-block; margin-left: 5px;}
	// 	}
	// 	.content {max-width: 58%;}
	// 	.btn.btn-success { min-width: 180px; font-weight: normal; font-size: 17px;}
	// 	.combineComps-1 & {
	// 		.row { background-position: left calc(50% - -3rem) top 0;}
	// 		.httItem { flex-direction: column;padding: 6% 1.5% 0 0;}
	// 		.step { width: 48px; height: 48px;margin:0 0 .8rem 0; flex-direction: column; line-height: 1; }
	// 		.content {max-width: 76%;}
	// 	}
	// }
	@media (min-width: 768px) {margin-bottom: 5.5rem; text-align: right; position: relative;
		&:after {animation: rotation 120s infinite linear; content: ""; background: url(../images/dots.webp) center center/contain no-repeat; position: absolute; width: 115px; height: 109px; bottom: 20px;right: 1660px;}
		.row { background: url(../images/group-8D.webp) center top/auto 100% no-repeat; min-height: 350px;}
		.httItem {display: flex;padding: 6% 4.5% 0 0;flex: 1 0 0%; max-width: 50%;}
		.step { width: 66px; height: 66px;margin:0 0 0 6%;
			span{display: inline-block; margin-left: 5px;}
		}
		.content {max-width: 58%;}
		.btn.btn-success { min-width: 180px; font-weight: normal; font-size: 17px;}
		.combineComps-1 & {
			.row { background-position: left calc(50% - -3rem) top 0;}
			.httItem { padding: 6% 3% 0;}
			.step { width: 66px; height: 66px;margin:0 0 .8rem 0; margin-inline-end: 7%;  line-height: 1; }
			.content {max-width: 76%;}
			h3 { text-align: start; font-weight: 700;}
		}
	}

}
// /*!
// *<section class="doctorsAndTherapists">
// */
.doctorsAndTherapists {margin-bottom: 4rem; text-align: center;
	.container {--bs-gutter-x: 0;}
	h2 { text-align: center; padding-left: 1.5rem; padding-right: 1.5rem; }
	h3 { text-align: center; margin-bottom: 1rem; white-space: pre-line; position: relative; padding-left: 1.5rem; padding-right: 1.5rem;}
	.swiper-button-prev,
	.swiper-button-next { top: 0; height: 77%; }
	.swiper-button-prev {right: 0;}
	.swiper-button-next { left:0;}
	.swiper-slide { width: 135px; font-size: clamp(15px,3.1vw,17px); line-height: 1; text-align: center;
		a { text-decoration: none;color:$body-color;
			&:hover,&:focus { color:$body-color;
				.name {color: $hazeGreen;}
			}
		}
		figure {width: 130px; height: 130px; margin: auto auto 1.6rem;
			img {display: block; max-width: 100%; margin: auto;}
		}
		figure:not(.simple) { display: block;  position: relative; overflow: hidden;
			div { border-radius:57% 43% 46% 54% / 46% 44% 56% 54%; overflow: hidden;}
			// &:before {content: ""; @include centerer; width: 184px; height: 184px;
			// 	mask-image: $ObjectV;
			// 	mask-position: center;
			// 	mask-size: cover;
			// 	mask-repeat: no-repeat; background: #fff;
			// 	mask-mode: luminance; mask-type: luminance;
				
			// }
			img { display: block; width: 100%; margin: auto; aspect-ratio: 1; object-fit: cover;} 
		}
		$random-number: random(360) + 10;
		&:nth-child(2) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 10}deg) ;}
		&:nth-child(3) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 20}deg) ;}
		&:nth-child(4) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 30}deg);}
		&:nth-child(5) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 40}deg);}
		&:nth-child(6) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 50}deg);}
		&:nth-child(7) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 60}deg);}
		&:nth-child(8) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 70}deg);}
		&:nth-child(9) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 80}deg);}
		&:nth-child(10) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 90}deg);}
		&:nth-child(11) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 100}deg);}
		&:nth-child(12) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 110}deg);}
		&:nth-child(13) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 120}deg);}
		&:nth-child(14) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 130}deg);}
		&:nth-child(15) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 140}deg);}
		&:nth-child(16) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 150}deg);}
		&:nth-child(17) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 160}deg);}
		&:nth-child(18) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 170}deg);}
		&:nth-child(19) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 180}deg);}
		&:nth-child(20) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 190}deg);}
		&:nth-child(21) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 200}deg);}
		&:nth-child(22) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 210}deg);}
		&:nth-child(23) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 220}deg);}
		&:nth-child(24) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 230}deg);}
		&:nth-child(25) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 240}deg);}
		&:nth-child(26) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 250}deg);}
		&:nth-child(27) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 260}deg);}
		&:nth-child(28) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 270}deg);}
		&:nth-child(29) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 280}deg);}
		&:nth-child(30) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 290}deg);}
		&:nth-child(31) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 300}deg);}
		&:nth-child(32) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 310}deg);}
		&:nth-child(33) figure:before{transform:translate(-50%, -50%) rotate(#{random(360) + 320}deg);}

		.name {font-size: clamp(17px,3.1vw,23px); font-weight: 800; color: $link-color; margin-bottom: .3rem;}
		.position { margin-bottom: 1.2rem;}
		.meta { display: flex; justify-content: center; flex-direction: column;
			.flag {
				&:before {content: ""; display: inline-block; background: $flagG center center/contain no-repeat; vertical-align: middle; width: 15px; height: 15px; margin-left: 4px;}
			}
			.location {
				&:before {content: ""; display: inline-block; background: $pinG center center/contain no-repeat; vertical-align: middle; width: 15px; height: 15px; margin-left: 4px;}
			}
		}
	}
	@media (max-width:1023.98px) {
		.swiper-button-next, .swiper-rtl .swiper-button-prev {display: none;}
	}
	@media (min-width: 768px) {margin-bottom: 5.5rem;
		// .container {--bs-gutter-x: 22px;}
		h3 { margin-bottom: 2.53rem;}
		.swiper-slide { width: 250px;
			figure {width: 170px; height: 170px;}
			figure:not(.simple) {  
				div { }
				&:before { width: 234px; height: 234px;}
				img {}
			}
			.meta { flex-direction: row; gap:1rem;}
		}
	}
}

// /*!
// *<section class="theRightTreatment">
// */
.theRightTreatment { text-align: center;padding: 3rem 24px; position: relative;
	&:after {animation: rotation 120s infinite linear; content: ""; background: url(../images/dots2.webp) center center/contain no-repeat; position: absolute; width: 62px; height: 51px; bottom: 37%;right: -3vw; display: block;}
	.container { background: #fff; box-shadow: 0 0 70px #eeebeb;border-radius: 18px;max-width: 1600px;padding-top: 3rem;padding-bottom: 3rem;}
	h2 {font-size: clamp(29px, 3.3vw, 48px);line-height: 1; font-weight: 700;}
	h3 {font-size: clamp(17px, 3.3vw, 25px); margin-bottom: 1.6rem;}
	.btn.btn-success {font-size: clamp(17px, 3.3vw, 23px); min-width: 235px;
		&:hover {}&:active {}&:focus {}
	}
	@media (min-width: 768px) {padding: 3rem 24px 7rem;
		&:after { width: 115px; height: 109px; bottom: 15%;right: 4vw;}
		.container { padding-top: 4rem;}
	}
}
.theRightTreatmentModal { 
	.modal-dialog { margin: 0 0 0 auto;}
	.modal-content { height: 100vh; overflow: auto; border-radius: 0; border:0; background: url(../images/theRightTreatmentBg.webp) center center/100% auto no-repeat #f8f8f8;}
	
	.modal-header { background: #eee; border:0;
		.btn-close {margin:0;  left: 16px; top: 16px; color: $link-color;  opacity: 1; position: absolute; z-index: 1; background: 0; width: 17px; height: 17px;
			&:before { content: ""; display: block;background: $link-hover-color; mask-image: $btn-close-offcanvas-bg; mask-position: center; mask-size: 17px auto; width: 17px; height: 17px; mask-repeat: no-repeat;}
			&:hover,&:active,&:focus {&:before {background: $link-color;}}
		}
		.modal-title {font-size: clamp(1.705rem, 1.4436rem + 1.2342vw, 2.823rem); color: $link-hover-color; font-weight: bold;line-height: .9; text-align: center;padding: 1.1em 1em .8em;}
		.back {font-weight: bold; text-decoration: none;color: $hazeGreen; font-size: 17px;
			&:before {content: "‹"; font-size: 140%; font-weight: 600; display: inline-block; margin: 0 3px;}
		}
	}
	.step { padding: 1rem 0;
		h5 { font-size:clamp(19px, 1.4436rem + 1.2342vw, 25px); font-weight: bold; text-align: center;margin-bottom: 1rem;
			+p { margin-top: -1rem; font-size:clamp(19px, 1.4436rem + 1.2342vw, 23px); color: $endeavourBlue; text-align: center; line-height: 1.2;}
		}
		.btn-link { margin: auto; font-weight: bold; text-decoration: none;color: $hazeGreen; font-size:clamp(19px, 1.4436rem + 1.2342vw, 23px); display: block; max-width: 120px;
			&:after {content: "›"; font-size: 140%; font-weight: 600; display: inline-block; margin: 0 3px;}
		}
	}
	.redo { color: $elephantBlue;}
	.note {border-radius: 10px; background-color: #eee; text-align: center; padding: 1.2rem 1.5rem; margin-bottom: 1.4rem;
		strong {}
		.btn.btn-success {
			&:hover {}&:active {}&:focus {}
		}
	}
	.btnCheck {
		.btn {border-radius: clamp(22px, 1.4436rem + 1.2342vw,36px);border-color: $peranoBlue; font-size: clamp(19px, 1.4436rem + 1.2342vw, 23px); width: 100%; max-width: 390px; margin:0 auto .65rem;
			 display: flex; justify-content: center; align-items: center; line-height: 1;
			 height: 3em;
			 padding: 0 .75rem;
			// display: block;line-height:clamp(1.5em,3.4vw,2.3em) ; 
			&:hover,&:focus {border-color: $mossGreen;}
			&:active, &.active {border-color: $peranoBlue;background-color: $peranoBlue;}
		}
		.btn-check:checked+.btn, .btn-check:active+.btn {border-color: $peranoBlue;background-color: $peranoBlue;}
	}
	.stepsBar { position: absolute; bottom: 0; width: 100%; left: 0;
		&:before { content: ""; height: 5px; display: block; width: var(--barWidth , 25%); background: $endeavourBlue;}
	}
/*
*<ul class="treatmentsList">
*						<li tabindex="0">
*							<figure><img src="../images/1-1.webp" alt=""></figure>
*							<figcaption>דיקור סיני</figcaption>
*							<div class="content">
*								<figure><img src="../images/treatments/acupu.webp" alt=""></figure>
*								<div class="title">דיקור סיני</div>
*								<div class="text">דיקור הוא טיפול ייחודי המסייע בשמירת האיזון האנרגטי של הגוף. השיטה מכוונת למנוע מחלות ולטפל במגוון של בעיות</div>
*								<a href="" class="more">קרא עוד</a>
*							</div>
*						</li>
*/
	.treatmentsList { list-style: none;  padding:1.6rem 0 1rem; margin: 0; display: flex; flex-wrap: wrap; gap:1rem;
		li { width: 100%; flex: 0 0 calc(50% - .75rem); max-width: calc(50% - .75rem); position: relative;
			&:focus,&:hover,&:focus-within {
				.content { top: 0; visibility: visible; opacity: 1; z-index: 1;}
			}
		}
		figure {
			img { width: 100%; display: block;}
		}
		figcaption { color: $endeavourBlue; font-weight: bold; text-align: center;font-size: clamp(17px, 3.1vw, 19px);}
		.content { text-align: center; transition: all .6s ease-out; position: absolute; top: -20%; visibility: hidden; opacity: 0; width: 90%; left:5%; min-height: 100%; font-size: clamp(15px, 3.1vw, 17px);box-shadow: 0 0 40px #eeebeb;border-radius: 10px; background: #fff; padding: 1rem; line-height: 1.2;
			figure {width: 52px; height: 52px; margin: auto; display: flex; justify-content: center; align-items: center; margin-bottom: .6rem;
				background-color: #9ed1f1;
					mask-image: $Object;
					mask-position: center;
					mask-size: contain;
					mask-repeat: no-repeat;
				img { width: 40px; display: block; margin: auto;}
			}
			.title {font-size: clamp(17px, 3.1vw, 23px); font-weight: 700; color: $link-color; margin-bottom: .5rem;}
			.text { margin-bottom: .8rem;}
			.more { font-weight: 700; text-decoration: underline;
				&:hover {}&:active {}&:focus {}
			}
		}
		// .content { display: none;
		// 	figure {}
		// 	.title {}
		// 	.text {}
		// 	.more {
		// 		&:hover {}&:active {}&:focus {}
		// 	}
		// }

		@media (max-width: 767.98px) {
			li { width: auto; max-width: 50%;}
			figure {img {width: 100%;}}
			.content { width: 100%;}
		}
	}
	@media (min-width:520px) {
		.modal-header {
			.btn-close {  left: 1.85rem; top: 1.25rem;mask-size: 23px auto;}
		}
		.step {
			h5 { }
		}
		.btnCheck {
			.btn { font-weight: 300;}
		}
		.stepsBar {
			&:before { height: 7px;}
		}
	}
} 
// /*!
// *<section class="ourTreatments">
// */
.ourTreatments {margin-bottom: 3rem; text-align: center; //overflow: hidden;
	.container {max-width: 1700px;}//--bs-gutter-x: 22px;
	h2 { text-align: center; }
	h3 { text-align: center; margin-bottom: 0; white-space: pre-line; position: relative;}
	ul { list-style: none; padding:1.6rem 0 1rem; margin: 0; display: flex; flex-wrap: wrap; justify-content: center;
		li { width: 275px; position: relative; //overflow: hidden;
			&:focus,&:hover,&:focus-within {
				.content { top: 0; opacity: 1; z-index: 1;}
			}
		}
		figure { margin-bottom: .1rem;
			img {}
		}
		figcaption {font-size: clamp(17px, 3.1vw, 23px);overscroll-behavior: contain;}
		.content { display: flex; flex-direction: column; justify-content: flex-start; transition: all .6s ease-out; position: absolute; top: -20%; opacity: 0; width: 90%; left:5%; min-height: 100%; font-size: clamp(15px, 3.1vw, 17px);box-shadow: 0 0 40px #eeebeb;border-radius: 10px; background: #fff; padding: 1rem; line-height: 1.2;
			figure {width: 52px; height: 52px; margin:0 auto auto; display: flex; justify-content: center; align-items: center; margin-bottom: .6rem;
				background-color: #9ed1f1;
					mask-image: $Object;
					mask-position: center;
					mask-size: contain;
					mask-repeat: no-repeat;
				img { width: 40px; display: block; margin: auto;}
			}
			.title {font-size: clamp(17px, 3.1vw, 23px); font-weight: 700; color: $link-color; margin-bottom: .5rem;}
			.text { margin-bottom: .8rem;}
			.btn { width: fit-content; margin: auto;}
			.more { font-weight: 700; text-decoration: underline;
				&:hover {}&:active {}&:focus {}
			}
		}
	}
	.bigMore { text-align: center;padding-top: 1.2rem;
		.btn.btn-success { min-width: 180px;
			&:hover {}&:active {}&:focus {}
		}
	}
	@media (min-width: 768px) {
		.bigMore {padding-top: 2.2rem;
			.btn.btn-success { font-size: 23px; min-width: 255px;}
		}
	}
	@media (max-width: 767.98px) {
		ul {
			li { width: auto; max-width: 50%;}
			figure {img {width: 100%;}}
			.content { width: 100%;}
		}
	}
}
// /*!
// *<section class="whyUs">
// */
.whyUs {margin-bottom: 3rem; text-align: center; //overflow: hidden;
	// .container {--bs-gutter-x: 22px;}
	h2 { text-align: center; }
	h3 { text-align: center; margin-bottom: 0; white-space: pre-line; position: relative;}
	ul {list-style: none; padding:1.6rem 0 .6rem; margin: 0; display: flex; flex-wrap: wrap; justify-content: center; gap:2rem;
		li {flex-shrink: 0; width: 100%; max-width: 375px;}
		.content { height: 100%; font-size: clamp(15px, 3.1vw, 17px);box-shadow: 0 0 40px #eeebeb;border-radius: 10px; background: #fff; padding:2.4rem 1rem; line-height: 1.2;
			figure { margin-bottom: 1.4rem;}
			h4 {font-size: clamp(17px, 3.1vw, 23px); font-weight: 700; color: $link-color; margin-bottom: .5rem;}
			.text {}
		}
	}
	.bigMore { text-align: center;padding-top: 1.2rem;
		.btn.btn-success { min-width: 180px;
			&:hover {}&:active {}&:focus {}
		}
	}
	@media (min-width: 768px) { margin-bottom: 7rem;
		.bigMore {padding-top: 2.2rem;
			.btn.btn-success { font-size: 23px; min-width: 255px;}
		}
	}
	@media (max-width: 767.98px) {
		ul { gap:1rem;
			li {max-width: 47%;}
		}
	}
}
// /*!
// *<section class="hpCarouselContainer">
// */
.hpCarouselContainer {
	.carousel { padding-bottom: 70px;}
	.carousel-indicators {
		button { background-color: $link-color; opacity: 1;width: 8px; height: 8px; border-radius: 100%; border:0;
			&:hover,&:active,&:focus { background-color: $link-hover-color;}
			&.active { background-color: $link-hover-color;}
			&.toggleHpCarousel { order:100; margin-right: 6px; border-radius: 0;
				background-color: $link-color;
					mask-image: $play;
					mask-position: center;
					mask-size: contain;
					mask-repeat: no-repeat;
				&:hover,&:active,&:focus {background-color: $link-hover-color;}
				&.pause {mask-image: $pause;}
				// @media (max-width: 767.98px) { display: none;}
			}
		}
	}
	.carousel-inner {}
	.carousel-item {}
	.inner { display: flex; flex-direction: column; text-align: center; 
		figure { //margin: 0;
			img { display: block; width: 100%;
				&:nth-child(1) {display: none;}
			}
		}
		.content {line-height: 1.2; padding: 0 25px;color: $link-color;/*white-space: pre-line;*/ }
		.title {font-size: clamp(23px, 3.1vw, 60px); font-weight: 700;line-height: 1.1; color:$link-color;
			span {font-size: clamp(29px, 3.1vw, 60px); font-weight: 300; display: block;}
		}
		.text {font-size: clamp(17px, 3.1vw, 26px); margin-bottom: .8rem;
			p:last-child { margin-bottom: 0;}
		}
		.btn.btn-success {font-size: clamp(17px, 3.1vw, 23px); min-width: 180px;
			&:hover {}&:active {}&:focus {}
		}
	}
	@media (max-width: 767.98px) {
		.carousel { //direction: ltr;
			// .inner { direction: rtl;}
		}
	}
	@media (min-width: 768px) {
		.carousel { min-height: auto; padding-bottom: 0; margin-bottom: .5rem;}
		.carousel-indicators { bottom: auto;top: 10rem;left: auto;right: 40%;margin: 0;
			button {width: 12px; height: 12px;
				&:hover {}&:active {}&:focus {}
				&.active {}
			}
		}
		.toggleHpCarousel { 
			&:hover {}&:active {}&:focus {}
			&.pause {}
		}
		.carousel-inner {}
		.carousel-item {}
		.inner { flex-direction: row; text-align: right; justify-content: space-between;
			figure {order: 2;min-width:64%;
				img { 
					&:nth-child(1) {display: block;}
					&:nth-child(2) {display: none;}
				}
			}
			.content {padding: 5.9% 8% 0 2%; }
			.title { line-height: .9;margin-top: 1.8rem; margin-bottom: 1rem;
				span { }
			}
			.text {margin-bottom: 1.5rem;}
			.btn.btn-success { font-weight: 600;min-width: 250px; padding: 0.8rem 0.75rem; border-radius: 36px;
				&:hover {}&:active {}&:focus {}
			}
		}
	}
}

// /*!
// *<div class="treatmentsWrapper">
// */
.treatmentsWrapper { display: flex; flex-wrap: wrap; gap:20px; padding: 1.8rem 10px; margin-bottom: 2rem;
	h2 {flex:0 0 100%; text-align: center;}
	.butLine { text-align: center; width: 100px; flex: 0 0 100%;
		.btn {margin-bottom: .8rem; min-width: 200px;}
		a:not(.btn) {font-weight: bold; text-decoration: underline;font-size: clamp(15px, 3.1vw, 17px);}
	}
	@media (min-width:992px) { gap: 33px; padding: 1.8rem 0px; justify-content:center;}
}

.treatment_big { padding: 18px;font-size: clamp(15px, 3.1vw, 17px);  background: #fff; box-shadow: 0 0 40px #f1f1f1; border-radius: 18px; flex:0 0 100%; width: 100%; display: flex; flex-wrap: wrap;
	figure { height: 125px; flex:0 0 0%; margin: 0 auto 0 0;
		img { display: block; height: 100%; margin: 0 auto 0 0;}
	}
	h2 {font-size: clamp(30px, 3.1vw, 48px);
		a {  font-size: inherit; text-decoration: none;}
	}
	h3 {font-size: clamp(17px, 3.1vw, 23px); font-weight: bold;}
	h4 {font-size: clamp(15px, 3.1vw, 17px); font-weight: bold; margin-bottom: 0;}
	.time {
		&:before {content: ""; display: inline-block; background: $time-left center center/contain no-repeat; width: 12px; height: 12px; margin-left: 5px; vertical-align: middle;}
	}
	.text {
		p {
			span {}
		}
		.more { font-weight: bold; color: $hazeGreen;
			&:after {content: "›"; font-size: 140%; font-weight: 400; display: inline-block; margin: 0 3px;}
		}
	}
	.info {
		ul { list-style: none; padding: 0;
			li { display: inline-block; padding: 0 0 0 15px; white-space: nowrap;
				img { width: 15px; height: auto;}
			}
		}
		.more { font-weight: bold; text-decoration: none;
			&:after {content: "›"; font-size: 140%; font-weight: 600; display: inline-block; margin: 0 3px;}
			&:hover {}&:active {}&:focus {}
		}
		.btn.btn-outline-success {width: 100%; max-width: 17.6471rem; font-size: $fz-17-23;}
	}
	@media (max-width:991.98px) {position: relative;
		.text { border-bottom: 5px solid #f8f8f8; margin-bottom: 1.2rem;
			h2 { position: absolute; top: 3rem;width: 100%; max-width: calc(100% - 175px); text-align: start;}
		}
	}
	@media (min-width:992px) { padding: 1.8rem 15px;
		.time { &:before { width: 15px; height: 15px;}}
		figure { height: auto;max-width:17.466%; flex:0 0 17.466%;margin:0 0 0 2.4%;
			img {height: auto; max-width: 100%; margin:auto;}
		}
		.text { max-width: 52%; flex: 0 0 52%; border-left: 5px solid #f8f8f8; padding: 9px 0 0 6%; margin: 0 0 0 2.4%; line-height: 1.4;
			h2 { margin-bottom: 0.9rem;}
			h3 { margin-bottom: 0.1rem;}
			p {
				span {}
			}
		}
		.info {max-width:20%; flex:0 0 100%; padding-top: 1rem;
			ul { display: flex; flex-wrap: wrap;
				li {flex:0 0 50%;
					img { width: 17px; height: auto;}
				}
			}
			.more {
				&:hover {}&:active {}&:focus {}
			}
		}
	}
}

.treatment_small {padding: 10px;font-size: clamp(15px, 3.1vw, 17px);  background: #fff; box-shadow: 0 0 40px #f1f1f1; border-radius: 18px;
	figure {
		img {max-width: 100%; display: block;}
	}
	.text { padding: 8px;
		h2 {font-size: clamp(17px, 3.1vw, 23px); font-weight: bold;}
		.more { text-decoration: underline; font-weight: bold;
			&:hover {}&:active {}&:focus {}
		}
	}
	@media (min-width:992px) { flex: 0 0 376px;}
}

.waypoint-sticky { position: fixed; left:0; width: 100vw; z-index: 0; top:0; visibility: hidden; transition: top .3s ease-in-out;
	&.blueStripe { background: $link-color; color: #fff; text-align: center; font-weight: bold; padding: 15px; display: flex; align-items: center; justify-content: center; line-height: 1;
		a {margin: 0 10px; white-space: nowrap;}
	}
	&.show { visibility: visible; z-index: 1020; top: 64px;}
	
	@media (min-width:1200px) {&.show { top:95px;}}
}

// /*!
// *<div class="topicks">
// */
.topics {position: relative; padding: 2rem 0 .5rem;
	ul {overflow: auto;  list-style: none; padding: 0 0 1rem; display: flex; gap:.5rem;
		.article-link { white-space: nowrap; padding: 3.5px 5px; min-width: 137px; text-align: center; display: block; border-radius: 32px; text-decoration: none;box-shadow: 0 0 20px #f1f1f1; background: #fff;font-size: clamp(15px, 3.1vw, 17px);
			&:hover,&:active,&:focus {}
		}
		li {
			&.active {
				.article-link {color: #fff; background: $link-color;}
			}
		}
	}
	@media (min-width:992px) {
		ul { flex-wrap: wrap; overflow: visible; .article-link {min-width: 157px; padding: 6.26px 5px;}}
	}
}
// /*!
// *<div class="offcanvasMatzavim">
// */


.offcanvasMatzavim {width: 505px;
	.offcanvas-header {		
		.btn-close {margin-right:auto;margin-left: -0.5rem; color: $link-color;  opacity: 1; background: 0; width: 17px; height: 17px; 
			&:before { content: ""; display: block;background: $link-color; mask-image: $btn-close-offcanvas-bg; mask-position: center; mask-size: 17px 17px;width: 17px; height: 17px;  mask-repeat: no-repeat;}
			&:hover,&:active,&:focus {&:before {background: $link-hover-color;}}
		}
	}
	.offcanvas-body {
		h3 {font-size: clamp(19px, 3.1vw, 25px); text-align: center; font-weight: bold; margin-bottom: 1.5rem;	}
		.matzavim-list { background: #f8f8f8; border-radius: 18px; text-align: center; padding: 2rem 1.5rem .5rem; max-width: 390px; margin: auto;
			p {color: $link-color;font-size: clamp(19px, 3.1vw, 25px);}
			ul { list-style: none; padding: 0; display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;
				li { width: 50%; margin-bottom: 1.5rem;}
				a {font-size: clamp(15px, 3.1vw, 19px); text-decoration: none;
					img {width: 94px; display: block; margin: auto;}
					&:hover {}&:active {}&:focus {}
				}
			}
		}
	}
}

.scroll-progress-container {width: 100%;height: 5px;position: fixed;top: 65px;
	@media (min-width:1200px) {top: 95px;}
}
.scroll-progress {width: 0px;height: 4px;background-color: $link-color;}

// /*! articlewrapper*/
.articlewrapper {margin: 0 auto 5rem; padding: clamp(15px,3vw,50px); display: flex; flex-wrap: wrap; justify-content: space-between; background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px;
	.articleHead {width: 100%; border-bottom: 5px solid #f8f8f8; padding-bottom: .8rem;}
	.article { width: 100%; max-width: 60.552%/*911px*/; }
	aside { width: 100%; max-width: 31.8%/*476px*/; }
	@media (max-width: 1659.98px) { }
	@media (max-width: 991.98px) {margin: 0 15px 5rem;
		.article { max-width: 100%; margin-bottom: 1.5rem;}
		aside { max-width: 100%;}
		img {max-width: 100%;}
	}
}
.article{font-size: clamp(15px, 3.1vw, 17px); line-height: 1.4;
	h2 {font-size: clamp(17px, 3.1vw, 23px); line-height: 1.3;}
	h3 {font-size: clamp(17px, 3.1vw, 23px); line-height: 1.3; font-weight: 700;}
	.bottomMeta {font-size: clamp(13.5px, 3.1vw, 15px);border-top: 5px solid #f8f8f8; padding-top: 2.5rem;}
}
.pageTools { position: absolute; left: 0; top: 1.3rem;
	.print {width:34px; height: 34px;  display: inline-block; vertical-align: middle;
			// background-color: $link-color;
			// 	mask-image: $printer;
			// 	mask-position: center;
			// 	mask-size: 18px;
			// 	mask-repeat: no-repeat;
			// background:$share center/contain no-repeat;
			&:before { content:""; display: block; width: 100%; height: 100%;background-color: $link-color;
				mask-image: $printer;
				mask-position: center;
				mask-size: 18px;
				mask-repeat: no-repeat; }
			&:hover ,&:active ,&:focus {&:before {background-color: $link-hover-color;}}
			&.show {&:before {background-color: $link-hover-color;}}
	}
}
.jobItem, .pageTools {
	.btn-group.dropstart {
		.share {width:34px; height: 34px;  border:0; background: 0; padding: 0;
			
			// background:$share center/contain no-repeat;
			&:before { content:""; display: block; width: 100%; height: 100%;background-color: $link-color;
				mask-image: $share;
				mask-position: center;
				mask-size: 18px;
				mask-repeat: no-repeat;}
			&:hover ,&:active ,&:focus {&:before {background-color: $link-hover-color;}}
			&.show {&:before {background-color: $link-hover-color;}}
		}
		.dropdown-menu {  flex-direction: column; gap:.25rem; padding: .6rem; background: $green; min-width: 80px;transform: translate(41px, -23px) !important; border:0;
			&:before {
				position: absolute;
				display: block;
				content: "";
				border-color: transparent;
				border-style: solid;
				left: -8px;top: 30px;
				border-width: 0.55rem 0.55rem 0.55rem 0;
				border-right-color: $link-hover-color;
			}
			&.show {display: flex;}
			li { 
				a {border:.5px solid $link-hover-color; border-radius: 4px; display: block;width:59px; height: 59px; background: center/28px no-repeat;
				&:hover,&:active,&:focus { border-color: rgba(255, 255, 255, 0.7);}}
			}
			.asGp { background-image:$google-plus ;
				&:hover {}&:active {}&:focus {}
			}
			.asTw { background-image:$twitter ;
				&:hover {}&:active {}&:focus {}
			}
			.asFb { background-image:$facebookW ;
				&:hover {}&:active {}&:focus {}
			}
			.asWa { background-image:$whatsapp ;
				&:hover {}&:active {}&:focus {}
			}
			.asEm { background-image:$email ;
				&:hover {}&:active {}&:focus {}
			}
		}
	}

}
.articleHead { position: relative; padding-left: 70px; margin-bottom: 2.5rem;
	.time { display: inline-block; margin-left: 2rem;
		&:before {content: ""; display: inline-block; background: $time-left center center/contain no-repeat; width: 15px; height: 15px; margin-left: 5px; vertical-align: middle;}
	}
	.timeSeek {display: inline-block; margin: 0 .8rem;}
	.pageTools {left: 0; top: 0;}

}

.blueBox {padding: 3rem 15px; margin-bottom: 1.5rem; color: #fff; background: url(../images/blueBg.webp) center center; border-radius: 18px; text-align: center;font-size: clamp(17px, 3.1vw, 23px);
	img { max-width: 46px;}
	p { font-weight: 300;}
	strong { font-weight: 700;}	
	.btn.btn-success {font-size: clamp(15px, 3.1vw, 17px);
		&:hover {}&:active {}&:focus {}
	}
	a {color: #fff;}
	@media (min-width: 992px) {
		img { max-width: 112px;}
		.btn.btn-success { padding: .662rem .75rem; min-width: 255px; font-weight: 600;}
	}
	.sendCV & {padding: 1.5rem 15px;
		h3 {font-size: clamp(1.47rem, 1.3693rem + 0.4756vw, 1.705rem); color: #fff; font-weight: 700; margin: 0;}
		p {}
		.text {}
		.btn { font-weight: bold;}
		@media (max-width: 991.98px) { margin: 0 15px 5rem; }
		@media (min-width: 992px) { text-align: start; display: flex; justify-content: space-between; align-items: center;padding-inline: 13%;
			p {margin-bottom: 0;}
		}
	}
}


%time {
	&:before {content: ""; display: inline-block; background: $time-left center center/contain no-repeat; width: 12px; height: 12px; margin-left: 5px; vertical-align: middle;}
}
.treatmentItem {
	.article {
		h2 {font-size: clamp(28px, 3.1vw, 35px);}
	}
	.info { border-top:3px solid #f8f8f8;border-bottom:3px solid #f8f8f8; padding: 1rem 0 0; margin: 1rem 0;
		h4 {font-size: clamp(15px, 3.1vw, 17px); font-weight: bold;}
		ul { list-style: none; padding: 0;
			li { display: inline-block; padding: 0 0 0 15px; white-space: nowrap;
				&:empty {display: none;}
				img { width: 15px; height: auto;}
			}
		}
		.time { @extend %time;
			//&:before {content: ""; display: inline-block; background: $time-left center center/contain no-repeat; width: 12px; height: 12px; margin-left: 5px; vertical-align: middle;}
		}
		.more { font-weight: bold; text-decoration: none;
			&:after {content: "›"; font-size: 140%; font-weight: 600; display: inline-block; margin: 0 3px;}
			&:hover {}&:active {}&:focus {}
		}
	}
	@media (min-width:768px) { //padding: 1.8rem 15px;
		.info { flex:0 0 100%; display: flex;
			.col-auto {
				&:first-child {padding-left: 2rem;}
			}
			ul { display: flex; flex-wrap: wrap;
				li {flex:0 0 0%;
					img { width: 17px; height: auto;}
				}
			}
			.more {
				&:hover {}&:active {}&:focus {}
			}
		}
	}
}
// /*!
// *<div class="costBox">
// */
.costBox {border-radius: 20px; padding: 2rem 2.1rem;
  background-color: #f8f8f8;
	h4 { font-size: 17px;}
	ul { list-style: none; padding: 0; margin: 0 0 1rem; display: flex; justify-content: space-between; gap:3%;
		li { line-height: 1.1; color: $endeavourBlue; background: #fff; border-radius: 100%;flex: 1 0 0%; text-align: center;font-size: clamp(13px, 3.1vw, 15px); overflow: hidden;
			&:before {content: ""; width: 0; padding-top: 100%; float: left; }
			>div{display: flex; flex-direction: column; justify-content: center; align-items: center;height: 100%;}
		}
		strong { display: block;font-size: clamp(15px, 3.1vw, 23px);}
	}
	p {font-size: clamp(13px, 3.1vw, 15px);}
	a { color: $hazeGreen; text-decoration: none;
		&:hover,&:focus { text-decoration: underline;} &:active {}
	}
	@media (max-width: 767.98px) { padding: 2rem 1rem;}
}

.goodtoKnow { padding-top: .8rem;padding-bottom: 4rem;
	h2 { text-align: center; margin-bottom: .8em;}
	.row {margin: auto; border-radius: 20px; background-color: #fff;box-shadow: 0 0 70px #eeebeb; padding: 2rem 0;}
	.col-12 { display: flex; flex-direction: column;}
	h3 {font-size: clamp(15px, 3.1vw, 17px); margin: auto auto 1rem; width:  83px; height: 83px;font-family: 'GveretLevin';background-color:#c9c0de; ;color: $link-color; display: flex;align-items: center; justify-content: center;
		mask-image: $Object;
		mask-position: center;
		mask-size: contain;
		mask-repeat: no-repeat; 
		word-spacing: 83px; text-align: center; line-height: 1.4;
		// span {display: none;}
	}
	ul {//list-style-type: '✔';
		li {font-size: clamp(15px, 3.1vw, 17px); margin-bottom: .4rem;
			list-style-image: $marker;
			&::marker {font-size: 1.5rem; line-height: .1;}
			//&::marker {color: $blue; font-family:monospace;}
			//&:before { content: "✔️";'✓';✔ }
		}
	}
	@media (max-width: 991.98px)  {.row {margin: 0 15px;}}
	@media (min-width:768px) {
		.row {padding: 3.5rem 1rem;}
		.col-12 { flex-direction: row; justify-content: space-between;}
		h3 { width: 94px; height: 94px;margin:0;
			// span{display: inline-block; margin-left: 5px;}
		}
		ul { width: 100%; flex:0 0 calc(100% - 102px); max-width: calc(100% - 102px);}
		.text { width: 100%; flex:0 0 calc(100% - 130px); max-width: calc(100% - 130px);
			ul {max-width: 100%;margin-inline-start: -1.3rem;}
		}
	}
}

// /*!
// *<section class="caseStudies container">
// */
.caseStudies {padding-bottom: 6rem;
	h2 { text-align: center;}
	.row {}
	.col-12 { margin-bottom: 1.5rem;}
	.item {border-radius: 20px; background-color: #f8f8f8;font-size: clamp(13px, 3.1vw, 17px); padding: 30px; display: flex; height: 100%;
		
	}
	h3 {font-size: clamp(16px, 3.1vw, 23px); font-weight: bold;}
	figure {width: 100%; flex:0 0 auto; max-width: 17%;margin-left: 5.1%;
		img { display: block; width: 100%;}
	}
	.text {text-align: start; line-height: 1.2;
		a {font-weight: 600;}
	}
	.bigMore { padding: 0;}
	.btn.btn-success {
		&:hover {}&:active {}&:focus {}
		&:not(.collapsed) { display: none;}
	}
	@media (max-width: 767.98px)  {
		.row {--bs-gutter-x: -22px; }
		.item { padding: 1.1rem .4rem;}
	}
}


// /*!
// *<section class="faq container">
// */
.faq {padding-bottom: 6rem;
	h2 { text-align: center; margin-bottom: 2rem;}
	.faqAccordion {border-radius: 30px; background-color: #fff;box-shadow: 0 0 70px #eeebeb; overflow: hidden; padding: clamp(16px, 3.1vw, 30px);
		.accordion-item { border-width:0;
			&:not(:first-child) { border-top:1px solid rgba(0,0,0,.125);}
			.accordion-header {}
			.accordion-collapse {
				&.collapse {}
				&.show {}
			}
			.accordion-button {font-size: clamp(17px, 3.1vw, 23px); color: $blue; padding-left: 0; padding-right: 0;text-align: right;
				&:after {order: -1; margin-left: 1.1rem; background-image: $plus; background-size: contain;margin-right: .15rem;width: .75rem; height: .75rem;}
				&:not(.collapsed) {font-weight: bold; box-shadow: none; background: 0;
					&:after { background-image: $minus;}
				}
				&:hover {}&:active {}&:focus {}
			}
			.accordion-body {padding: 0 2rem 2rem;}
		}
	}
}

/*
*<section class="conditionTreatments container">
*/
.conditionTreatments {
	h2 { text-align: center; margin-bottom: 1.5rem;}
	.inner {    margin: 0 auto 5rem;
    padding: clamp(15px,3vw,60px) clamp(15px,3vw,30px);
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    background: var(--bs-white);
    box-shadow: 0 0 70px #eeebeb;
    border-radius: 18px;}
	figure { overflow: hidden;border-radius: 20px; //padding-top:clamp(105px, 11vw, 210px) ;
		img { object-fit: cover; display: block; min-height: 105px; width: auto; max-width: 100%;}
	}
	ul { column-count: 2;padding: 1.6rem 1.4rem 0 0; gap: 1.4rem;
		li {list-style-image: $blue18;padding: 0rem 0 0 5rem; margin-bottom: 2rem; line-height: 1.35;
			//page-break-inside: avoid;           /* Theoretically FF 20+ */    
			break-inside: avoid-column;         /* Chrome, Safari, IE 11 */    
			&::marker {font-size: 2.4rem;}
		}
		h3 {font-size: clamp(17px, 3.1vw, 23px); font-weight: bold;}
	}
} 

.contactWrapper { background: var(--bs-white); border-radius: 18px; position: relative; margin-top:1.5rem;
	&:before { content: ""; position: absolute; left: 0; top: 0; height: 50%; box-shadow: 0 0 70px #eeebeb; border-radius:18px 18px 0 0; width: 100%; z-index: -1;}
	.note {font-size: clamp(0.9375rem, 0.8798rem + 0.2564vw, 1.1875rem); border-radius: 20px; background-color: #fbf9f8; text-align: center; padding: .4rem;
		strong {font-size: clamp(1.375rem, 1.2740rem + 0.4487vw, 1.8125rem);unicode-bidi: plaintext;}
	}
	.subTitle {text-align: center; max-width: 100%;}
	@media (max-width: 767.98px)  { margin:1.5rem 10px 0}
	@media (min-width:992px) {
		.note {position: absolute; border-radius: 20px 20px 0 20px; left: 0; top: -103px;padding: 0.9rem;
			&:after {content: '';position: absolute;bottom: 0;right: 0;width: 0;height: 0;border: 34px solid transparent;border-top-color: #fbf9f8;border-bottom: 0;border-right: 0;margin-left: -22px;margin-bottom: -38px;}
		}
	}
}
/*
*<div class="contactS1">
*/
.contactS1 {padding: 2rem 0;
	h2 { text-align: center;}
	// .subTitle {text-align: center; max-width: 100%;}
	ul { list-style: none; display: flex;flex-wrap: wrap; justify-content: space-evenly; gap:1.5rem; text-align: center; padding: 1.5rem 0 0;}
	li { /*width: 100%; max-width: 50%; margin-bottom: 1.5rem;*/}
	a { color: $elephantBlue; text-decoration: none;
		&:hover {}&:active {}&:focus {}
	}
	figure {width: clamp(5.9375rem, 4.4663rem + 6.5385vw, 12.3125rem); margin: 0 auto;
		img { display: block;width: 100%; object-fit: contain;}
	}
	@media (min-width:768px) {
		ul {margin-bottom:4rem ;}
	}
	@media (min-width:992px) {padding: 4rem 0;
		ul { padding-top: 4rem;}
	}
}
.contactS2 {padding: 2rem 25px;border-radius: 18px; overflow: hidden;  position: relative; z-index: 1;
	&:before,
	&:after {content: ""; position: absolute; z-index: -1; top: 0; left:0; width: 100%; height: 100%;}
	&:after { background-color:  #fff3ec; mask: $blue18 right -170px top 35% / 280px auto no-repeat;}
	&:before { background-color:  #fcc9ad; mask: $blue18 -40px -55px / 102px auto no-repeat, $blue18 80px -35px / 50px auto no-repeat;}
	.back { color: $hazeGreen; text-decoration: none; font-weight: bold; font-size: 17px; line-height: 1.4;
		&:before { content: "‹"; font-size: 1.8em; line-height: .1;transform: translate(2px, 2px);font-weight: 500; display: inline-block;}
	}
	aside {padding: 1rem 0;}
	
	.contactForm {padding: 1rem 0;
		legend { text-align: center;color: $endeavourBlue; font-weight: bold;font-size: clamp(1.0625rem, 0.9471rem + 0.5128vw, 1.5625rem); line-height: 1; margin-bottom: 1rem;}
		.form-floating { //margin-bottom: 1rem;
			&.w-100 {max-width: 100% !important;}
			.form-control {
				
			}
			textarea.form-control { height: 110px; resize: none;}
			label {}
		}
		.btn.btn-success { width: 100%;
			&:hover {}&:active {}&:focus {}
		}
	}
	@media (min-width:992px) {padding: 2rem clamp(1.5625rem, -6.1207rem + 12.3922vw, 8.75rem);
		&:before { background-color:  #fcc9ad; mask: $blue18 -35px -50px / 102px auto no-repeat, $blue18 80px 15px / 50px auto no-repeat;}
		h2 { border-bottom: 6px solid #f8f8f8;}
		.row { margin: 0; gap:4%;}
		aside { max-width: 36%;}
		.contactForm { max-width: 60%; display: flex; flex-wrap: wrap; gap:0 1rem; font-size: 23px;
			>*:not(form) {flex-shrink: 0;}
			.form-floating>.form-control { height: calc(3.1rem + 4px); font-size: 23px; }
			.form-floating:nth-of-type(1):not([class*=col-]),.form-floating:nth-of-type(2):not([class*=col-]),.form-floating:nth-of-type(3):not([class*=col-]), .form-floating:nth-of-type(4):not([class*=col-]) {width: 100%; max-width: calc(50% - .5rem);}
			.btn { max-width: 200px; margin: auto; margin-inline-end: 0; height: 68px; border-radius: 34px; font-size: 23px;}
		}
	}

	.umbraco-forms-container {display: flex;flex-wrap: wrap;gap: 0 1rem;font-size: 23px;
		.form-floating {flex-shrink: 0; width: 100%; max-width: 100%;
			.umbraco-forms-field-wrapper { }
		}
		.input-validation-error { border-color: var(--bs-danger);background-image: escape-svg($form-feedback-icon-invalid);
			background-repeat: no-repeat;
			background-position: left $input-height-inner-quarter center;
			background-size: $input-height-inner-half $input-height-inner-half;}
		// .input-validation-valid { border-color: var(--bs-success);background-image: escape-svg($form-feedback-icon-valid);
		// 	background-repeat: no-repeat;
		// 	background-position: left $input-height-inner-quarter center;
		// 	background-size: $input-height-inner-half $input-height-inner-half;}
			
		.field-validation-error {color: var(--bs-danger); font-size: 80%; line-height: 1; display: inline-block;}
	}
	.umbraco-forms-navigation { text-align: left;
		input[type=submit].btn {@extend .btn-success;}
	}
}
.contactS3 {padding: 2rem 25px; text-align: center;
	.btn {margin-top: 1.5rem;}
	@media (min-width:992px) {padding: 4rem;
		.btn { font-size: 23px;}
	}
}

@import 'siteModals';

/*
*<section class="loginWrapper">
*/
// .login-tooltip {
// 	.tooltipImage:after { display: none;}
// 	.tooltip-arrow:before {//content: "";
// 		// display: block;
// 		width: 25px;
// 		height: 23px;
// 		// position: absolute;
// 		right: -18px;
// 		top: 40%;
// 		background: url('../images/tooltipBubbleArrow.png') no-repeat;}
// }
.loginWrapper { justify-content: space-between; display: flex; padding: .8rem 0;
	.col-12 {}
	.login { all: unset; margin-bottom: 1.5rem; }
	.loginHeader { width: 100%; color: $endeavourBlue; line-height: 1.2;
		h1 {font-size: clamp(1.47rem, 1.1577rem + 1.4750vw, 2.8235rem); color: inherit; margin: 0 0 .3rem;}
		.subtitle {font-size: clamp(1.1176rem, 1.0617rem + 0.2642vw, 1.36rem); line-height: inherit;}
	}
	
	.loginForm { width: 100%;
		.enterPersonalDetails { 
			.form-row {display: flex; flex-direction: column-reverse;}
		}
		.form-floating, .floatingPlaceHolder { margin-bottom: 1rem;
			.form-control {
				
			}
			textarea.form-control { height: 110px; resize: none;}
			label { right: 2.25rem;}
		}

		.form-control {@extend .form-control;
			// height: auto;
			// // 	width: 100%;
			// 	border-radius: 24px;
			// 	border: 2px solid #efefef;
			// 	padding: .375rem .75rem;
			// 	font-size: 1rem;
			// 	color: #103245;
			&[type=text] {background: url(../images/account.svg) right 1.5rem center/17px auto no-repeat;padding-right: 2.9rem;}
			&[type=password] {background: url(../images/padlock.svg) right 1.5rem center/17px auto no-repeat;padding-right: 2.9rem;}
		}
		.form-select {background: url(../images/account.svg) right 1.5rem center/17px auto no-repeat;padding-right: 2.9rem;}
		label {}
		.form-text { font-size: 65%; margin: 0;}
		.btnRow { display: flex; flex-direction: column; align-items: center; justify-content: center; gap:1.5rem; padding-top: 1rem;
			.fPass { //;
				&:hover {}&:active {}&:focus {}
			}
			.btn-success { width: 100%; max-width: 315px; font-weight: 600;
				&:hover {}&:active {}&:focus {}
			}
		}
	}
	.textBlock {padding: 1rem 15px; margin-bottom: 1.5rem; color: #fff; background: url(../images/blueBg.webp) center center; border-radius: 18px; text-align: center;font-size: clamp(17px, 3.1vw, 23px);
		img { display: block; margin:0 auto .6rem; width: 30px;}
		.inner {}
		h3 {color: #fff; font-size: 23px; font-weight: bold;}
		a {color: #fff;}
	}
	.loginPic {width: 100%;max-width: 32%;
		img { display: block; margin: auto; max-width: 100%;}
	}
	@media (max-width:991.98px) {
		.loginPic {display: none;}
	}
	@media (min-width:992px) {
		.col-12 { max-width: 65%; padding-top: 2.3rem; }
		.login {padding: 4.5%; background: #fff; border-radius: 20px;box-shadow: 0 0 70px #eeebeb; display: flex; justify-content: space-between; align-items: flex-start;}
		.loginHeader {max-width: 290px;
			h1 {}
			.subtitle {}
		}
		.loginForm { max-width: calc(100% - 290px - 2rem);font-size: 23px;
			.form-floating, .floatingPlaceHolder>{
				.form-select,.form-control { height: calc(3.1rem + 4px); font-size: 23px; }
			}
			.btnRow { flex-direction: row; justify-content: space-between;
				.btn-success { max-width: 215px; font-size: 23px;}
			}
		}
		.textBlock { display: flex; text-align: right; padding: 1.3rem 4.5%; //margin-bottom: 1.5rem; color: #fff; background: url(../images/blueBg.webp) center center; border-radius: 18px; text-align: center;font-size: clamp(17px, 3.1vw, 23px);
			img { display: block; margin:0 0 0 1.2rem; width: 43px; flex:0 0 43px;}
			.inner { flex:1 0 0%;}
			h3 { margin: 0;}
			// a {color: #fff;}
		}
	}
}



.macabiList { padding: 0; list-style: none;
	li {padding: 2px 17px 2px 0;
		&:before {margin: 8px -16px 0 0; content:""; float: right;mask-image: $Object; width: 9px; height:9px;mask-position: center;
			mask-size: 9px;
			mask-repeat: no-repeat;}
			&:before { background-color:#d8d0c9;}
		// &:nth-child(1),&:nth-child(8) { &:before { background-color:#fab794;} }
		// &:nth-child(2),&:nth-child(9) { &:before { background-color:#fed786;} }
		// &:nth-child(3),&:nth-child(10) { &:before { background-color:#9ed1f1;} }
		// &:nth-child(4),&:nth-child(11) { &:before { background-color:#a4d4a4;} }
		// &:nth-child(5),&:nth-child(12) { &:before { background-color:#c9c0de;} }
		// &:nth-child(6),&:nth-child(13) { &:before { background-color:#a4d4a5;} }
		// &:nth-child(7),&:nth-child(14) { &:before { background-color:#d8d0c9;} }
	}
}

.mediaPicCard {box-shadow: 0 0 35px #eeebeb; background: #fff; border-radius: 18px;padding: 1.4rem 1.7rem; display: flex; justify-content: space-between; align-items: center; margin-bottom: 1.3rem;
	figure { width: 100%; max-width: 42.7%; flex: 1 0 auto; margin: 0;
		img {width: 100%;}
	}
	.text {width: 100%; max-width: 45.7%; flex: 1 0 auto; font-size: 1.294rem;line-height: 1.2;}
	h3 {font-weight: bold;}
	a { font-weight: bold; font-size: 1rem;}
}

.userTreets {padding: 3.4rem 1.7rem 0;line-height: 1.2; display: flex; flex-wrap: wrap; margin-bottom: 1.5rem; color: #fff; background: url(../images/blueBg.webp) center center; border-radius: 18px;
	>div { line-height: .6; margin-bottom: 1.5rem; flex:0 0 50%;
		&:last-of-type {border-inline-start: 5px solid; padding-inline-start: 1.7rem;}
		strong { font-size: 1.352rem;}
		.count { padding-top: 1.35rem;
			span { font-size: 2.823rem; font-weight: 700;}
		}
	}
	>p {width: 100%; flex:0 0 100%; }
}

.priceListTable { width: 100%;caption-side: top;
	caption {color:$link-color; text-align: start;font-size: clamp(1.176rem, 1.0085rem + 0.7908vw, 1.47rem);}
	thead {  line-height: 1;
		tr {}
		th { font-size: 1.352rem;padding: 1px 0 .5rem; vertical-align: bottom;
			&:not(:first-child) { text-align: center;width: 100%; max-width: 17%; }
			&:nth-last-child(-n+2) {color:$blue;font-size: 1.588rem;}
			&:last-child {color:$green !important; }
		}
	}
	tbody {}
	tr {}
	td {
		&:nth-last-child(-n+2) {color:$blue; font-weight: bold;}
		&:last-child {color:$green !important;}
	}
	figure { width: 100%; overflow: hidden; position: relative;display: flex;align-items: center; justify-content: center; margin: 0; max-width: 66px;height: 66px; //padding: 16%;
		&:before {content:""; position: absolute;left:0; top: 0; width: 100%; height: 100%; aspect-ratio: 1/1; transition: all 4s ease-in-out;
			background-color: var(--picBg, #d8d0c9);
			mask-image: $Object;
			mask-position: center;
			mask-size: contain;
			mask-repeat: no-repeat; 
		}
		img {width: 86.86%;@include centerer; }
	}
	.text {
		strong {}
		p { line-height: 1;
			&:first-of-type { margin-bottom: 0;}
		}
		.time {@extend %time;}
	}
	.btn.btn-link {
		&:hover {}&:active {}&:focus {}
	}
	
	@media (max-width:991.98px) {
		caption {text-align: center; font-weight: bold;}
		thead {display: none;}
		tr {padding: 1.75rem 1.1rem 5.5rem;display: flex; flex-direction: column; position: relative;border-radius: 20px; background-color: #fff; margin-bottom: .5rem;box-shadow: 0 0 35px #eeebeb;}
		td {padding: .55rem 0;flex:0 0 100%; border-bottom:1px solid #eee; display: flex; justify-content: space-between;
			&:first-child { padding: 0 0 .2rem; flex-direction: row-reverse; border:0;}
			&:last-child {border:0;}
			&:not(:first-child):before {content:attr(data-td);}
		}
		figure { max-width: 50px;height: 50px;}
		img {width: 56.86%;}
		.text {width: 100%; max-width: calc(100% - 70px); height: auto;	}
		.btn.btn-link { position: absolute; @include button-variant($green, $green); text-decoration: none;width: 100%; max-width: 230px; inset:auto calc((100% - 230px) / 2) 1.5rem; font-size: 1.235rem;}
	}
	@media (min-width:992px) {
		// base | silver | gold | my
		&[data-plan=base] {
			th:nth-child(2) {
				&:before {content:"המחיר שלך"; display: block;}
			}
			td:nth-child(2) {font-size: 1.588rem;background: #9ed1f169;}
		}
		&[data-plan=silver] {
			th:nth-child(3) {
				&:before {content:"המחיר שלך"; display: block;}
			}
			td:nth-child(3) {font-size: 1.588rem;background: #9ed1f169;}}
		&[data-plan=gold] {
			th:nth-child(4) {
				&:before {content:"המחיר שלך"; display: block;}
			}
			td:nth-child(4) {font-size: 1.588rem;background: #9ed1f169;}}
		&[data-plan=my] {
			th:nth-child(5) {
				&:before {content:"המחיר שלך"; display: block;}
			}
			td:nth-child(5) {font-size: 1.588rem;background: #9ed1f169;}}
		tbody { background: #fff; border-radius: 20px;box-shadow: 0 0 35px #eeebeb; overflow: hidden;}
		tr {
			&:nth-child(even) {background-color: #f8f8f8;}
		}
		td {
			&:first-child { display: flex; gap: 1.47rem; padding: 1.3rem 5.5% 1.7rem 2.5%;}
			&:nth-child(2) { border-inline: 1px solid #eee;}
			&:not(:first-child) { text-align: center;font-size: 1.352rem;}
			// &:nth-last-child(-n+2) { font-size: 1.588rem;background: #9ed1f169;}
			// &:last-child { background: 0;}
		}
		figure {}
		.text {align-self: center;}
		.btn.btn-link { margin: 0 auto 0 0;}
	}
}

.topicsList { list-style: none; padding: 0; margin: 0 0 2rem; display: flex; flex-wrap: wrap; gap:1rem .6471rem;
	li {padding: 1rem .4rem .5rem;width: 100%; max-width: calc((100% / 2) - .6471rem + (.6471rem / 2)); line-height: 1.2; background: #f8f8f8; border-radius: 18px; text-align: center;
		&:nth-child(2) {figure:before {background-color: $pastelGray;}}
		&:nth-child(3) {figure:before {background-color: $salomieYellow;}}
		&:nth-child(4) {figure:before {background-color: $peranoBlue;}}
		&:nth-child(5) {figure:before {background-color: $mossGreen;}}
		&:nth-child(6) {figure:before {background-color: $grayPurple;}}
		&:nth-child(7) {figure:before {background-color: $roseOrange;}}


	}
	figure { font-size: clamp(0.8529rem, 0.6544rem + 0.9375vw, 1.1176rem); z-index: 1;
	overflow: hidden; position: relative;display: flex;align-items: center; justify-content: center; margin: 0 auto .6rem;width: clamp(3.0588rem, 1.2059rem + 8.7500vw, 5.5294rem);
		aspect-ratio: 1; //padding: 16%;
		&:before {content:""; position: absolute;left:0; top: 0; width: 100%; height: 100%;  transition: all 4s ease-in-out;
			background-color: $roseOrange;
			mask-image: $Object;
			mask-position: center;
			mask-size: contain;
			mask-repeat: no-repeat; z-index: -1;
		}
		span { display: none;}
		
	}
	.title { font-size: clamp(0.8824rem, 0.5295rem + 1.6664vw, 1.3529rem); font-weight: 700; color: $endeavourBlue; margin-bottom: .1rem;}
	.text {}
	@media (min-width:992px) {
		li {max-width: calc((100% / 3) - .6471rem + (.6471rem / 3)); padding: 1.8rem 4% 2rem;}
		figure {margin: 0 auto .9rem;
			span { display: inline-block;}
		}
		.title {margin-bottom: .5rem;}
	}
}

.availableClinics {list-style: none; padding: 0;
	li:not(li:has(form)) {gap: .5rem .2rem;font-size: clamp(1rem, 0.9118rem + 0.4165vw, 1.1176rem); line-height: 1.2; padding: clamp(1.5294rem, 1.6618rem + -0.6251vw, 1.3529rem) clamp(0.2rem, -1.0618rem + 5.9585vw, 1.8824rem); background: #f8f8f8; border-radius: 20px; margin-bottom: 1rem; display: flex; flex-wrap: wrap;align-items: center; justify-content: center;  }
	form {gap: .5rem .2rem;font-size: clamp(1rem, 0.9118rem + 0.4165vw, 1.1176rem); line-height: 1.2; padding: clamp(1.5294rem, 1.6618rem + -0.6251vw, 1.3529rem) clamp(0.2rem, -1.0618rem + 5.9585vw, 1.8824rem); background: #f8f8f8; border-radius: 20px; margin-bottom: 1rem; display: flex; flex-wrap: wrap;align-items: center; justify-content: center;}
	.name { width: 100%; text-align: center; color: $endeavourBlue; font-weight: 700;}
	.address {width: 100%; text-align: center;
		i {width: 15px; display: inline-block; transform: translateY(3px); 
			height: 15px;
			mask-image: $pin;
			mask-size: contain;
			mask-position: center;
			mask-repeat: no-repeat;
			background-color: $hazeGreen;}
	}
	.date {max-width: max-content;
		&:empty {display: none;}
		i {width: 15px; display: inline-block; transform: translateY(3px);
			height: 15px;
			mask-image: $time-left;
			mask-size: contain;
			mask-position: center;
			mask-repeat: no-repeat;
			background-color: $hazeGreen;}
	}
	.day {max-width: max-content;
		&:empty {display: none !important;
			&:before,&:after {display: none !important; }
		}
	}
	.time {max-width: max-content;
		&:empty {display: none;}
	}
	.d-flex { gap:.2rem;
		// >*:empty {display: none;}
	}
	.btn {width: 100%;}
	@media (min-width:992px) { 
		li:not(li:has(form)) {justify-content: flex-start; flex-wrap: nowrap; text-align: start;}
		form {justify-content: flex-start; flex-wrap: nowrap; text-align: start;}
		.name { width: 100%; max-width: 6.7rem; text-align: start;}
		.address {text-align: start; width: auto; padding-inline-end: .7rem;}
		.day {position: relative; padding-inline: .9rem;
			&:before,&:after {content: "|"; color:$hazeGreen; position: absolute; }
			&:before {inset-inline-start: .3rem;}
			&:after {inset-inline-end: .3rem;}
		}
		.d-flex {flex-wrap: wrap; min-width: min-content;}
		.btn {width: 100%;max-width: 10.5882rem; margin-inline: auto 0;}
	}
}


.videoListWrapper { text-align: center; margin-bottom: 2rem;
	.container { }
	h2 {}
	.subTitle { margin-inline: auto;}
}

.videoList { list-style: none; padding: 0; margin: 1rem 0;
	li { margin-bottom: 1.3rem; }
	.btn-video { border:0; padding: 0; background: 0; }
	figure {aspect-ratio: 16/9; overflow: hidden; margin-bottom: .3rem; border-radius: 18px; position: relative;
		&:before {content:"";background: $play center center/cover no-repeat transparent; position: absolute; width: clamp(2.5294rem, 1.8676rem + 3.1252vw, 3.4118rem); aspect-ratio: 1; top:50%;left:50%; transform: translate(-50%, -50%);}
		img { display: block;width: 100%; height: 100%; object-fit: cover;}
	}
	figcaption { text-align: start;}
	@media (min-width:992px) { display: flex; gap:1.2941rem;
		li {width: 100%; max-width: calc((100% / 5) - 1.2941rem + (1.2941rem / 5));}
	}
}


.workshopNecessities {
	h4 { margin-bottom: 0;}
	ul { padding: 0 .8em; display: inline-flex; gap:0 1.3em; font-size: 1rem; flex-wrap: wrap;
		li { padding-inline-start: .2em; min-width: max-content;
			&::marker {content: '✓';color: $hazeGreen; }
		}
	}
}


.aboutArticle { padding: clamp(15px,3vw,50px); background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px; margin-bottom: 1.7647rem;
	
	h2 {font-size: clamp(1.29411rem, 0.7206rem + 2.7083vw, 2.0588rem);}
	h3 { font-weight: 700;}
	h4 { font-size: 1rem; color: #103245; font-weight: 700;}
	@media (min-width:1200px) {padding-inline-end: min(30vw, 900px);}
}

.mankal {padding: clamp(15px, 3vw, 44px); background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px; margin-bottom: 1.7647rem;
	.row {}
	figure { flex:0 0 100%;
		img { aspect-ratio: 1; object-fit: cover; margin: auto; display: block;border-radius: 57% 43% 46% 54%/46% 44% 56% 54%;
			}
	}
	.text {flex: 1 0 0%;
		.title {font-size: clamp(1.29411rem, 0.7206rem + 2.7083vw, 2.0588rem);}
		p {}
	}
	@media (min-width:992px) { padding-inline: 7.9%;
		figure { flex:0 0 15%;margin-inline-end: 1.5%; margin-top: 2.6rem;
			img {}
		}
		.text {flex: 0 0 calc((100% / 7) * 4);
			.title {font-size: clamp(1.29411rem, 0.7206rem + 2.7083vw, 2.0588rem);}
			p {}
		}

	}
}
.poersonel {padding: clamp(15px,3vw,50px); background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px; margin-bottom: 1.7647rem;
	h2 {font-size: clamp(1.29411rem, 0.7206rem + 2.7083vw, 2.0588rem); text-align: center; margin-bottom: 3rem;}
	ul { list-style: none; padding: 0; margin-inline: auto; max-width:1030px; display: flex; flex-wrap: wrap; justify-content: center; text-align: center;
		li { margin-bottom: 2.5rem; }
		a { text-decoration: none;line-height: 1.3;}
		figure { background: #333; border-radius: 57% 43% 46% 54%/46% 44% 56% 54%; overflow: hidden;aspect-ratio: 1; max-width: min(175px, 32vw); margin-inline: auto; margin-bottom: 1.5rem;
			img {aspect-ratio: 1; object-fit: cover; margin: auto; display: block;width: 100%; height: 100%;}
		}
		.name { display: block;font-size: clamp(17px, 3.1vw, 23px); font-weight: 800; margin-bottom: 0.3rem;}
		.text { display: block;}
	}
}
.errorContent {padding: clamp(15px,3vw,50px); background: var(--bs-white); box-shadow: 0 0 70px #eeebeb; border-radius: 18px; margin: auto; max-width: 650px; text-align: center;}

.fuzzyList { list-style: disc; padding: 0 20px;
	li::marker { font-size: 130%; color: #9ed3f1;	}
} 
.mapAlert {
	color: var(--bs-link-color);
	text-decoration: underline;
	transition: all .3s ease-in-out;
	flex-shrink: 0;
	position: absolute !important;
	// width: 1px !important;
	// height: 1px !important;
	// padding: 0 !important;
	// margin: -1px !important;
	// overflow: hidden !important;
	// clip: rect(0, 0, 0, 0) !important;
	// white-space: nowrap !important;
	// border: 0 !important;
	max-width: calc(37% - 1rem) !important;
	inset-inline-end: 1rem;
	isolation: isolate;
	z-index: 1;
	margin-top: -1rem;
&:not(:focus):not(:focus-within) {
		// position: absolute !important;
		// width: 1px !important;
		height: 1px ;
		padding: 0 ;
		// margin: -1px ;
		overflow: hidden ;
		clip: rect(0, 0, 0, 0) ;
		// white-space: nowrap ;
		border: 0 ;
	}
	@media (width < 992px) { position: static !important;max-width: 100% !important;}
}
/////////////////////
@media (max-width:767.98px) {}
@media (max-width:991.98px) {}
@media (min-width:768px) {}
@media (min-width:992px) {}