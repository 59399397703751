@import	"general";
@import "sm-core-css";
@import "footer";
@import "audio-player";
@import "clinics";
@import "career";
@import "recruitContact";
#header {
	.navbar-toggler { order: 0; padding-inline: .45rem;margin-inline-end: 8%;
		&:after {display: none;}
		.navbar-toggler-icon {width: 1.3em;height: 1.3em;}
	}
}