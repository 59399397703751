.audio-player {--player-button-width: 1.5rem;--sound-button-width: 1.5rem;--space: .5em;width: 15rem; direction: ltr;/* height: 15rem; */
	.icon-container {width: 100%;height: 100%;background-color: $green;color: #fff;display: flex;justify-content: center;align-items: center;}
	/* .audio-icon {width: 90%;height: 90%;} */
	.controls {display: flex;flex-direction: row;align-items: center;width: 100%}
	.player-button {background-color: transparent;border: 0;width: var(--player-button-width);height: var(--player-button-width);cursor: pointer;padding: 0;
		&:hover,&:focus {
			path { fill:$blue;}
		}
	}
	.timeline {-webkit-appearance: none;width: calc(100% - (var(--player-button-width) + var(--sound-button-width) + var(--space)));height: .5em;background-color: #f8f8f8;border-radius: 5px;background-size: 0% 100%; box-shadow: inset 0 0 4px #afafaf; background-image: linear-gradient($green, $green);background-repeat: no-repeat;margin-right: var(--space);
		>div:nth-child(3) { color: red; background-color: red;}
	}
	// input.timeline[type=range]::-moz-range-thumb {background: $blue;}
	// input.timeline[type="range" i]::-webkit-slider-thumb {background: $blue;}
	// input[type="range"]::-moz-range-thumb,
	.timeline::-webkit-slider-thumb {box-shadow: 0 0 0 8px inset $blue; -webkit-appearance: none;width: 1em;height: 1em;border-radius: 50%;cursor: pointer;/*opacity: 0;*/transition: all .1s;background: $blue;}
	.timeline::-moz-range-thumb {box-shadow: 0 0 0 8px inset $blue; -webkit-appearance: none;width: 1em;height: 1em;border-radius: 50%;cursor: pointer;/*opacity: 0;*/transition: all .1s;background: $blue;}
	.timeline::-ms-thumb {box-shadow: 0 0 0 8px inset $blue; -webkit-appearance: none;width: 1em;height: 1em;border-radius: 50%;cursor: pointer;/*opacity: 0;*/transition: all .1s;background: $blue;}
	
	.timeline::-webkit-slider-thumb:hover,
	.timeline::-moz-range-thumb:hover,
	.timeline::-ms-thumb:hover{background-color: #943f65;}

	.timeline:hover::-webkit-slider-thumb,
	.timeline:hover::-moz-range-thumb,
	.timeline:hover::-ms-thumb {opacity: 1;}

	.timeline::-webkit-slider-runnable-track,
	.timeline::-moz-range-track,
	.timeline::-ms-track {-webkit-appearance: none;box-shadow: none;border: none;background: transparent;}

	.sound-button {background-color: transparent;border: 0;width: var(--sound-button-width);height: var(--sound-button-width);cursor: pointer;padding: 0;
		&:hover,&:focus {
			path { fill:$blue;}
		}
	}
	@media (max-width:991.98px) {}
	@media (min-width:992px) { display: inline-block;}
}
